import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ISavedSearchState } from 'ts/interfaces/saved-search/saved-search';
import { ISavedSearch } from 'ts/interfaces/saved-search/saved-search.data';

const initialState: ISavedSearchState = {
  table: {
    data: [],
  },
  select: {
    data: [],
    pageNumber: 0,
    pageSize: 10,
  },
};

const savedSearchReducer = (state = initialState, action: IReduxAction): ISavedSearchState | {} => {
  switch (action.type) {
    case EReduxTypes.GET_SAVED_SEARCHES: {
      if (action.forwardedData.place === 'select') {
        if (action?.forwardedData?.query || action?.forwardedData?.idFilter) return state;
        return {
          ...state,
          select: {
            data: [...state.select.data, ...(action?.payload?.data || [])] || [], //(action?.forwardedData?.pageNumber <= state.select.pageNumber) ? action?.payload?.data : 
            meta: action?.payload?.meta,
            pageNumber: action?.forwardedData?.pageNumber || 0
          }
        }
      }
      return {
        ...state,
        [action.forwardedData.place]: {
          data: action?.payload?.data || [],
          meta: action?.payload?.meta,
        }
      }
    }
    case EReduxTypes.SAVE_GOOGLE_SEARCH: {
      return {
        ...state,
        select: {
          data: [action.payload, ...state.select.data],
        },
        table: {
          data: [action.payload, ...state.table.data],
        }
      }
    }
    case EReduxTypes.UPDATE_SAVED_SEARCH: {
      const tableIndex = state.table.data.findIndex((search) => action.payload?._id === search._id);
      const selectIndex = state.select.data.findIndex((search) => action.payload?._id === search._id);

      const newTableData = [...state.table.data];
      const newSelectData = [...state.select.data];

      if (tableIndex && action.payload?._id) newTableData[tableIndex] = action.payload as ISavedSearch;
      if (selectIndex && action.payload?._id) newSelectData[tableIndex] = action.payload as ISavedSearch;

      return {
        ...state,
        select: {
          ...state.select,
          data: newSelectData,
        },
        table: {
          ...state.table,
          data: newTableData,
        }
      }
    }
    case EReduxTypes.DELETE_SAVED_SEARCH: {
      return {
        ...state,
        select: {
          ...state.select,
          data: state.select.data.filter((search) => search._id !== action.forwardedData.searchId)
        },
        table: {
          ...state.table,
          data: state.table.data.filter((search) => search._id !== action.forwardedData.searchId)
        }
      }
    }
    default:
      return state;
  }
};

export default savedSearchReducer;