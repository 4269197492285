import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IGoogleLocation, IPaginatedGoogleLocation } from 'ts/interfaces/google/google.data';
import { ISavedSearch, IPaginatedSavedSearch } from 'ts/interfaces/saved-search/saved-search.data';
import { IGoogleSearchRequest } from 'ts/interfaces/google/google.search.request';
import { IPagination } from 'ts/interfaces/common/pagination';

export const getGoogleLocations = (query: String, onSuccess: (options: any) => void): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_GOOGLE_LOCATIONS,
    payload: {
      method: 'GET',
      endpoint: `google/locations?q=${query}`,
    },
    onSuccess: (data: IPaginatedGoogleLocation) => onSuccess(data?.data?.map((item: IGoogleLocation) => item))
  }
  return action;
};

export const sendGoogleSearchRequest = (params: IGoogleSearchRequest): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_GOOGLE_SEARCH_REQUEST,
    payload: {
      method: 'GET',
      endpoint: `google/search`,
      params,
    },
    components: ['api_playground']
  }
  return action;
};

export const setGoogleSearchRequest = (params: IGoogleSearchRequest): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_GOOGLE_SEARCH_REQUEST,
    data: params
  }
  return action;
};

export const saveGoogleSearch = (name: string, params: IGoogleSearchRequest, onSuccess?: (search: ISavedSearch) => void): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SAVE_GOOGLE_SEARCH,
    payload: {
      method: 'POST',
      endpoint: `saved-search`,
      body: {
        ...params,
        name
      }
    },
    onSuccess: (result: ISavedSearch) => {
      if (onSuccess) onSuccess(result);
      toast.success('Search successfully saved!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    },
    components: ['save_search']
  }
  return action;
};
