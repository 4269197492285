import { FC, useEffect, useState } from 'react';
import Select from 'react-select';

//** Definitions - interface, type, const */
import { 
    intervalOptions, 
} from 'ts/constants/automation.form.options';
import { EIntervalTypes } from 'ts/enums/interval.types';

//** Helpers */
import generateCronTime from 'helpers/cronTimeGenerator';

import HoursSelect from 'components/hoursSelect';
import DaysOfWeekSelect from 'components/daysOfWeekSelect';
import DaysOfMonthSelect from 'components/daysOfMonthSelect';

import {
    Container
} from './styles';
import parseInterval from 'helpers/cronTimeParser';

const IntervalSelect: FC<{ value?: string, setValue: (value: any) => void }> = ({ value, setValue }) => {
    const [interval, setInterval] = useState<EIntervalTypes>();
    const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
    const [daysOfMonth, setDaysOfMonth] = useState<string[]>([]);
    const [hours, setHours] = useState<string[]>([]);
    const [initialValueParsed, toggleInitialValueParsed] = useState<boolean>(!value)

    useEffect(() => {
        if (interval && initialValueParsed) {
            const cronTime = generateCronTime(interval, hours, daysOfWeek, daysOfMonth);
            setValue(cronTime);
        }
    }, [interval, daysOfWeek, daysOfMonth, hours]);

    useEffect(() => {
        if (!initialValueParsed && value) {
            const parsedInterval = parseInterval(value);

            setInterval(parsedInterval.interval);
            setHours(parsedInterval.hours);
            setDaysOfMonth(parsedInterval.daysOfMonth);
            setDaysOfWeek(parsedInterval.daysOfWeek);
            toggleInitialValueParsed(true);
        }
    }, []);

    return (
        <>
            <Select
                placeholder="Select automation interval..."
                className="basic-single"
                classNamePrefix="select"
                isClearable
                name="interval"
                value={intervalOptions.find((option) => option.value === interval)}
                onChange={(newValue: any) => setInterval(newValue?.value)}
                options={intervalOptions}
                menuPlacement="auto"
            />
            { interval === EIntervalTypes.WEEKLY && <Container><DaysOfWeekSelect value={daysOfWeek} setValue={setDaysOfWeek}/></Container>}
            { interval === EIntervalTypes.MONTHLY && <Container><DaysOfMonthSelect value={daysOfMonth} setValue={setDaysOfMonth}/></Container>}
            { interval && [EIntervalTypes.DAILY, EIntervalTypes.WEEKLY, EIntervalTypes.MONTHLY].includes(interval) && <Container><HoursSelect value={hours} setValue={setHours}/></Container>}
        </>
        
    )
}

export default IntervalSelect;