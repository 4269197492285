import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FcGoogle } from 'react-icons/fc';

//** Actions */
import { signIn } from 'data/actions/user';

//** Selectors */
import { getLoadingSelector } from 'data/selectors/loading';

//** Components */
import EmailInput from 'components/emailInput';
import PasswordInput from 'components/passwordInput';
import Button from 'components/button';

//** Layouts */
import AuthLayout from 'layouts/auth/authLayout';

//** Hooks */
import useFingerPrint from 'hooks/useFingerPrint';
import useQueryError from 'hooks/useQueryError';

//** Helpers */
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';

//** Styles */
import { ForgotPassword, Label, CreateAccountLabel, Title, ErrorMessage } from './styles';

const SignInPage: FC = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>(useQueryError() || '');
    const loading = useSelector<boolean>(getLoadingSelector('sign_in'));
    const google_loading = useSelector<boolean>(getLoadingSelector('google_auth'));
    const fingerPrint: string = useFingerPrint();

    const handleSignIn = (e: any) => {
        e.preventDefault();
        dispatch(signIn(email, password, fingerPrint, setError));
    };

    const connectGmailAccount = (e: any) => {
        e.preventDefault();
        const url = buildOauthRequestUrl();
        window.location.replace(url);
    };

    return (
        <AuthLayout>
            <Title>Sign In</Title>
            <EmailInput value={email} onChange={(e: any) => setEmail(e?.target?.value)} />
            <PasswordInput value={password} onChange={(e: any) => setPassword(e?.target?.value)}/>
            <ForgotPassword><Link to="/password-forgot">Forgot password?</Link></ForgotPassword>
            { error && <ErrorMessage>{error}</ErrorMessage> }
            <Button onClick={handleSignIn} loading={loading}>Sign in</Button>
            <Label>or</Label>
            <Button onClick={connectGmailAccount} loading={google_loading} empty><FcGoogle size={18} style={{ marginRight: 5 }}/>Sign in with Google</Button>
            <CreateAccountLabel>Don't have an account? <Link to="/sign-up">Create!</Link></CreateAccountLabel>
        </AuthLayout>
    )
} 

export default SignInPage;