import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 550px;
    background-color: rgb(238 245 254);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Form = styled.form`
    width: 450px;
    background: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 30px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const LogoContainer = styled.img`
    width: 150px;
    cursor: pointer;
`;

export const BackToSiteContainer = styled.div`
  width: 140px;
  position: absolute;
  top: 20px;
  left: 20px;
`;
