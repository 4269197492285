import { FC } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { currentSubscriptionSelector } from 'data/selectors/subscriptions';
import { creditsSelector } from 'data/selectors/credits';
import { SettingPageHeader, Container, SettingPageDescription } from '../account/styles';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';
import { ICredits } from 'ts/interfaces/credits/credits';
import { upperFirst } from 'lodash';
import { FlexRowContainer, FlexColumnContainer } from 'components/common/styles'; 
import UpgradePlanButton from 'components/upgradePlanButton';
import { RawItem, Label, SubscriptionContainer } from './styles';
import { Dot } from 'components/common/styles';
import { Divider } from '../billing/styles';

const SubscriptionPage: FC = () => {
    const subscription: ISubscription = useSelector(currentSubscriptionSelector)
    const credits: ICredits = useSelector(creditsSelector);
    const renewalDate = DateTime.fromISO(credits?.lastRefreshedAt).plus({ month: 1}).toFormat('LLL dd, yyyy');

    if (!subscription?.plan || !credits) return <></>
    return (
        <Container>
            <FlexRowContainer>
                <FlexColumnContainer>
                    <SettingPageHeader>Subscription</SettingPageHeader>
                    <SettingPageDescription>Subscription status, credits and limits.</SettingPageDescription>
                </FlexColumnContainer>
                <UpgradePlanButton />
            </FlexRowContainer>
            <Divider />
            <SubscriptionContainer>
                <RawItem><Label>Current Plan</Label> {upperFirst(subscription.plan.name)}</RawItem>
                <RawItem><Label>Credits </Label>{subscription.plan.credits} credits per month</RawItem>
                <RawItem><Label>Price </Label>{subscription.plan.price ? `${subscription.plan.price}$` : 'free'}</RawItem>
            </SubscriptionContainer>
            <Divider />
            <SubscriptionContainer>
                <RawItem><Label>Status </Label><Dot error={subscription.status !== 'active'}/>{upperFirst(subscription.status)}</RawItem>
                <RawItem><Label>Next renewal </Label>{renewalDate}</RawItem>
            </SubscriptionContainer>

        </Container>
    )
}

export default SubscriptionPage;

