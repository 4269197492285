import { EGoogleTab } from 'ts/enums/google.tabs';
import { IGoogleDomain, IGoogleCountry, IGoogleLanguage } from 'ts/interfaces/google/google.data';
import OptionType from 'ts/types/option';

export const googleDomains: IGoogleDomain[] = [
    {
      domain: "google.com.af",
      countryCode: "af",
      langCode: "ps",
      countryName: "Afghanistan"
    },
    {
      domain: "google.al",
      countryCode: "al",
      langCode: "sq",
      countryName: "Albania"
    },
    {
      domain: "google.dz",
      countryCode: "dz",
      langCode: "ar",
      countryName: "Algeria"
    },
    {
      domain: "google.as",
      countryCode: "as",
      langCode: "en",
      countryName: "American Samoa"
    },
    {
      domain: "google.ad",
      countryCode: "ad",
      langCode: "ca",
      countryName: "Andorra"
    },
    {
      domain: "google.co.ao",
      countryCode: "ao",
      langCode: "pt",
      countryName: "Angola"
    },
    {
      domain: "google.com.ai",
      countryCode: "ai",
      langCode: "en",
      countryName: "Anguilla"
    },
    {
      domain: "google.com.ag",
      countryCode: "ag",
      langCode: "en",
      countryName: "Antigua and Barbuda"
    },
    {
      domain: "google.com.ar",
      countryCode: "ar",
      langCode: "es",
      countryName: "Argentina"
    },
    {
      domain: "google.am",
      countryCode: "am",
      langCode: "hy",
      countryName: "Armenia"
    },
    {
      domain: "google.com.au",
      countryCode: "au",
      langCode: "en",
      countryName: "Australia"
    },
    {
      domain: "google.at",
      countryCode: "at",
      langCode: "de",
      countryName: "Austria"
    },
    {
      domain: "google.az",
      countryCode: "az",
      langCode: "az",
      countryName: "Azerbaijan"
    },
    {
      domain: "google.bs",
      countryCode: "bs",
      langCode: "en",
      countryName: "Bahamas"
    },
    {
      domain: "google.com.bh",
      countryCode: "bh",
      langCode: "ar",
      countryName: "Bahrain"
    },
    {
      domain: "google.com.bd",
      countryCode: "bd",
      langCode: "bn",
      countryName: "Bangladesh"
    },
    {
      domain: "google.by",
      countryCode: "by",
      langCode: "be",
      countryName: "Belarus"
    },
    {
      domain: "google.be",
      countryCode: "be",
      langCode: "nl",
      countryName: "Belgium"
    },
    {
      domain: "google.com.bz",
      countryCode: "bz",
      langCode: "en",
      countryName: "Belize"
    },
    {
      domain: "google.bj",
      countryCode: "bj",
      langCode: "fr",
      countryName: "Benin"
    },
    {
      domain: "google.bt",
      countryCode: "bt",
      langCode: "dz",
      countryName: "Bhutan"
    },
    {
      domain: "google.com.bo",
      countryCode: "bo",
      langCode: "es",
      countryName: "Bolivia"
    },
    {
      domain: "google.ba",
      countryCode: "ba",
      langCode: "bs",
      countryName: "Bosnia and Herzegovina"
    },
    {
      domain: "google.co.bw",
      countryCode: "bw",
      langCode: "en",
      countryName: "Botswana"
    },
    {
      domain: "google.com.br",
      countryCode: "br",
      langCode: "pt",
      countryName: "Brazil"
    },
    {
      domain: "google.vg",
      countryCode: "vg",
      langCode: "en",
      countryName: "British Virgin Islands"
    },
    {
      domain: "google.com.bn",
      countryCode: "bn",
      langCode: "ms",
      countryName: "Brunei"
    },
    {
      domain: "google.bg",
      countryCode: "bg",
      langCode: "bg",
      countryName: "Bulgaria"
    },
    {
      domain: "google.bf",
      countryCode: "bf",
      langCode: "fr",
      countryName: "Burkina Faso"
    },
    {
      domain: "google.bi",
      countryCode: "bi",
      langCode: "fr",
      countryName: "Burundi"
    },
    {
      domain: "google.com.kh",
      countryCode: "kh",
      langCode: "km",
      countryName: "Cambodia"
    },
    {
      domain: "google.cm",
      countryCode: "cm",
      langCode: "en",
      countryName: "Cameroon"
    },
    {
      domain: "google.ca",
      countryCode: "ca",
      langCode: "en",
      countryName: "Canada"
    },
    {
      domain: "google.cv",
      countryCode: "cv",
      langCode: "pt",
      countryName: "Cape Verde"
    },
    {
      domain: "google.cf",
      countryCode: "cf",
      langCode: "fr",
      countryName: "Central African Republic"
    },
    {
      domain: "google.td",
      countryCode: "td",
      langCode: "ar",
      countryName: "Chad"
    },
    {
      domain: "google.cl",
      countryCode: "cl",
      langCode: "es",
      countryName: "Chile"
    },
    {
      domain: "google.cn",
      countryCode: "cn",
      langCode: "zh",
      countryName: "China"
    },
    {
      domain: "google.cx",
      countryCode: "cx",
      langCode: "en",
      countryName: "Christmas Island"
    },
    {
      domain: "google.cc",
      countryCode: "cc",
      langCode: "en",
      countryName: "Cocos (Keeling) Islands"
    },
    {
      domain: "google.com.co",
      countryCode: "co",
      langCode: "es",
      countryName: "Colombia"
    },
    {
      domain: "google.co.ck",
      countryCode: "ck",
      langCode: "en",
      countryName: "Cook Islands"
    },
    {
      domain: "google.co.cr",
      countryCode: "cr",
      langCode: "es",
      countryName: "Costa Rica"
    },
    {
      domain: "google.hr",
      countryCode: "hr",
      langCode: "hr",
      countryName: "Croatia"
    },
    {
      domain: "google.com.cu",
      countryCode: "cu",
      langCode: "es",
      countryName: "Cuba"
    },
    {
      domain: "google.com.cy",
      countryCode: "cy",
      langCode: "el",
      countryName: "Cyprus"
    },
    {
      domain: "google.cz",
      countryCode: "cz",
      langCode: "cs",
      countryName: "Czech Republic"
    },
    {
      domain: "google.cd",
      countryCode: "cg",
      langCode: "fr",
      countryName: "Democratic Rep of Congo"
    },
    {
      domain: "google.dk",
      countryCode: "dk",
      langCode: "da",
      countryName: "Denmark"
    },
    {
      domain: "google.dj",
      countryCode: "dj",
      langCode: "ar",
      countryName: "Djibouti"
    },
    {
      domain: "google.dm",
      countryCode: "dm",
      langCode: "en",
      countryName: "Dominica"
    },
    {
      domain: "google.com.do",
      countryCode: "do",
      langCode: "es",
      countryName: "Dominican Republic"
    },
    {
      domain: "google.com.ec",
      countryCode: "ec",
      langCode: "es",
      countryName: "Ecuador"
    },
    {
      domain: "google.com.eg",
      countryCode: "eg",
      langCode: "ar",
      countryName: "Egypt"
    },
    {
      domain: "google.com.sv",
      countryCode: "sv",
      langCode: "es",
      countryName: "El Salvador"
    },
    {
      domain: "google.ee",
      countryCode: "ee",
      langCode: "et",
      countryName: "Estonia"
    },
    {
      domain: "google.com.et",
      countryCode: "et",
      langCode: "am",
      countryName: "Ethiopia"
    },
    {
      domain: "google.com.fj",
      countryCode: "fj",
      langCode: "en",
      countryName: "Fiji"
    },
    {
      domain: "google.fi",
      countryCode: "fi",
      langCode: "fi",
      countryName: "Finland"
    },
    {
      domain: "google.fr",
      countryCode: "fr",
      langCode: "fr",
      countryName: "France"
    },
    {
      domain: "google.gf",
      countryCode: "gf",
      langCode: "fr",
      countryName: "French Guiana"
    },
    {
      domain: "google.ga",
      countryCode: "ga",
      langCode: "fr",
      countryName: "Gabon"
    },
    {
      domain: "google.gm",
      countryCode: "gm",
      langCode: "en",
      countryName: "Gambia"
    },
    {
      domain: "google.ge",
      countryCode: "ge",
      langCode: "ka",
      countryName: "Georgia"
    },
    {
      domain: "google.de",
      countryCode: "de",
      langCode: "de",
      countryName: "Germany"
    },
    {
      domain: "google.com.gh",
      countryCode: "gh",
      langCode: "en",
      countryName: "Ghana"
    },
    {
      domain: "google.com.gi",
      countryCode: "gi",
      langCode: "en",
      countryName: "Gibraltar"
    },
    {
      domain: "google.gr",
      countryCode: "gr",
      langCode: "el",
      countryName: "Greece"
    },
    {
      domain: "google.gl",
      countryCode: "gl",
      langCode: "kl",
      countryName: "Greenland"
    },
    {
      domain: "google.gp",
      countryCode: "gp",
      langCode: "fr",
      countryName: "Guadeloupe"
    },
    {
      domain: "google.com.gt",
      countryCode: "gt",
      langCode: "es",
      countryName: "Guatemala"
    },
    {
      domain: "google.gy",
      countryCode: "gy",
      langCode: "en",
      countryName: "Guyana"
    },
    {
      domain: "google.ht",
      countryCode: "ht",
      langCode: "fr",
      countryName: "Haiti"
    },
    {
      domain: "google.hn",
      countryCode: "hn",
      langCode: "es",
      countryName: "Honduras"
    },
    {
      domain: "google.com.hk",
      countryCode: "hk",
      langCode: "en",
      countryName: "Hong Kong"
    },
    {
      domain: "google.hu",
      countryCode: "hu",
      langCode: "hu",
      countryName: "Hungary"
    },
    {
      domain: "google.is",
      countryCode: "is",
      langCode: "is",
      countryName: "Iceland"
    },
    {
      domain: "google.co.in",
      countryCode: "in",
      langCode: "hi",
      countryName: "India"
    },
    {
      domain: "google.io",
      countryCode: "io",
      langCode: "en",
      countryName: "Indian Ocean Territory"
    },
    {
      domain: "google.co.id",
      countryCode: "id",
      langCode: "id",
      countryName: "Indonesia"
    },
    {
      domain: "google.iq",
      countryCode: "iq",
      langCode: "ar",
      countryName: "Iraq"
    },
    {
      domain: "google.ie",
      countryCode: "ie",
      langCode: "en",
      countryName: "Ireland"
    },
    {
      domain: "google.co.il",
      countryCode: "il",
      langCode: "he",
      countryName: "Israel"
    },
    {
      domain: "google.it",
      countryCode: "it",
      langCode: "it",
      countryName: "Italy"
    },
    {
      domain: "google.ci",
      countryCode: "ci",
      langCode: "fr",
      countryName: "Ivory Coast"
    },
    {
      domain: "google.com.jm",
      countryCode: "jm",
      langCode: "en",
      countryName: "Jamaica"
    },
    {
      domain: "google.co.jp",
      countryCode: "jp",
      langCode: "ja",
      countryName: "Japan"
    },
    {
      domain: "google.jo",
      countryCode: "jo",
      langCode: "ar",
      countryName: "Jordan"
    },
    {
      domain: "google.kz",
      countryCode: "kz",
      langCode: "kk",
      countryName: "Kazakhstan"
    },
    {
      domain: "google.co.ke",
      countryCode: "ke",
      langCode: "en",
      countryName: "Kenya"
    },
    {
      domain: "google.ki",
      countryCode: "ki",
      langCode: "en",
      countryName: "Kiribati"
    },
    {
      domain: "google.com.kw",
      countryCode: "kw",
      langCode: "ar",
      countryName: "Kuwait"
    },
    {
      domain: "google.kg",
      countryCode: "kg",
      langCode: "ky",
      countryName: "Kyrgyzstan"
    },
    {
      domain: "google.la",
      countryCode: "la",
      langCode: "lo",
      countryName: "Laos"
    },
    {
      domain: "google.lv",
      countryCode: "lv",
      langCode: "lv",
      countryName: "Latvia"
    },
    {
      domain: "google.com.lb",
      countryCode: "lb",
      langCode: "ar",
      countryName: "Lebanon"
    },
    {
      domain: "google.co.ls",
      countryCode: "ls",
      langCode: "en",
      countryName: "Lesotho"
    },
    {
      domain: "google.com.ly",
      countryCode: "ly",
      langCode: "ar",
      countryName: "Libya"
    },
    {
      domain: "google.li",
      countryCode: "li",
      langCode: "de",
      countryName: "Liechtenstein"
    },
    {
      domain: "google.lt",
      countryCode: "lt",
      langCode: "lt",
      countryName: "Lithuania"
    },
    {
      domain: "google.lu",
      countryCode: "lu",
      langCode: "fr",
      countryName: "Luxembourg"
    },
    {
      domain: "google.mk",
      countryCode: "mk",
      langCode: "mk",
      countryName: "Macedonia"
    },
    {
      domain: "google.mg",
      countryCode: "mg",
      langCode: "fr",
      countryName: "Madagascar"
    },
    {
      domain: "google.mw",
      countryCode: "mw",
      langCode: "en",
      countryName: "Malawi"
    },
    {
      domain: "google.com.my",
      countryCode: "my",
      langCode: "ms",
      countryName: "Malaysia"
    },
    {
      domain: "google.mv",
      countryCode: "mv",
      langCode: "dv",
      countryName: "Maldives"
    },
    {
      domain: "google.ml",
      countryCode: "ml",
      langCode: "fr",
      countryName: "Mali"
    },
    {
      domain: "google.com.mt",
      countryCode: "mt",
      langCode: "mt",
      countryName: "Malta"
    },
    {
      domain: "google.mu",
      countryCode: "mu",
      langCode: "en",
      countryName: "Mauritius"
    },
    {
      domain: "google.com.mx",
      countryCode: "mx",
      langCode: "es",
      countryName: "Mexico"
    },
    {
      domain: "google.fm",
      countryCode: "fm",
      langCode: "en",
      countryName: "Micronesia"
    },
    {
      domain: "google.md",
      countryCode: "md",
      langCode: "ro",
      countryName: "Moldova"
    },
    {
      domain: "google.mn",
      countryCode: "mn",
      langCode: "mn",
      countryName: "Mongolia"
    },
    {
      domain: "google.ms",
      countryCode: "ms",
      langCode: "en",
      countryName: "Montserrat"
    },
    {
      domain: "google.co.ma",
      countryCode: "ma",
      langCode: "ar",
      countryName: "Morocco"
    },
    {
      domain: "google.co.mz",
      countryCode: "mz",
      langCode: "pt",
      countryName: "Mozambique"
    },
    {
      domain: "google.com.mm",
      countryCode: "mm",
      langCode: "my",
      countryName: "Myanmar"
    },
    {
      domain: "google.com.na",
      countryCode: "na",
      langCode: "en",
      countryName: "Namibia"
    },
    {
      domain: "google.nr",
      countryCode: "nr",
      langCode: "en",
      countryName: "Nauru"
    },
    {
      domain: "google.com.np",
      countryCode: "np",
      langCode: "ne",
      countryName: "Nepal"
    },
    {
      domain: "google.nl",
      countryCode: "nl",
      langCode: "nl",
      countryName: "Netherlands"
    },
    {
      domain: "google.co.nz",
      countryCode: "nz",
      langCode: "en",
      countryName: "New Zealand"
    },
    {
      domain: "google.com.ni",
      countryCode: "ni",
      langCode: "es",
      countryName: "Nicaragua"
    },
    {
      domain: "google.ne",
      countryCode: "ne",
      langCode: "fr",
      countryName: "Niger"
    },
    {
      domain: "google.com.ng",
      countryCode: "ng",
      langCode: "en",
      countryName: "Nigeria"
    },
    {
      domain: "google.nu",
      countryCode: "nu",
      langCode: "en",
      countryName: "Niue"
    },
    {
      domain: "google.no",
      countryCode: "no",
      langCode: "nb",
      countryName: "Norway"
    },
    {
      domain: "google.com.om",
      countryCode: "om",
      langCode: "ar",
      countryName: "Oman"
    },
    {
      domain: "google.com.pk",
      countryCode: "pk",
      langCode: "en",
      countryName: "Pakistan"
    },
    {
      domain: "google.ps",
      countryCode: "ps",
      langCode: "ar",
      countryName: "Palestine"
    },
    {
      domain: "google.com.pa",
      countryCode: "pa",
      langCode: "es",
      countryName: "Panama"
    },
    {
      domain: "google.com.pg",
      countryCode: "pg",
      langCode: "en",
      countryName: "Papua New Guinea"
    },
    {
      domain: "google.com.py",
      countryCode: "py",
      langCode: "es",
      countryName: "Paraguay"
    },
    {
      domain: "google.com.pe",
      countryCode: "pe",
      langCode: "es",
      countryName: "Peru"
    },
    {
      domain: "google.com.ph",
      countryCode: "ph",
      langCode: "tl",
      countryName: "Philippines"
    },
    {
      domain: "google.pl",
      countryCode: "pl",
      langCode: "pl",
      countryName: "Poland"
    },
    {
      domain: "google.pt",
      countryCode: "pt",
      langCode: "pt",
      countryName: "Portugal"
    },
    {
      domain: "google.com.pr",
      countryCode: "pr",
      langCode: "es",
      countryName: "Puerto Rico"
    },
    {
      domain: "google.com.qa",
      countryCode: "qa",
      langCode: "ar",
      countryName: "Qatar"
    },
    {
      domain: "google.ro",
      countryCode: "ro",
      langCode: "ro",
      countryName: "Romania"
    },
    {
      domain: "google.ru",
      countryCode: "ru",
      langCode: "ru",
      countryName: "Russia"
    },
    {
      domain: "google.rw",
      countryCode: "rw",
      langCode: "rw",
      countryName: "Rwanda"
    },
    {
      domain: "google.sh",
      countryCode: "sh",
      langCode: "en",
      countryName: "Saint Helena"
    },
    {
      domain: "google.com.vc",
      countryCode: "vc",
      langCode: "en",
      countryName: "Saint Vincent"
    },
    {
      domain: "google.ws",
      countryCode: "ws",
      langCode: "sm",
      countryName: "Samoa"
    },
    {
      domain: "google.sm",
      countryCode: "sm",
      langCode: "it",
      countryName: "San Marino"
    },
    {
      domain: "google.com.sa",
      countryCode: "sa",
      langCode: "ar",
      countryName: "Saudi Arabia"
    },
    {
      domain: "google.sn",
      countryCode: "sn",
      langCode: "fr",
      countryName: "Senegal"
    },
    {
      domain: "google.rs",
      countryCode: "rs",
      langCode: "sr",
      countryName: "Serbia"
    },
    {
      domain: "google.sc",
      countryCode: "sc",
      langCode: "fr",
      countryName: "Seychelles"
    },
    {
      domain: "google.com.sl",
      countryCode: "sl",
      langCode: "en",
      countryName: "Sierra Leone"
    },
    {
      domain: "google.com.sg",
      countryCode: "sg",
      langCode: "en",
      countryName: "Singapore"
    },
    {
      domain: "google.sk",
      countryCode: "sk",
      langCode: "sk",
      countryName: "Slovakia"
    },
    {
      domain: "google.si",
      countryCode: "si",
      langCode: "sl",
      countryName: "Slovenia"
    },
    {
      domain: "google.com.sb",
      countryCode: "sb",
      langCode: "en",
      countryName: "Solomon Islands"
    },
    {
      domain: "google.so",
      countryCode: "so",
      langCode: "so",
      countryName: "Somalia"
    },
    {
      domain: "google.co.za",
      countryCode: "za",
      langCode: "af",
      countryName: "South Africa"
    },
    {
      domain: "google.co.kr",
      countryCode: "kp",
      langCode: "ko",
      countryName: "South Korea"
    },
    {
      domain: "google.es",
      countryCode: "es",
      langCode: "es",
      countryName: "Spain"
    },
    {
      domain: "google.lk",
      countryCode: "lk",
      langCode: "si",
      countryName: "Sri Lanka"
    },
    {
      domain: "google.sr",
      countryCode: "sr",
      langCode: "nl",
      countryName: "Suriname"
    },
    {
      domain: "google.se",
      countryCode: "se",
      langCode: "sv",
      countryName: "Sweden"
    },
    {
      domain: "google.ch",
      countryCode: "ch",
      langCode: "de",
      countryName: "Switzerland"
    },
    {
      domain: "google.com.tw",
      countryCode: "tw",
      langCode: "zh",
      countryName: "Taiwan"
    },
    {
      domain: "google.com.tj",
      countryCode: "tj",
      langCode: "tg",
      countryName: "Tajikistan"
    },
    {
      domain: "google.co.tz",
      countryCode: "tz",
      langCode: "sw",
      countryName: "Tanzania"
    },
    {
      domain: "google.co.th",
      countryCode: "th",
      langCode: "th",
      countryName: "Thailand"
    },
    {
      domain: "google.tl",
      countryCode: "tl",
      langCode: "pt",
      countryName: "Timor-Leste"
    },
    {
      domain: "google.tg",
      countryCode: "tg",
      langCode: "fr",
      countryName: "Togo"
    },
    {
      domain: "google.tk",
      countryCode: "tk",
      langCode: "en",
      countryName: "Tokelau"
    },
    {
      domain: "google.to",
      countryCode: "to",
      langCode: "en",
      countryName: "Tonga"
    },
    {
      domain: "google.tt",
      countryCode: "tt",
      langCode: "en",
      countryName: "Trinidad and Tobago"
    },
    {
      domain: "google.tn",
      countryCode: "tn",
      langCode: "ar",
      countryName: "Tunisia"
    },
    {
      domain: "google.com.tr",
      countryCode: "tr",
      langCode: "tr",
      countryName: "Turkey"
    },
    {
      domain: "google.tm",
      countryCode: "tm",
      langCode: "tk",
      countryName: "Turkmenistan"
    },
    {
      domain: "google.co.ug",
      countryCode: "ug",
      langCode: "en",
      countryName: "Uganda"
    },
    {
      domain: "google.com.ua",
      countryCode: "ua",
      langCode: "uk",
      countryName: "Ukraine"
    },
    {
      domain: "google.ae",
      countryCode: "ae",
      langCode: "ar",
      countryName: "United Arab Emirates"
    },
    {
      domain: "google.co.uk",
      countryCode: "uk",
      langCode: "en",
      countryName: "United Kingdom"
    },
    {
      domain: "google.com",
      countryCode: "us",
      langCode: "en",
      countryName: "United States"
    },
    {
      domain: "google.com.uy",
      countryCode: "uy",
      langCode: "es",
      countryName: "Uruguay"
    },
    {
      domain: "google.co.vi",
      countryCode: "vi",
      langCode: "en",
      countryName: "US Virgin Islands"
    },
    {
      domain: "google.co.uz",
      countryCode: "uz",
      langCode: "uz",
      countryName: "Uzbekistan"
    },
    {
      domain: "google.vu",
      countryCode: "vu",
      langCode: "bi",
      countryName: "Vanuatu"
    },
    {
      domain: "google.co.ve",
      countryCode: "ve",
      langCode: "es",
      countryName: "Venezuela"
    },
    {
      domain: "google.com.vn",
      countryCode: "vn",
      langCode: "vi",
      countryName: "Vietnam"
    },
    {
      domain: "google.co.zm",
      countryCode: "zm",
      langCode: "en",
      countryName: "Zambia"
    },
    {
      domain: "google.co.zw",
      countryCode: "zw",
      langCode: "en",
      countryName: "Zimbabwe"
    }
];

export const googleCountries: IGoogleCountry[] = [
  {
    countryCode: "af",
    countryName: "Afghanistan"
  },
  {
    countryCode: "al",
    countryName: "Albania"
  },
  {
    countryCode: "dz",
    countryName: "Algeria"
  },
  {
    countryCode: "as",
    countryName: "American Samoa"
  },
  {
    countryCode: "ad",
    countryName: "Andorra"
  },
  {
    countryCode: "ao",
    countryName: "Angola"
  },
  {
    countryCode: "ai",
    countryName: "Anguilla"
  },
  {
    countryCode: "aq",
    countryName: "Antarctica"
  },
  {
    countryCode: "ag",
    countryName: "Antigua and Barbuda"
  },
  {
    countryCode: "ar",
    countryName: "Argentina"
  },
  {
    countryCode: "am",
    countryName: "Armenia"
  },
  {
    countryCode: "aw",
    countryName: "Aruba"
  },
  {
    countryCode: "au",
    countryName: "Australia"
  },
  {
    countryCode: "at",
    countryName: "Austria"
  },
  {
    countryCode: "az",
    countryName: "Azerbaijan"
  },
  {
    countryCode: "bs",
    countryName: "Bahamas"
  },
  {
    countryCode: "bh",
    countryName: "Bahrain"
  },
  {
    countryCode: "bd",
    countryName: "Bangladesh"
  },
  {
    countryCode: "bb",
    countryName: "Barbados"
  },
  {
    countryCode: "by",
    countryName: "Belarus"
  },
  {
    countryCode: "be",
    countryName: "Belgium"
  },
  {
    countryCode: "bz",
    countryName: "Belize"
  },
  {
    countryCode: "bj",
    countryName: "Benin"
  },
  {
    countryCode: "bm",
    countryName: "Bermuda"
  },
  {
    countryCode: "bt",
    countryName: "Bhutan"
  },
  {
    countryCode: "bo",
    countryName: "Bolivia"
  },
  {
    countryCode: "bq",
    countryName: "Bonaire"
  },
  {
    countryCode: "ba",
    countryName: "Bosnia and Herzegovina"
  },
  {
    countryCode: "bw",
    countryName: "Botswana"
  },
  {
    countryCode: "bv",
    countryName: "Bouvet Island"
  },
  {
    countryCode: "br",
    countryName: "Brazil"
  },
  {
    countryCode: "bn",
    countryName: "Brunei Darussalam"
  },
  {
    countryCode: "bg",
    countryName: "Bulgaria"
  },
  {
    countryCode: "bf",
    countryName: "Burkina Faso"
  },
  {
    countryCode: "bi",
    countryName: "Burundi"
  },
  {
    countryCode: "kh",
    countryName: "Cambodia"
  },
  {
    countryCode: "cm",
    countryName: "Cameroon"
  },
  {
    countryCode: "ca",
    countryName: "Canada"
  },
  {
    countryCode: "cv",
    countryName: "Cape Verde"
  },
  {
    countryCode: "ky",
    countryName: "Cayman Islands"
  },
  {
    countryCode: "cf",
    countryName: "Central African Republic"
  },
  {
    countryCode: "td",
    countryName: "Chad"
  },
  {
    countryCode: "cl",
    countryName: "Chile"
  },
  {
    countryCode: "cn",
    countryName: "China"
  },
  {
    countryCode: "cx",
    countryName: "Christmas Island"
  },
  {
    countryCode: "cc",
    countryName: "Cocos (Keeling) Islands"
  },
  {
    countryCode: "co",
    countryName: "Colombia"
  },
  {
    countryCode: "km",
    countryName: "Comoros"
  },
  {
    countryCode: "cg",
    countryName: "Congo"
  },
  {
    countryCode: "ck",
    countryName: "Cook Islands"
  },
  {
    countryCode: "cr",
    countryName: "Costa Rica"
  },
  {
    countryCode: "ci",
    countryName: "Cote D'ivoire"
  },
  {
    countryCode: "hr",
    countryName: "Croatia"
  },
  {
    countryCode: "cu",
    countryName: "Cuba"
  },
  {
    countryCode: "cw",
    countryName: "Curaçao"
  },
  {
    countryCode: "cy",
    countryName: "Cyprus"
  },
  {
    countryCode: "cz",
    countryName: "Czech Republic"
  },
  {
    countryCode: "cd",
    countryName: "Democratic Rep Congo"
  },
  {
    countryCode: "dk",
    countryName: "Denmark"
  },
  {
    countryCode: "dj",
    countryName: "Djibouti"
  },
  {
    countryCode: "dm",
    countryName: "Dominica"
  },
  {
    countryCode: "do",
    countryName: "Dominican Republic"
  },
  {
    countryCode: "ec",
    countryName: "Ecuador"
  },
  {
    countryCode: "eg",
    countryName: "Egypt"
  },
  {
    countryCode: "sv",
    countryName: "El Salvador"
  },
  {
    countryCode: "gq",
    countryName: "Equatorial Guinea"
  },
  {
    countryCode: "er",
    countryName: "Eritrea"
  },
  {
    countryCode: "ee",
    countryName: "Estonia"
  },
  {
    countryCode: "et",
    countryName: "Ethiopia"
  },
  {
    countryCode: "fk",
    countryName: "Falkland Islands (Malvinas)"
  },
  {
    countryCode: "fo",
    countryName: "Faroe Islands"
  },
  {
    countryCode: "fj",
    countryName: "Fiji"
  },
  {
    countryCode: "fi",
    countryName: "Finland"
  },
  {
    countryCode: "fr",
    countryName: "France"
  },
  {
    countryCode: "gf",
    countryName: "French Guiana"
  },
  {
    countryCode: "pf",
    countryName: "French Polynesia"
  },
  {
    countryCode: "tf",
    countryName: "French Southern Territories"
  },
  {
    countryCode: "ga",
    countryName: "Gabon"
  },
  {
    countryCode: "gm",
    countryName: "Gambia"
  },
  {
    countryCode: "ge",
    countryName: "Georgia"
  },
  {
    countryCode: "de",
    countryName: "Germany"
  },
  {
    countryCode: "gh",
    countryName: "Ghana"
  },
  {
    countryCode: "gi",
    countryName: "Gibraltar"
  },
  {
    countryCode: "gr",
    countryName: "Greece"
  },
  {
    countryCode: "gl",
    countryName: "Greenland"
  },
  {
    countryCode: "gd",
    countryName: "Grenada"
  },
  {
    countryCode: "gp",
    countryName: "Guadeloupe"
  },
  {
    countryCode: "gu",
    countryName: "Guam"
  },
  {
    countryCode: "gt",
    countryName: "Guatemala"
  },
  {
    countryCode: "gg",
    countryName: "Guernsey"
  },
  {
    countryCode: "gn",
    countryName: "Guinea"
  },
  {
    countryCode: "gw",
    countryName: "Guinea-Bissau"
  },
  {
    countryCode: "gy",
    countryName: "Guyana"
  },
  {
    countryCode: "ht",
    countryName: "Haiti"
  },
  {
    countryCode: "hm",
    countryName: "Heard Island and Mcdonald Islands"
  },
  {
    countryCode: "va",
    countryName: "Holy See (Vatican City State)"
  },
  {
    countryCode: "hn",
    countryName: "Honduras"
  },
  {
    countryCode: "hk",
    countryName: "Hong Kong"
  },
  {
    countryCode: "hu",
    countryName: "Hungary"
  },
  {
    countryCode: "is",
    countryName: "Iceland"
  },
  {
    countryCode: "in",
    countryName: "India"
  },
  {
    countryCode: "io",
    countryName: "Indian Ocean Territory"
  },
  {
    countryCode: "id",
    countryName: "Indonesia"
  },
  {
    countryCode: "ir",
    countryName: "Iran, Islamic Republic of"
  },
  {
    countryCode: "iq",
    countryName: "Iraq"
  },
  {
    countryCode: "ie",
    countryName: "Ireland"
  },
  {
    countryCode: "im",
    countryName: "Isle of Man"
  },
  {
    countryCode: "il",
    countryName: "Israel"
  },
  {
    countryCode: "it",
    countryName: "Italy"
  },
  {
    countryCode: "jm",
    countryName: "Jamaica"
  },
  {
    countryCode: "jp",
    countryName: "Japan"
  },
  {
    countryCode: "je",
    countryName: "Jersey"
  },
  {
    countryCode: "jo",
    countryName: "Jordan"
  },
  {
    countryCode: "kz",
    countryName: "Kazakhstan"
  },
  {
    countryCode: "ke",
    countryName: "Kenya"
  },
  {
    countryCode: "ki",
    countryName: "Kiribati"
  },
  {
    countryCode: "kr",
    countryName: "Korea"
  },
  {
    countryCode: "xk",
    countryName: "Kosovo"
  },
  {
    countryCode: "kw",
    countryName: "Kuwait"
  },
  {
    countryCode: "kg",
    countryName: "Kyrgyzstan"
  },
  {
    countryCode: "la",
    countryName: "Lao"
  },
  {
    countryCode: "lv",
    countryName: "Latvia"
  },
  {
    countryCode: "lb",
    countryName: "Lebanon"
  },
  {
    countryCode: "ls",
    countryName: "Lesotho"
  },
  {
    countryCode: "lr",
    countryName: "Liberia"
  },
  {
    countryCode: "ly",
    countryName: "Libyan Arab Jamahiriya"
  },
  {
    countryCode: "li",
    countryName: "Liechtenstein"
  },
  {
    countryCode: "lt",
    countryName: "Lithuania"
  },
  {
    countryCode: "lu",
    countryName: "Luxembourg"
  },
  {
    countryCode: "mo",
    countryName: "Macao"
  },
  {
    countryCode: "mk",
    countryName: "Macedonia, the Former Yugosalv Republic of"
  },
  {
    countryCode: "mg",
    countryName: "Madagascar"
  },
  {
    countryCode: "mw",
    countryName: "Malawi"
  },
  {
    countryCode: "my",
    countryName: "Malaysia"
  },
  {
    countryCode: "mv",
    countryName: "Maldives"
  },
  {
    countryCode: "ml",
    countryName: "Mali"
  },
  {
    countryCode: "mt",
    countryName: "Malta"
  },
  {
    countryCode: "mh",
    countryName: "Marshall Islands"
  },
  {
    countryCode: "mq",
    countryName: "Martinique"
  },
  {
    countryCode: "mr",
    countryName: "Mauritania"
  },
  {
    countryCode: "mu",
    countryName: "Mauritius"
  },
  {
    countryCode: "yt",
    countryName: "Mayotte"
  },
  {
    countryCode: "mx",
    countryName: "Mexico"
  },
  {
    countryCode: "fm",
    countryName: "Micronesia, Federated States of"
  },
  {
    countryCode: "md",
    countryName: "Moldova, Republic of"
  },
  {
    countryCode: "mc",
    countryName: "Monaco"
  },
  {
    countryCode: "mn",
    countryName: "Mongolia"
  },
  {
    countryCode: "ms",
    countryName: "Montserrat"
  },
  {
    countryCode: "ma",
    countryName: "Morocco"
  },
  {
    countryCode: "mz",
    countryName: "Mozambique"
  },
  {
    countryCode: "mm",
    countryName: "Myanmar"
  },
  {
    countryCode: "na",
    countryName: "Namibia"
  },
  {
    countryCode: "nr",
    countryName: "Nauru"
  },
  {
    countryCode: "np",
    countryName: "Nepal"
  },
  {
    countryCode: "nl",
    countryName: "Netherlands"
  },
  {
    countryCode: "an",
    countryName: "Netherlands Antilles"
  },
  {
    countryCode: "nc",
    countryName: "New Caledonia"
  },
  {
    countryCode: "nz",
    countryName: "New Zealand"
  },
  {
    countryCode: "ni",
    countryName: "Nicaragua"
  },
  {
    countryCode: "ne",
    countryName: "Niger"
  },
  {
    countryCode: "ng",
    countryName: "Nigeria"
  },
  {
    countryCode: "nu",
    countryName: "Niue"
  },
  {
    countryCode: "nf",
    countryName: "Norfolk Island"
  },
  {
    countryCode: "mp",
    countryName: "Northern Mariana Islands"
  },
  {
    countryCode: "no",
    countryName: "Norway"
  },
  {
    countryCode: "om",
    countryName: "Oman"
  },
  {
    countryCode: "pk",
    countryName: "Pakistan"
  },
  {
    countryCode: "pw",
    countryName: "Palau"
  },
  {
    countryCode: "ps",
    countryName: "Palestinian Territory, Occupied"
  },
  {
    countryCode: "pa",
    countryName: "Panama"
  },
  {
    countryCode: "pg",
    countryName: "Papua New Guinea"
  },
  {
    countryCode: "py",
    countryName: "Paraguay"
  },
  {
    countryCode: "pe",
    countryName: "Peru"
  },
  {
    countryCode: "ph",
    countryName: "Philippines"
  },
  {
    countryCode: "pn",
    countryName: "Pitcairn"
  },
  {
    countryCode: "pl",
    countryName: "Poland"
  },
  {
    countryCode: "pt",
    countryName: "Portugal"
  },
  {
    countryCode: "pr",
    countryName: "Puerto Rico"
  },
  {
    countryCode: "qa",
    countryName: "Qatar"
  },
  {
    countryCode: "re",
    countryName: "Reunion"
  },
  {
    countryCode: "ro",
    countryName: "Romania"
  },
  {
    countryCode: "ru",
    countryName: "Russian Federation"
  },
  {
    countryCode: "rw",
    countryName: "Rwanda"
  },
  {
    countryCode: "sh",
    countryName: "Saint Helena"
  },
  {
    countryCode: "kn",
    countryName: "Saint Kitts and Nevis"
  },
  {
    countryCode: "lc",
    countryName: "Saint Lucia"
  },
  {
    countryCode: "pm",
    countryName: "Saint Pierre and Miquelon"
  },
  {
    countryCode: "vc",
    countryName: "Saint Vincent"
  },
  {
    countryCode: "ws",
    countryName: "Samoa"
  },
  {
    countryCode: "sm",
    countryName: "San Marino"
  },
  {
    countryCode: "st",
    countryName: "Sao Tome and Principe"
  },
  {
    countryCode: "sa",
    countryName: "Saudi Arabia"
  },
  {
    countryCode: "sn",
    countryName: "Senegal"
  },
  {
    countryCode: "rs",
    countryName: "Serbia and Montenegro"
  },
  {
    countryCode: "sc",
    countryName: "Seychelles"
  },
  {
    countryCode: "sl",
    countryName: "Sierra Leone"
  },
  {
    countryCode: "sg",
    countryName: "Singapore"
  },
  {
    countryCode: "sx",
    countryName: "Sint Maarten"
  },
  {
    countryCode: "sk",
    countryName: "Slovakia"
  },
  {
    countryCode: "si",
    countryName: "Slovenia"
  },
  {
    countryCode: "sb",
    countryName: "Solomon Islands"
  },
  {
    countryCode: "so",
    countryName: "Somalia"
  },
  {
    countryCode: "za",
    countryName: "South Africa"
  },
  {
    countryCode: "gs",
    countryName: "South Georgia and the South Sandwich Islands"
  },
  {
    countryCode: "es",
    countryName: "Spain"
  },
  {
    countryCode: "lk",
    countryName: "Sri Lanka"
  },
  {
    countryCode: "sd",
    countryName: "Sudan"
  },
  {
    countryCode: "sr",
    countryName: "Suriname"
  },
  {
    countryCode: "sj",
    countryName: "Svalbard and Jan Mayen"
  },
  {
    countryCode: "sz",
    countryName: "Swaziland"
  },
  {
    countryCode: "se",
    countryName: "Sweden"
  },
  {
    countryCode: "ch",
    countryName: "Switzerland"
  },
  {
    countryCode: "sy",
    countryName: "Syrian Arab Republic"
  },
  {
    countryCode: "tw",
    countryName: "Taiwan, Province of China"
  },
  {
    countryCode: "tj",
    countryName: "Tajikistan"
  },
  {
    countryCode: "tz",
    countryName: "Tanzania, United Republic of"
  },
  {
    countryCode: "th",
    countryName: "Thailand"
  },
  {
    countryCode: "tl",
    countryName: "Timor-Leste"
  },
  {
    countryCode: "tg",
    countryName: "Togo"
  },
  {
    countryCode: "tk",
    countryName: "Tokelau"
  },
  {
    countryCode: "to",
    countryName: "Tonga"
  },
  {
    countryCode: "tt",
    countryName: "Trinidad and Tobago"
  },
  {
    countryCode: "tn",
    countryName: "Tunisia"
  },
  {
    countryCode: "tr",
    countryName: "Turkey"
  },
  {
    countryCode: "tm",
    countryName: "Turkmenistan"
  },
  {
    countryCode: "tc",
    countryName: "Turks and Caicos Islands"
  },
  {
    countryCode: "tv",
    countryName: "Tuvalu"
  },
  {
    countryCode: "ug",
    countryName: "Uganda"
  },
  {
    countryCode: "ua",
    countryName: "Ukraine"
  },
  {
    countryCode: "ae",
    countryName: "United Arab Emirates"
  },
  {
    countryCode: "uk",
    countryName: "United Kingdom"
  },
  {
    countryCode: "gb",
    countryName: "United Kingdom"
  },
  {
    countryCode: "us",
    countryName: "United States"
  },
  {
    countryCode: "um",
    countryName: "United States Minor Outlying Islands"
  },
  {
    countryCode: "uy",
    countryName: "Uruguay"
  },
  {
    countryCode: "uz",
    countryName: "Uzbekistan"
  },
  {
    countryCode: "vu",
    countryName: "Vanuatu"
  },
  {
    countryCode: "ve",
    countryName: "Venezuela"
  },
  {
    countryCode: "vn",
    countryName: "Viet Nam"
  },
  {
    countryCode: "vg",
    countryName: "Virgin Islands, British"
  },
  {
    countryCode: "vi",
    countryName: "Virgin Islands, U.S."
  },
  {
    countryCode: "wf",
    countryName: "Wallis and Futuna"
  },
  {
    countryCode: "eh",
    countryName: "Western Sahara"
  },
  {
    countryCode: "ye",
    countryName: "Yemen"
  },
  {
    countryCode: "zm",
    countryName: "Zambia"
  },
  {
    countryCode: "zw",
    countryName: "Zimbabwe"
  }
]

export const googleLanguages: IGoogleLanguage[] = [
  {
    langCode: "af",
    langName: "Afrikaans"
  },
  {
    langCode: "ak",
    langName: "Akan"
  },
  {
    langCode: "sq",
    langName: "Albanian"
  },
  {
    langCode: "am",
    langName: "Amharic"
  },
  {
    langCode: "ar",
    langName: "Arabic"
  },
  {
    langCode: "hy",
    langName: "Armenian"
  },
  {
    langCode: "az",
    langName: "Azerbaijani"
  },
  {
    langCode: "eu",
    langName: "Basque"
  },
  {
    langCode: "be",
    langName: "Belarusian"
  },
  {
    langCode: "bem",
    langName: "Bemba"
  },
  {
    langCode: "bn",
    langName: "Bengali"
  },
  {
    langCode: "bh",
    langName: "Bihari"
  },
  {
    langCode: "bs",
    langName: "Bosnian"
  },
  {
    langCode: "br",
    langName: "Breton"
  },
  {
    langCode: "bg",
    langName: "Bulgarian"
  },
  {
    langCode: "km",
    langName: "Cambodian"
  },
  {
    langCode: "ca",
    langName: "Catalan"
  },
  {
    langCode: "chr",
    langName: "Cherokee"
  },
  {
    langCode: "ny",
    langName: "Chichewa"
  },
  {
    langCode: "zh-cn",
    langName: "Chinese (Simplified)"
  },
  {
    langCode: "zh-tw",
    langName: "Chinese (Traditional)"
  },
  {
    langCode: "co",
    langName: "Corsican"
  },
  {
    langCode: "hr",
    langName: "Croatian"
  },
  {
    langCode: "cs",
    langName: "Czech"
  },
  {
    langCode: "da",
    langName: "Danish"
  },
  {
    langCode: "nl",
    langName: "Dutch"
  },
  {
    langCode: "en",
    langName: "English"
  },
  {
    langCode: "eo",
    langName: "Esperanto"
  },
  {
    langCode: "et",
    langName: "Estonian"
  },
  {
    langCode: "ee",
    langName: "Ewe"
  },
  {
    langCode: "fo",
    langName: "Faroese"
  },
  {
    langCode: "tl",
    langName: "Filipino"
  },
  {
    langCode: "fi",
    langName: "Finnish"
  },
  {
    langCode: "fr",
    langName: "French"
  },
  {
    langCode: "fy",
    langName: "Frisian"
  },
  {
    langCode: "gaa",
    langName: "Ga"
  },
  {
    langCode: "gl",
    langName: "Galician"
  },
  {
    langCode: "ka",
    langName: "Georgian"
  },
  {
    langCode: "de",
    langName: "German"
  },
  {
    langCode: "el",
    langName: "Greek"
  },
  {
    langCode: "gn",
    langName: "Guarani"
  },
  {
    langCode: "gu",
    langName: "Gujarati"
  },
  {
    langCode: "ht",
    langName: "Haitian Creole"
  },
  {
    langCode: "ha",
    langName: "Hausa"
  },
  {
    langCode: "haw",
    langName: "Hawaiian"
  },
  {
    langCode: "he",
    langName: "Hebrew"
  },
  {
    langCode: "iw",
    langName: "Hebrew"
  },
  {
    langCode: "hi",
    langName: "Hindi"
  },
  {
    langCode: "hu",
    langName: "Hungarian"
  },
  {
    langCode: "is",
    langName: "Icelandic"
  },
  {
    langCode: "ig",
    langName: "Igbo"
  },
  {
    langCode: "id",
    langName: "Indonesian"
  },
  {
    langCode: "ia",
    langName: "Interlingua"
  },
  {
    langCode: "ga",
    langName: "Irish"
  },
  {
    langCode: "it",
    langName: "Italian"
  },
  {
    langCode: "ja",
    langName: "Japanese"
  },
  {
    langCode: "jw",
    langName: "Javanese"
  },
  {
    langCode: "kn",
    langName: "Kannada"
  },
  {
    langCode: "kk",
    langName: "Kazakh"
  },
  {
    langCode: "rw",
    langName: "Kinyarwanda"
  },
  {
    langCode: "rn",
    langName: "Kirundi"
  },
  {
    langCode: "kg",
    langName: "Kongo"
  },
  {
    langCode: "ko",
    langName: "Korean"
  },
  {
    langCode: "kri",
    langName: "Krio (Sierra Leone)"
  },
  {
    langCode: "ku",
    langName: "Kurdish"
  },
  {
    langCode: "ckb",
    langName: "Kurdish (Soranî)"
  },
  {
    langCode: "ky",
    langName: "Kyrgyz"
  },
  {
    langCode: "lo",
    langName: "Laothian"
  },
  {
    langCode: "la",
    langName: "Latin"
  },
  {
    langCode: "lv",
    langName: "Latvian"
  },
  {
    langCode: "ln",
    langName: "Lingala"
  },
  {
    langCode: "lt",
    langName: "Lithuanian"
  },
  {
    langCode: "loz",
    langName: "Lozi"
  },
  {
    langCode: "lg",
    langName: "Luganda"
  },
  {
    langCode: "ach",
    langName: "Luo"
  },
  {
    langCode: "mk",
    langName: "Macedonian"
  },
  {
    langCode: "mg",
    langName: "Malagasy"
  },
  {
    langCode: "ms",
    langName: "Malay"
  },
  {
    langCode: "ml",
    langName: "Malayalam"
  },
  {
    langCode: "mt",
    langName: "Maltese"
  },
  {
    langCode: "mi",
    langName: "Maori"
  },
  {
    langCode: "mr",
    langName: "Marathi"
  },
  {
    langCode: "mfe",
    langName: "Mauritian Creole"
  },
  {
    langCode: "mo",
    langName: "Moldavian"
  },
  {
    langCode: "mn",
    langName: "Mongolian"
  },
  {
    langCode: "sr-ME",
    langName: "Montenegrin"
  },
  {
    langCode: "ne",
    langName: "Nepali"
  },
  {
    langCode: "pcm",
    langName: "Nigerian Pidgin"
  },
  {
    langCode: "nso",
    langName: "Northern Sotho"
  },
  {
    langCode: "no",
    langName: "Norwegian"
  },
  {
    langCode: "nn",
    langName: "Norwegian (Nynorsk)"
  },
  {
    langCode: "oc",
    langName: "Occitan"
  },
  {
    langCode: "or",
    langName: "Oriya"
  },
  {
    langCode: "om",
    langName: "Oromo"
  },
  {
    langCode: "ps",
    langName: "Pashto"
  },
  {
    langCode: "fa",
    langName: "Persian"
  },
  {
    langCode: "pl",
    langName: "Polish"
  },
  {
    langCode: "pt",
    langName: "Portuguese"
  },
  {
    langCode: "pt-br",
    langName: "Portuguese (Brazil)"
  },
  {
    langCode: "pt-pt",
    langName: "Portuguese (Portugal)"
  },
  {
    langCode: "pa",
    langName: "Punjabi"
  },
  {
    langCode: "qu",
    langName: "Quechua"
  },
  {
    langCode: "ro",
    langName: "Romanian"
  },
  {
    langCode: "rm",
    langName: "Romansh"
  },
  {
    langCode: "nyn",
    langName: "Runyakitara"
  },
  {
    langCode: "ru",
    langName: "Russian"
  },
  {
    langCode: "gd",
    langName: "Scots Gaelic"
  },
  {
    langCode: "sr",
    langName: "Serbian"
  },
  {
    langCode: "sh",
    langName: "Serbo-Croatian"
  },
  {
    langCode: "st",
    langName: "Sesotho"
  },
  {
    langCode: "tn",
    langName: "Setswana"
  },
  {
    langCode: "crs",
    langName: "Seychellois Creole"
  },
  {
    langCode: "sn",
    langName: "Shona"
  },
  {
    langCode: "sd",
    langName: "Sindhi"
  },
  {
    langCode: "si",
    langName: "Sinhalese"
  },
  {
    langCode: "sk",
    langName: "Slovak"
  },
  {
    langCode: "sl",
    langName: "Slovenian"
  },
  {
    langCode: "so",
    langName: "Somali"
  },
  {
    langCode: "es",
    langName: "Spanish"
  },
  {
    langCode: "es-419",
    langName: "Spanish (Latin American)"
  },
  {
    langCode: "su",
    langName: "Sundanese"
  },
  {
    langCode: "sw",
    langName: "Swahili"
  },
  {
    langCode: "sv",
    langName: "Swedish"
  },
  {
    langCode: "tg",
    langName: "Tajik"
  },
  {
    langCode: "ta",
    langName: "Tamil"
  },
  {
    langCode: "tt",
    langName: "Tatar"
  },
  {
    langCode: "te",
    langName: "Telugu"
  },
  {
    langCode: "th",
    langName: "Thai"
  },
  {
    langCode: "ti",
    langName: "Tigrinya"
  },
  {
    langCode: "to",
    langName: "Tonga"
  },
  {
    langCode: "lua",
    langName: "Tshiluba"
  },
  {
    langCode: "tum",
    langName: "Tumbuka"
  },
  {
    langCode: "tr",
    langName: "Turkish"
  },
  {
    langCode: "tk",
    langName: "Turkmen"
  },
  {
    langCode: "tw",
    langName: "Twi"
  },
  {
    langCode: "ug",
    langName: "Uighur"
  },
  {
    langCode: "uk",
    langName: "Ukrainian"
  },
  {
    langCode: "ur",
    langName: "Urdu"
  },
  {
    langCode: "uz",
    langName: "Uzbek"
  },
  {
    langCode: "vi",
    langName: "Vietnamese"
  },
  {
    langCode: "cy",
    langName: "Welsh"
  },
  {
    langCode: "wo",
    langName: "Wolof"
  },
  {
    langCode: "xh",
    langName: "Xhosa"
  },
  {
    langCode: "yi",
    langName: "Yiddish"
  },
  {
    langCode: "yo",
    langName: "Yoruba"
  },
  {
    langCode: "zu",
    langName: "Zulu"
  }
]

export const googleTabs: OptionType[] = Object.entries(EGoogleTab).map(([key, value]) => ({
  label: key.charAt(0) + key.slice(1, key.length).toLowerCase().replaceAll('_', ' '),
  value: value,
}));