import { FC } from 'react';
import ReactJson from '@textea/json-viewer';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import * as Papaparse from 'papaparse';

//** Components */
import Table from 'components/commonTable';

//** Redux layer */
import { googleResultSelector } from 'data/selectors/google';

import { IGoogleSearchResult } from 'ts/interfaces/google/google.data';

//** Styles */
import { 
    LoadingLabel, 
    CustomLoader, 
    PlaceholderTitle, 
    PlaceholderDescription,
    TableCell,
} from './styles';
import { iframeScrollStyle } from 'ts/constants/iframe.scroll.style';

const ApiResponseBox: FC<{isLoading: boolean}> = ({ isLoading }) => {
    const resultData: IGoogleSearchResult = useSelector(googleResultSelector);

    const columns = [
        {
            Header: 'Link',
            accessor: (originalRow: any) => <a href={originalRow.Link} target="_blank" rel="noreferrer"> Link </a>
        },
    ];

    const generateColumns = (fields: string[]) => {
      return fields.map((field) => {
        const customColumn = columns.find((column) => column.Header === field);
        if (customColumn) return customColumn;
        return {
          Header: field,
          accessor: (originalRow: any) => originalRow[field].length > 35 ? <Tooltip title={originalRow[field]}><TableCell>{originalRow[field]}</TableCell></Tooltip> : originalRow[field]
        }
      })
    }

    const addScrollStyle = (html: string) => {
        let doc = new DOMParser().parseFromString(html, 'text/html').documentElement;
        doc?.querySelector('head')?.insertAdjacentHTML("beforeend", iframeScrollStyle);
        return doc.innerHTML;
    };

    if (isLoading) return (
        <>
            <LoadingLabel>Fetching your data <span role="img" aria-label="rocket">🚀</span></LoadingLabel>
            <CustomLoader><div></div><div></div><div></div><div></div></CustomLoader>
        </>
    );
    if (resultData?.type === 'json') return <ReactJson src={resultData?.data} theme="paraiso" enableClipboard={false} displayDataTypes={false} collapseStringsAfterLength={100} style={{ display: 'flex', margin: 0, position: 'absolute' }}/>
    if (resultData?.type === 'html') return <iframe srcDoc={navigator.userAgent.includes('Windows') ? addScrollStyle(resultData?.data) : resultData?.data} style={{ width: '100%', height: '100%', border: 0}} title="SpaceSerp API result"/>
    if (resultData?.type === 'csv') {
      if (resultData?.data) {
        const parsedResponse = Papaparse.parse(resultData?.data, { header: true });
        const fields = parsedResponse.meta.fields;
        if (fields) return <Table columns={generateColumns(fields)} data={parsedResponse?.data} containerStyles={{ height: '100%' }} />
      } else return (
        <>
          <PlaceholderTitle>No results found</PlaceholderTitle>
          <PlaceholderDescription>Try change the request params or select another result blocks</PlaceholderDescription>
        </>
      );
    }
    
    return (
        <>
            <PlaceholderTitle> Let's start! </PlaceholderTitle>
            <PlaceholderDescription>Get started by filling search params, and <b>Send</b> request! </PlaceholderDescription>
            <img src="https://app.rocketscrape.com/static/media/rocket_placeholder.9f34389a.svg" alt="playground-placeholder"/>
        </>
    )
}

export default ApiResponseBox;