export enum EResultBlocks {
  ORGANIC_RESULTS = 'organic_results',
  ADS_RESULTS = 'ads_results',
  VIDEO_VOYAGER = 'video_voyager',
  SHOPPING_CAROUSEL = 'shopping_carousel',
  ANSWER_BOX = 'answer_box',
  TOTAL_RESULTS_COUNT = 'total_results_count',
  PEOPLE_ALSO_ASK = 'people_also_ask',
  YOUTUBE_SHORTS = 'youtube_shorts',
  IMAGE_RESULTS = 'image_results',
  PLACES_RESULTS = 'places_results',
  SHOPPING_RESULTS = 'shopping_results',
  VIDEO_RESULTS = 'video_results',
  NEWS_RESULTS = 'news_results',
  BOOKS_RESULTS = 'books_results',
  RELATED_SEARCHES = 'related_searches',
  SUPPOSED_ITEMS = 'supposed_items',
}
