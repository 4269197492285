import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'data/actions/user';
import { CustomDropdown, CaretDown, Container, MenuItem, UpgradePlanContainer, UpgradePlanLink, UserInfoContainer, UserIconContainer, Email } from './styles';
import { BiCode, BiUser, BiCalendarCheck, BiLogIn, BiLineChart, BiSupport, BiUserCircle } from 'react-icons/bi';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { userEmail, userFirstName, userLastName } from 'data/selectors/user';
import { currentSubscriptionSelector } from 'data/selectors/subscriptions';
import { creditsSelector } from 'data/selectors/credits';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';
import { ICredits } from 'ts/interfaces/credits/credits';
import { FlexColumnContainer } from 'components/common/styles';
import { startCase } from 'lodash';
import getIconLabel from 'helpers/getIconLabel';
import numberToString from 'helpers/numberToString';

const ProfileBar: FC<{ mobile?: boolean }> = ({ mobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email: string = useSelector(userEmail);
  const firstName: string = useSelector(userFirstName);
  const lastName: string = useSelector(userLastName);
  const subscription: ISubscription = useSelector(currentSubscriptionSelector)
  const credits: ICredits = useSelector(creditsSelector);

  const menu = (
    <Menu>
      <UserInfoContainer>
        <UserIconContainer>{getIconLabel(email, firstName, lastName)}</UserIconContainer>
        <Email>{email}</Email>
      </UserInfoContainer>
      <MenuItem key={'account_settings_menu'} onClick={() => navigate('/settings/account', { replace: true })}>
        <BiUser/> Account & preferences
      </MenuItem>
      
      <UpgradePlanContainer>
        <FlexColumnContainer>
          <UpgradePlanLink onClick={() => navigate('/pricing', { replace: true })}><BsFillLightningChargeFill />Upgrade plan</UpgradePlanLink>
          <div>Current plan: <b>{startCase(subscription?.plan?.name)}</b></div>
          <div>{subscription?.plan?.credits - credits?.credits}/{numberToString(subscription?.plan?.credits)} searches used</div> 
        </FlexColumnContainer>
      </UpgradePlanContainer>

      <MenuItem key={'subscription_settings_menu'} onClick={() => navigate('/settings/subscription', { replace: true })}>
        <BiCalendarCheck />Subscription
      </MenuItem>
      <MenuItem key={'api_settings_menu'} onClick={() => navigate('/settings/api', { replace: true })}>
        <BiCode/> API
      </MenuItem>
      <MenuItem key={'usage_page_menu'} onClick={() => navigate('/usage', { replace: true })}>
        <BiLineChart/> Usage
      </MenuItem>
      <MenuItem key={'support_center_menu'} onClick={() => window.open('mailto:support@spaceserp.com', '_blank')}>
        <BiSupport/> Support Center
      </MenuItem>
      <MenuItem key={'sign_out_menu'} onClick={() => dispatch(signOut())}>
        <BiLogIn/> Sign out
      </MenuItem>
    </Menu>
  );

  return (
    <Container>
      <CustomDropdown
        overlay={menu}
        trigger={['click']}
      >
        <Button style={{ padding: '4px 5px' }}>
          {mobile 
            ? <BiUserCircle size={25} color="black" />
            : (
              <>
                {firstName && lastName ? `${firstName} ${lastName}` : email} <CaretDown />
              </>
            )
          }
        </Button>
      </CustomDropdown>
    </Container>
  );
};

export default ProfileBar;
