import { FC } from 'react';
import Select from 'react-select';

//** Definitions - interface, type, const */
import { 
    daysOfWeekOptions, 
} from 'ts/constants/automation.form.options';

const DaysOfWeekSelect: FC<{ value: string[], setValue: (value: any) => void }> = ({ value, setValue }) => {
    return (
            <Select
                placeholder="Days of week..."
                className="basic-single"
                classNamePrefix="select"
                isClearable
                name="days-of-week"
                isMulti
                value={daysOfWeekOptions.filter((option) => value.includes(option.value))}
                onChange={(newValue: any) => setValue(newValue.map((item: any) => item?.value))}
                options={daysOfWeekOptions}
                menuPlacement="auto"
            />
    )
}

export default DaysOfWeekSelect;