import React, { FC } from 'react';
import { Container } from './styles';

const ContentBox: FC<any> = ({ children }) => {
    return (
        <Container>
            { children }
        </Container>
    )
}

export default ContentBox;