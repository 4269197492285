import { FC, useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';

//** Styles */
import { SectionTitle } from 'components/common/styles';
import {
    Container,
    ControlsBar,
    UsageTypeSelectContainer,
} from './styles';

//** Selectors */
import { usageAnalyticsSelector } from 'data/selectors/usage';
import { getLoadingSelector } from 'data/selectors/loading';

//** Actions */
import { getUsageAnalytics } from 'data/actions/usage';

//** Components  */
import UsageTable from 'components/usageTable';
import UsageChart from 'components/usageChart';

// Layouts
import UsageEmptyState from 'layouts/usageEmptyState';

//** Interfaces */
import { IUsageAnalyticsDocument, IUsageAnalyticsPeriod } from 'ts/interfaces/usage/usage.analytics';

//** Constants */
import { periodOptions } from 'ts/constants/analytics.period.options';

const UsagePage: FC = () => {
    const dispatch = useDispatch();
    const [period, setPeriod] = useState<IUsageAnalyticsPeriod>('month');
    const [isComponentInit, setIsComponentInit] = useState<boolean>(false);
    const usageAnalytics: IUsageAnalyticsDocument[]  = useSelector(usageAnalyticsSelector);
    const isLoading: boolean = useSelector(getLoadingSelector('usage_analytics'));
    const showEmptyStatePlaceholder = isComponentInit && !isLoading && !usageAnalytics?.length && period === 'month';

    useEffect(() => {
        dispatch(getUsageAnalytics(period));
        setTimeout(() => setIsComponentInit(true), 1);
    }, [period, dispatch]);

    if (!isComponentInit || isLoading && !usageAnalytics?.length) return <></>
    if (showEmptyStatePlaceholder) return <UsageEmptyState />
    return (
        <Container>
            <ControlsBar>
            <SectionTitle>Usage</SectionTitle>
            
            <UsageTypeSelectContainer>
                <Select
                    placeholder="Analytics period"
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={periodOptions.find(item => item.value === period)}
                    isDisabled={false}
                    name="period"
                    onChange={(newValue: any) => setPeriod(newValue.value)}
                    options={periodOptions}
                />
            </UsageTypeSelectContainer>
            
            </ControlsBar>
            <UsageChart period={period} usageAnalytics={usageAnalytics} />
            <UsageTable period={period} />       
        </Container>
    )
}   

export default UsagePage;