import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SectionTitle } from 'components/common/styles';
import { BiCode, BiUser, BiLockAlt, BiCalendarCheck, BiCreditCard} from 'react-icons/bi';

import { SettingsContainer,
    SidebarContainer,
    SettingsPageContent,
    MenuItem,
    ContentContainer,
    SettingsPageContainer
} from './styles';

const SettingsPage: FC<any> = ({ children }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    return (
        <SettingsPageContainer>
            <SectionTitle>Settings</SectionTitle>
            <ContentContainer>
            <SettingsContainer>
                <SidebarContainer>
                    <MenuItem active={pathname.includes('account')} onClick={() => navigate('/settings/account', { replace: true })}><BiUser size={20}/>Account</MenuItem>
                    <MenuItem active={pathname.includes('password')} onClick={() => navigate('/settings/password', { replace: true })}><BiLockAlt size={20}/>Password</MenuItem>
                    <MenuItem active={pathname.includes('subscription')} onClick={() => navigate('/settings/subscription', { replace: true })}><BiCalendarCheck size={20}/>Subscription</MenuItem>
                    <MenuItem active={pathname.includes('billing')} onClick={() => navigate('/settings/billing', { replace: true })}><BiCreditCard size={20}/>Billing</MenuItem>
                    <MenuItem active={pathname.includes('api')} onClick={() => navigate('/settings/api', { replace: true })}><BiCode size={20}/>API</MenuItem>
                </SidebarContainer>
                <SettingsPageContent>{children}</SettingsPageContent>
            </SettingsContainer>
            </ContentContainer>
        </SettingsPageContainer>
    )
}

export default SettingsPage;
