import { combineReducers, Reducer } from 'redux';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import loading from './loading';
import user from './user';
import credits from './credits';
import usage from './usage';
import modals from './modals';
import google from './google';
import subscriptions from './subscriptions';
import automation from './automation';
import drawer from './drawer';
import application from './application';
import order from './order';
import savedSearch from './savedSearch'

const combinedReducer = combineReducers({
  loading,
  user,
  credits,
  usage,
  modals,
  google,
  subscriptions,
  automation,
  drawer,
  application,
  order,
  savedSearch
});

const rootReducer: Reducer = (state: any, action: IReduxAction) => {
  return combinedReducer(state, action);
};

export default rootReducer;