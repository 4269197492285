import { ICredits } from 'ts/interfaces/credits/credits'
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: ICredits | {} = {};

const creditsReducer = (state = initialState, action: IReduxAction): ICredits | {} => {
  switch (action.type) {
    case EReduxTypes.GET_USER_CREDITS: {
      return {
          credits: action?.payload?.credits || 0,
          lastRefreshedAt: action?.payload?.lastRefreshedAt
      };
    }
    case EReduxTypes.CREDITS_UPDATE: {
      return {
          credits: action?.payload?.credits || 0,
          lastRefreshedAt: action?.payload?.lastRefreshedAt
      };
    }
    default:
      return state;
  }
};

export default creditsReducer;