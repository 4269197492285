import { toast } from "react-toastify";
import { AnyAction } from "redux";
import { EReduxTypes } from "ts/enums/redux.types";
import { IPagination } from "ts/interfaces/common/pagination";
import { IReduxAction } from "ts/interfaces/redux/redux.action";
import { IPaginatedSavedSearch, ISavedSearch } from "ts/interfaces/saved-search/saved-search.data";

export const getSavedSearches = (place: 'table' | 'select', query: string, pagination?: IPagination, idFilter?: string[], onSuccess?: (response: IPaginatedSavedSearch) => void, onFailed?: (err: any) => void): AnyAction => {
  let endpoint = `saved-search?q=${query}`;
  if (pagination) endpoint += `&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`;
  if (idFilter) endpoint += idFilter.map((id) => `&ids[]=${id}`).join('');
  const action: IReduxAction = {
    type: EReduxTypes.GET_SAVED_SEARCHES,
    payload: {
      method: 'GET',
      endpoint,
    },
    onSuccess,
    onFailed,
    forwardedData: { place, pageNumber: pagination?.pageNumber, query, idFilter },
    components: ['saved_searches_list']
  }
  return action;
};

export const setEditableSearch = (search: ISavedSearch | null): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_EDITABLE_SEARCH,
    data: { search },
  }
  return action;
}

export const updateSavedSearch = (search: ISavedSearch): AnyAction => {
  const { user, ...dto } = search;
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_SAVED_SEARCH,
    payload: {
      method: 'PATCH',
      endpoint: `saved-search/${search._id}`,
      body: dto
    },
    onSuccess: () => {
      toast.success('Search successfully updated!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    },
    components: ['save_search']
  }
  return action;
};

export const deleteSavedSearch = (searchId: string, onSuccess?: (options: any) => void): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_SAVED_SEARCH,
    payload: {
      method: 'DELETE',
      endpoint:  `saved-search/${searchId}`,
    },
    onSuccess: () => {
      toast.success('Search successfully deleted!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    },
    forwardedData: { searchId },
  }
  return action;
}