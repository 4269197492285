import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getLoadingSelector } from 'data/selectors/loading';
import AuthLayout from 'layouts/auth/authLayout';
import PasswordInput from 'components/passwordInput';
import Button from 'components/button';
import { passwordReset } from 'data/actions/user';
import { Title, ErrorMessage } from './styles';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useSelector(getLoadingSelector('password_reset'));

  const { token }: any = useParams();
  const email = new URLSearchParams(location?.search).get('email');

  const comparePasswords = () => {
    if (repeatedPassword && password !== repeatedPassword) {
      setError('Passwords are different');
      return;
    }
    setError('');
  };

  const handleReset = (e: any) => {
    e.preventDefault();
    if (email) {
      dispatch(passwordReset(email, password, token, () => navigate('/sign-in', { replace: true }), setError));
    }
  };

  return (
    <AuthLayout>
      <Title>Choose new password</Title>
      { error && <ErrorMessage>{error}</ErrorMessage> }
      <PasswordInput onBlur={comparePasswords} value={password} onChange={(e: any) => setPassword(e?.target?.value)} placeholder="Choose new password" />
      <PasswordInput onBlur={comparePasswords} value={repeatedPassword} onChange={(e: any) => setRepeatedPassword(e?.target?.value)} placeholder="Repeat password" />
      <Button loading={loading} disabled={!password || !repeatedPassword} onClick={handleReset} style={{marginTop: 25}}>Update password</Button>
    </AuthLayout>
  );
};

export default ResetPassword;
