export const iframeScrollStyle = `
  <style>
    body::-webkit-scrollbar {
      width: 18px;
      height: 18px;
    }
    
    body::-webkit-scrollbar-thumb {
      border: 5px solid #fff;
      background-color: #babac0;
      border-radius: 16px;
    }
    
    body::-webkit-scrollbar-track {
      background-color: #fff;
    }
    
    body::-webkit-scrollbar-corner {
      width: 0px;
      height: 0px;
      background-color: #fff;
    }
    
    body::-webkit-scrollbar-thumb:hover {
      background-color: #76767e;
    }
    
    body::-webkit-scrollbar-button {
      display:none;
    }
  </style>
`
