import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useQueryError = () => {
    const { search } = useLocation();
    return useMemo(() => {
        const parsedLocation = queryString.parse(search);
        return parsedLocation?.error as string;
    }, [search]);
};

export default useQueryError;