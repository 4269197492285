import { createSelector } from 'reselect';

const baseOrderSelector = (state: any) => state?.order;

export const orderDataSelector = createSelector(
    baseOrderSelector,
    (order) => order?.data || [],
);

export const orderMetaSelector = createSelector(
    baseOrderSelector,
    (order) => order?.meta || {},
  );
  
