import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { BsCheck } from 'react-icons/bs';

//** Definitions - interface, type, const */
import { IGoogleSearchRequest } from 'ts/interfaces/google/google.search.request';

//** Components */
import Button from 'components/button';

//** Styles */
import { CodeBox, Code, Container } from './styles';
import { FlexRowContainer } from 'components/common/styles';

//** Redux layer */
import { googleRequestSelector } from 'data/selectors/google';
import { sendGoogleSearchRequest } from 'data/actions/google';
import { applicationDataSelector } from 'data/selectors/application';
import DownloadResultButton from 'components/downloadResultButton';

const ExampleUrlBox: FC<{ disabled: boolean }> = ({ disabled }) => {
    const dispatch = useDispatch();
    const applications = useSelector(applicationDataSelector);
    const request: IGoogleSearchRequest = useSelector(googleRequestSelector);
    const [isCopied, setIsCopied] = useState(false);
    
    const apiKey = applications?.[0]?.key;
    const query = new URLSearchParams();
    query.append('apiKey', apiKey);

    if (request) {
        (Object.keys(request) as Array<keyof IGoogleSearchRequest>).forEach((key: keyof IGoogleSearchRequest) => {
            const value = request[key];
            if (
                (!Array.isArray(value) && value) || 
                (Array.isArray(value) && value.length)
            ) query.append(key, value as string);
        })
    }
    
    const requestUrl = `${process.env.REACT_APP_API_URL}/google/search?${query.toString()}`;
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(requestUrl);
        setIsCopied(true);
    }
        
    return (
        <Container>
            <Tooltip 
                title={!isCopied ? 'Click to copy' : <FlexRowContainer><BsCheck size={22} color='#24a522' /> Copied </FlexRowContainer>}
                onVisibleChange={(value) => value && setIsCopied(false)}
            >
                <CodeBox onClick={copyToClipboard}>
                    <Code>{requestUrl}</Code>
                </CodeBox>
            </Tooltip>
            <Button style={{ width: 200, marginLeft: 15, height: 38 }} onClick={() => dispatch(sendGoogleSearchRequest(request))} disabled={disabled || !apiKey}>Send</Button>
            <DownloadResultButton />
         </Container>
    )
}

export default ExampleUrlBox;