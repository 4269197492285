import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';
import { ButtonHeight } from 'ts/enums/styles';
import { Container, Title, ButtonContainer, Description }from './styles';
import { BsCode } from 'react-icons/bs';

const UsageEmptyState: FC = () => {
    const navigate = useNavigate();
    return (
        <Container>
                <Title>Usage analytics will be here!</Title>
                <Description>Not sent requests yet.</Description>
                <Description>Build your first request using <b>SpaceSerp</b> Playground!</Description>
                <ButtonContainer>
                    <Button size={ButtonHeight.MEDIUM} onClick={() => navigate('/playground', { replace: true })}><BsCode size={20} style={{ marginRight: 5 }}/> Build request</Button>
                </ButtonContainer>
        </Container>  
    )
}

export default UsageEmptyState;