import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { upperFirst } from 'lodash';
import { Badge, DatePicker, Tooltip } from 'antd';
import moment from 'moment-timezone';

//** Components  */
import LocationSelect from 'components/locationSelect';

//** Definitions - interface, type, const */
import { IGoogleLocation, IGoogleDomain, IGoogleCountry, IGoogleLanguage } from 'ts/interfaces/google/google.data';
import { resultFormatOptions, deviceTypeOptions, mobileOsOptions, searchOutputFormatOptions, periodOptions, googleTabsParams, searchBaseResults } from 'ts/constants/search.form.options';
import { ResultFormat } from 'ts/interfaces/google/google.search.request';
import { ISavedSearch } from 'ts/interfaces/saved-search/saved-search.data';
import { EGoogleTab } from 'ts/enums/google.tabs';
import OptionType from 'ts/types/option';

//** Others */
import { googleDomains, googleCountries, googleLanguages, googleTabs } from 'helpers/google.data';

//** Redux layer */
import { getLoadingSelector } from 'data/selectors/loading';
import { setGoogleSearchRequest } from 'data/actions/google';
import { editableSearchSelector } from 'data/selectors/google';
import { setEditableSearch } from 'data/actions/savedSearch';

//** Styles */
import { FormItem, FlexRow, Description, Input } from 'components/common/styles';
import { BiInfoCircle } from 'react-icons/bi';

const { RangePicker } = DatePicker;

const SearchForm: FC<{ setFormFieldsValid: Dispatch<SetStateAction<boolean>>, createMode?: boolean }> = ({ setFormFieldsValid, createMode }) => {
    const dispatch = useDispatch();
    const isLoading: boolean = useSelector(getLoadingSelector('api_playground'));
    const editableSearch: ISavedSearch | null = useSelector(editableSearchSelector);

    const [name, setName] = useState<string>();
    const [q, setQuery] = useState<string>('');
    const [location, setLocation] = useState<IGoogleLocation>();
    const [device, setDevice] = useState<SingleValue<string>>();
    const [os, setOs] = useState<SingleValue<string>>();
    const [resultFormat, setResultFormat] = useState<SingleValue<string>>();
    const [pageSize, setPageSize] = useState<number>();
    const [pageNumber, setPageNumber] = useState<string>();
    const [googleDomain, setGoogleDomain] = useState<SingleValue<IGoogleDomain>>();
    const [googleCountry, setGoogleCountry] = useState<SingleValue<IGoogleCountry>>();
    const [googleLanguage, setGoogleLanguage] = useState<SingleValue<IGoogleLanguage>>();
    const [googleTab, setGoogleTab] = useState<EGoogleTab>(EGoogleTab.DEFAULT);
    const [resultBlocks, setResultBlocks] = useState<MultiValue<string>>();
    const [tabBlocks, setTabBlocks] = useState<OptionType[]>([]);
    const [blockedSize, setBlockedSize] = useState<number | undefined>();
    const [blockedPage, setBlockedPage] = useState<number | undefined>();
    const [availableFields, setAvailableFields] = useState<string[] | undefined>();
    const [requiredFields, setRequiredFields] = useState<string[] | undefined>();
    const [availableFormats, setAvailableFormats] = useState<string[] | undefined>();
    const [minSize, setMinSize] = useState<string | undefined>();
    const [period, setPeriod] = useState<SingleValue<string>>();
    const [periodRange, setPeriodRange] = useState<Record<string, string | undefined>>({ startDate: undefined, endDate: undefined });
    const [updatesChecked, setUpdatesChecked] = useState(false);

    useEffect(() => {
      if (window.localStorage.getItem('updatesChecked')) setUpdatesChecked(true);
    }, []);

    useEffect(() => {
      if (requiredFields?.length) {
        const validity = [];
        if (requiredFields.includes('country')) validity.push(!!googleCountry);
        if (requiredFields.includes('language')) validity.push(!!googleLanguage);
        setFormFieldsValid(!validity.filter((value) => !value).length);
      } else setFormFieldsValid(true);
    }, [q, googleCountry, googleLanguage, requiredFields]);

    useEffect(() => {
      if (name && !editableSearch) {
        setName(undefined);
        setQuery('')
        setLocation(undefined);
        setDevice(undefined);
        setOs(undefined);
        setResultFormat(undefined);
        setPageSize(undefined);
        setPageNumber(undefined);
        setGoogleDomain(undefined);
        setGoogleCountry(undefined);
        setGoogleLanguage(undefined);
        setResultBlocks(undefined);
        setPeriod(undefined);
        setPeriodRange({ startDate: undefined, endDate: undefined });
      }
    }, [editableSearch])

    useEffect(() => {
      if (editableSearch) {
        setName(editableSearch.name);
        setQuery(editableSearch.q);
        setResultFormat(editableSearch.resultFormat);
        if (editableSearch?.device) setDevice(editableSearch.device);
        if (editableSearch?.mobileOs) setOs(editableSearch.mobileOs);
        if (editableSearch?.pageSize) setPageSize(editableSearch.pageSize);
        if (editableSearch?.pageNumber) setPageNumber(editableSearch.pageNumber);
        if (editableSearch?.domain) setGoogleDomain(googleDomains.find((domain) => domain.domain === editableSearch.domain));
        if (editableSearch?.gl) setGoogleCountry(googleCountries.find((country) => country.countryCode === editableSearch.gl));
        if (editableSearch?.hl) setGoogleLanguage(googleLanguages.find((language) => language.langCode === editableSearch.hl));
        if (editableSearch?.tbm) setGoogleTab(editableSearch?.tbm);
        if (editableSearch?.resultBlocks?.length) setResultBlocks(editableSearch.resultBlocks);
        if (editableSearch?.period) {
          if (editableSearch?.period?.startsWith('cdr')) {
            const dates = editableSearch.period.match(/\d*\/\d*\/\d*/g);
            setPeriod('range');
            setPeriodRange({ startDate: dates?.[0] as string, endDate: dates?.[1] as string })
          } else setPeriod(editableSearch.period);
        }
      }
    }, []);

    useEffect(() => {
      if (editableSearch?.name) setName(editableSearch.name)
    }, [editableSearch]);

    useEffect(() => {
        if (location) {
            const { countryCode } = location;            
            const domain = googleDomains.find(domain => domain.countryCode === countryCode.toLocaleLowerCase());
            const language = googleLanguages.find(language => language.langCode === domain?.langCode.toLocaleLowerCase());
            const country = googleCountries.find(country => country.countryCode === countryCode.toLocaleLowerCase());

            if (country) setGoogleCountry(country);
            if (domain) setGoogleDomain(domain);
            if (language) setGoogleLanguage(language);
        }
    }, [location]);

    useEffect(() => {
      const currentDevice = (device && ['mobile', 'tablet'].includes(device)) ? 'mobile' : 'desktop';
      const tabParams = googleTabsParams[googleTab];
      const tabBaseResults = searchBaseResults[googleTab];
      const tabBlocks = [tabBaseResults, ...tabParams[currentDevice]];

      setTabBlocks(tabBlocks.map((blockName) => ({ value: blockName, label: upperFirst(blockName).replace('_', ' ') })));
      setBlockedSize(tabParams?.pageSize);
      setBlockedPage(tabParams?.pageNumber);
      setMinSize(tabParams?.minSize);
      setAvailableFields(tabParams?.availableFields);
      setRequiredFields(tabParams?.requiredFields);
      setAvailableFormats(tabParams?.availableFormats);
    }, [googleTab, device]);

    useEffect(() => {
      if (!editableSearch || !(editableSearch?.tbm === undefined && googleTab === EGoogleTab.DEFAULT) || (!!editableSearch?.tbm && (editableSearch.tbm !== googleTab))) setResultBlocks([]);
    }, [tabBlocks]);

    useEffect(() => {
        const newSearchData = {
          name,
          q,
          location: location?.canonicalName,
          domain: googleDomain?.domain,
          gl: googleCountry?.countryCode,
          hl: googleLanguage?.langCode,
          resultFormat: resultFormat as ResultFormat,
          device: device as string,
          mobileOs: os as string,
          pageSize,
          pageNumber,
          resultBlocks,
          tbm: googleTab === EGoogleTab.DEFAULT ? undefined : googleTab,
          period: period === 'range' ? `cdr:1,cd_min:${periodRange?.startDate},cd_max:${periodRange?.endDate}` : period as string
        }
        if (editableSearch && name !== undefined) dispatch(setEditableSearch({
            ...editableSearch,
            ...newSearchData,
            name: name || '',
        }));
        dispatch(setGoogleSearchRequest(newSearchData))
    }, [q, location, device, os, resultFormat, pageSize, pageNumber, googleDomain, googleCountry, googleLanguage, resultBlocks, period, periodRange, googleTab, name]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexBasis: '100%' }}>
                        { (editableSearch || createMode) && (
                          <FormItem>
                            <Description>Name:</Description>
                            <Input placeholder="Search name..." name="search-name" value={name} onChange={(e) => setName(e?.target?.value)}/>
                          </FormItem>
                        )}
                        <FormItem>
                            <Description>Query which you want to search:</Description>
                            <Input placeholder="e.g.:  iPhone 13" name="query" disabled={isLoading} value={q} onChange={(e) => setQuery(e?.target?.value)}/>
                        </FormItem>

                        <FormItem>
                            {!updatesChecked && <Badge.Ribbon text="New option" color="rgb(87,102,236)" />}
                            <Description>Google Tab:</Description>
                            <Select
                                placeholder="Choose search tab from the list..."
                                isDisabled={isLoading}
                                isSearchable
                                name="g-tab"
                                value={googleTabs.find((option: OptionType) => option.value === googleTab)}
                                onChange={(newValue) => setGoogleTab(newValue?.value as EGoogleTab)}
                                options={googleTabs}
                                menuPlacement="auto"
                                formatOptionLabel={(option: OptionType) => (
                                  <>
                                    {option.label}
                                    {(option.value === 'autocomplete' && !updatesChecked) && <span style={{ color: 'white', borderRadius: 4, textAlign: 'center', padding: '2px 4px', background: 'rgb(87,102,236)', fontSize: 8, marginLeft: 5, fontWeight: 600 }}>NEW</span>}
                                  </>
                                )}
                                onMenuOpen={() => {
                                  console.log('opened')
                                  if (!updatesChecked) window.localStorage.setItem('updatesChecked', '1')
                                }}
                            />
                        </FormItem>

                        <FormItem>
                            <Description>Location:</Description>
                            <LocationSelect isLoading={isLoading} setLocation={setLocation} />
                        </FormItem>

                        <FormItem>
                            <Description>Google Domain:</Description>
                            <Select
                                placeholder="Choose Google domain from the list..."
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={isLoading}
                                isClearable
                                isSearchable
                                name="g-domain"
                                onChange={(newValue: SingleValue<IGoogleDomain>) => setGoogleDomain(newValue) }
                                options={googleDomains}
                                value={googleDomain}
                                getOptionLabel={(option: IGoogleDomain) => `${option.domain} - ${option.countryName}`}
                                getOptionValue={(option: IGoogleDomain) => option.domain}
                                menuPlacement="auto"
                            />
                        </FormItem>

                        <FormItem>
                            <Description>Google Country:{requiredFields?.length && requiredFields.includes('country') ? <span style={{ color: 'rgb(87,102,236)', fontWeight: 600 }} >*</span> : ''}</Description>
                            <Select
                                placeholder="Choose country code from the list..."
                                isDisabled={isLoading}
                                isClearable
                                isSearchable
                                name="g-country"
                                value={googleCountry}
                                onChange={(newValue: SingleValue<IGoogleCountry>) => {
                                  setGoogleCountry(newValue);
                                  if (!googleLanguage) setGoogleLanguage(googleLanguages.find((lang) => lang.langCode === googleDomains.find((domain) => domain.countryCode === newValue?.countryCode)?.langCode));
                                }}
                                options={googleCountries}
                                getOptionLabel={(option: IGoogleCountry) => option.countryName}
                                getOptionValue={(option: IGoogleCountry) => option.countryCode}
                                menuPlacement="auto"
                            />
                        </FormItem>

                        <FormItem>
                            <Description>Language:{requiredFields?.length && requiredFields.includes('language') ? <span style={{ color: 'rgb(87,102,236)', fontWeight: 600 }} >*</span> : ''}</Description>
                            <Select
                                placeholder="Choose Google UI Language..."
                                isDisabled={isLoading}
                                isClearable
                                isSearchable
                                name="g-language"
                                value={googleLanguage}
                                onChange={(newValue: SingleValue<IGoogleLanguage>) => setGoogleLanguage(newValue) }
                                options={googleLanguages}
                                getOptionLabel={(option: IGoogleLanguage) => option.langName}
                                getOptionValue={(option: IGoogleLanguage) => option.langCode}
                                menuPlacement="auto"
                            />
                        </FormItem>

                        {(!availableFields?.length || availableFields.includes('period')) && (
                          <>
                            <FormItem>
                              <Description>Period:</Description>
                              <Select
                                  placeholder="Choose results period..."
                                  isDisabled={isLoading}
                                  isClearable
                                  isSearchable
                                  name="g-period"
                                  value={periodOptions.find((option) => option.value === period)}
                                  onChange={(newValue) => setPeriod(newValue?.value) }
                                  options={periodOptions}
                                  menuPlacement="auto"
                              />
                            </FormItem>

                            {period === 'range' && (
                              <FormItem>
                                <RangePicker
                                  defaultValue={[periodRange.startDate ? moment(periodRange.startDate, 'M/D/YYYY') : null, periodRange.startDate ? moment(periodRange.endDate, 'M/D/YYYY') : null]}
                                  format={'M/D/YYYY'}
                                  onCalendarChange={(values) => setPeriodRange({ startDate: values?.[0]?.format('M/D/YYYY') || '', endDate: values?.[1]?.format('M/D/YYYY') || '' })}
                                  disabledDate={(current) => current && current > moment().endOf('day')}
                                />
                              </FormItem>
                            )}
                          </>
                        )}
                        

                        <FormItem>
                          <Description>Blocks which you want to receive:</Description>
                          <Select
                            placeholder="Leave empty for all"
                            className="basic-single"
                            classNamePrefix="select"
                            isMulti={true}
                            isDisabled={isLoading}
                            isClearable
                            name="result-blocks"
                            value={tabBlocks.filter((option: OptionType) => resultBlocks?.includes(option.value))}
                            onChange={(newValue) => setResultBlocks(newValue.map((item) => item.value))}
                            options={tabBlocks}
                            menuPlacement="auto"
                          />
                        </FormItem>

                        <FormItem>
                                <Description>Result format:</Description>
                                <Select
                                    placeholder="Result format..."
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={isLoading}
                                    isClearable
                                    name="result-format"
                                    value={resultFormatOptions.find((option) => option.value === resultFormat)}
                                    onChange={(newValue) => setResultFormat(newValue?.value)}
                                    options={(editableSearch || (!!pageNumber && new RegExp(/\D/g).test(pageNumber)) ? searchOutputFormatOptions : resultFormatOptions).filter((option) => availableFormats ? availableFormats.includes(option.value) : true)}
                                    menuPlacement="auto"
                                />
                        </FormItem>

                        <FlexRow>
                            <FormItem style={{ minWidth: 150 }}>
                                <Description>Device:</Description>
                                <Select
                                    placeholder="Desktop / Mobile"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={isLoading}
                                    isClearable
                                    name="device-type"
                                    value={deviceTypeOptions.find((option) => option.value === device)}
                                    onChange={(newValue: any) => setDevice(newValue?.value)}
                                    options={deviceTypeOptions}
                                    menuPlacement="top"
                                />
                            </FormItem>

                            <FormItem>
                                    <Description>Mobile OS:</Description>
                                    <Select
                                        placeholder="iOS, Android"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isDisabled={(isLoading || !device || !['mobile', 'tablet'].includes(device))}
                                        isClearable
                                        name="device-os"
                                        value={mobileOsOptions.find((option) => option.value === os)}
                                        onChange={(newValue) => setOs(newValue?.value)}
                                        options={mobileOsOptions}
                                        menuPlacement="auto"
                                    />
                            </FormItem>
                        </FlexRow>

                        <FlexRow>
                            <FormItem style={{ minWidth: 150 }}>
                                <Description>Page size:</Description>
                                <Input placeholder="From 1 to 100" name="page-size" type="number" min={minSize || '1'} max="100" disabled={!!blockedSize || isLoading} value={blockedSize || pageSize} onChange={(e) => setPageSize(parseInt(e?.target?.value, 10))}/>
                            </FormItem>                

                            <FormItem>
                                <Description>
                                  Page number:
                                  <Tooltip title={
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                      <span style={{ textAlign: 'center', marginBottom: 10 }}><b>Valid formats</b></span>
                                      <span><b>Single page</b>: 1</span>
                                      <span><b>Selection</b>: 1,3,4</span>
                                      <span><b>Range</b>: 1-5</span>
                                    </div>
                                  }>
                                    <BiInfoCircle style={{ marginLeft: 'auto' }} size={14} />
                                  </Tooltip>
                                </Description>
                                <Input
                                  placeholder="Page number"
                                  name="page-number"
                                  type="text"
                                  disabled={!!blockedPage || isLoading}
                                  value={blockedPage || pageNumber}
                                  onChange={(e) => setPageNumber((prev) => {
                                    const regExp = new RegExp(/^\d*$|^\d*(?:\,{1}\d*)*$|^\d*\-\d*$/);
                                    if (!e?.target?.value) return undefined;
                                    if (e?.target?.value && regExp.test(e.target.value)) return e?.target?.value.replace(/\,{2,}/g, ',')
                                    return prev;
                                  })}
                                />
                            </FormItem>   
                        </FlexRow>
        </div>
    )
}

export default SearchForm;
