import styled from "styled-components";
import Button from 'components/button';
import {
  Container,
} from '../baseModal/styles';

export const ModalContainer = styled(Container)`
  margin: 0;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const AcceptButton = styled(Button)`
  max-width: 200px;
  margin-right: 10px;
  padding: 0 20px;
  height: 40px;
  border-radius: 50px;
`;

export const DeclineButton = styled(Button)`
  max-width: 100px;
  padding: 0 20px;
  height: 40px;
  border-radius: 50px;
`;

export const DescriptionContainer = styled.div`
  padding: 25px 5px;
`;