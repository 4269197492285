import styled from 'styled-components';

export const TableCell = styled.div`
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StatusRawContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
`;

export const AutomationHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        width: 200px;
    }

    @media (max-width: 1249px) {
        flex-direction: column;
        gap: 30px;

        button {
            width: 100%;
        }
    }
`;
