import { FC } from 'react';
import { TableProps } from 'ts/types/table.props';
import { useTable } from 'react-table';
import { TableContainer } from './styles';
import TableSearchEmptyState from './emptyState';

const Table: FC<TableProps> = ({ columns, data, containerStyles, showSearchEmptyState }) => {
    const {
      getTableProps,
      getTableBodyProps,
      rows,
      headers,
      prepareRow,
    } = useTable({
      columns,
      data,
    });
      
    return (
      <>
      
      <TableContainer style={containerStyles}>
      {!rows?.length && showSearchEmptyState ? <TableSearchEmptyState /> : (
        <table {...getTableProps()}>
          <thead>
            <tr>{headers.map(header => <th {...header.getHeaderProps()}>{header.render('Header')}</th>)}</tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
              })}
          </tbody>
        </table>
      )}
      </TableContainer>
      </>
    )
}

export default Table;