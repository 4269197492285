import { EGoogleTab } from 'ts/enums/google.tabs';
import { EResultBlocks } from 'ts/enums/result.blocks';
import OptionType from 'ts/types/option';

export const deviceTypeOptions: OptionType[] = [
    { value: "desktop", label: "Desktop" },
    { value: "mobile", label: "Mobile" },
    { value: "tablet", label: "Tablet" }
];

export const mobileOsOptions: OptionType[] = [
    { value: "ios", label: "iOS" },
    { value: "android", label: "Android" },
];

export const resultFormatOptions: OptionType[] = [
    { value: "json", label: "JSON" },
    { value: "csv", label: "CSV" },
    { value: "html", label: "HTML" },
];

export const searchOutputFormatOptions: OptionType[] = [
    { value: "json", label: "JSON" },
    { value: "csv", label: "CSV" },
];

interface IGoogleTabsParams { 
  desktop: string[],
  mobile: string[],
  pageSize?: number,
  minSize?: string,
  pageNumber?: number,
  requiredFields?: string[],
  availableFields?: string[],
  availableFormats?: string[],
}

export const googleTabsParams: Record<EGoogleTab, IGoogleTabsParams> = {
  [EGoogleTab.DEFAULT]: {
    desktop: [
      EResultBlocks.ADS_RESULTS,
      EResultBlocks.ANSWER_BOX,
      EResultBlocks.PEOPLE_ALSO_ASK,
      EResultBlocks.SHOPPING_CAROUSEL,
      EResultBlocks.TOTAL_RESULTS_COUNT,
      EResultBlocks.VIDEO_VOYAGER,
      EResultBlocks.RELATED_SEARCHES,
    ],
    mobile: [
      EResultBlocks.ADS_RESULTS,
      EResultBlocks.SHOPPING_CAROUSEL,
      EResultBlocks.PEOPLE_ALSO_ASK,
      EResultBlocks.RELATED_SEARCHES,
    ],
  },
  [EGoogleTab.PICTURES]: {
    desktop: [],
    mobile: [],
    pageSize: 100,
    pageNumber: 1,
  },
  [EGoogleTab.PLACES]: {
    desktop: [],
    mobile: [],
    pageSize: 20,
  },
  [EGoogleTab.SHOP]: {
    desktop: [
      EResultBlocks.ADS_RESULTS,
    ],
    mobile: [],
    minSize: '10',
  },
  [EGoogleTab.VIDEOS]: {
    desktop: [],
    mobile: [
      EResultBlocks.VIDEO_VOYAGER,
      EResultBlocks.YOUTUBE_SHORTS,
    ],
  },
  [EGoogleTab.NEWS]: {
    desktop: [],
    mobile: [],
  },
  [EGoogleTab.BOOKS]: {
    desktop: [],
    mobile: [],
  },
  [EGoogleTab.AUTOCOMPLETE]: {
    desktop: [EResultBlocks.SUPPOSED_ITEMS],
    mobile: [EResultBlocks.SUPPOSED_ITEMS],
    requiredFields: [
      'country',
      'language'
    ],
    availableFields: [
      'location',
    ],
    availableFormats: [
      'json',
      'csv',
    ],
    pageSize: 10,
    pageNumber: 1,
  },
};

export const searchBaseResults: Record<EGoogleTab, EResultBlocks> = {
  [EGoogleTab.DEFAULT]: EResultBlocks.ORGANIC_RESULTS,
  [EGoogleTab.PICTURES]: EResultBlocks.IMAGE_RESULTS,
  [EGoogleTab.PLACES]: EResultBlocks.PLACES_RESULTS,
  [EGoogleTab.SHOP]: EResultBlocks.SHOPPING_RESULTS,
  [EGoogleTab.VIDEOS]: EResultBlocks.VIDEO_RESULTS,
  [EGoogleTab.NEWS]: EResultBlocks.NEWS_RESULTS,
  [EGoogleTab.BOOKS]: EResultBlocks.BOOKS_RESULTS,
  [EGoogleTab.AUTOCOMPLETE]: EResultBlocks.ORGANIC_RESULTS,
};

export const periodOptions: OptionType[] = [
  { value: "", label: "All time" },
  { value: "qdr:h", label: "Hour" },
  { value: "qdr:d", label: "Day" },
  { value: "qdr:w", label: "Week" },
  { value: "qdr:m", label: "Month" },
  { value: "qdr:y", label: "Year" },
  { value: "range", label: "Range" }
];
