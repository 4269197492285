import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import useFingerPrint from 'hooks/useFingerPrint';

import { googleAuth } from 'data/actions/user';

import FullScreenLoader from 'components/fullScreenLoader';

const GoogleVerifyPage: FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const fingerPrint = useFingerPrint();
    const navigate = useNavigate();

    useEffect(() => {
        const parsedLocation = queryString.parse(location.hash);
        if (parsedLocation?.id_token && fingerPrint) {
            const token = parsedLocation.id_token as string;
            dispatch(googleAuth(token, fingerPrint, (err: string)=>{ navigate(`/sign-in?error=${err}`) }))
        }
        
    }, [location, fingerPrint, dispatch]);
    return (
        <FullScreenLoader />
    )
}

export default GoogleVerifyPage;