import { IGoogleData } from 'ts/interfaces/google/google.data'
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: IGoogleData | {} = {};

const googleReducer = (state = initialState, action: IReduxAction): IGoogleData | {} => {
  switch (action.type) {
    case EReduxTypes.GET_GOOGLE_LOCATIONS: {
      return {
        ...state,
        location: action?.payload || [],
      };
    }
    case EReduxTypes.SEND_GOOGLE_SEARCH_REQUEST: {
      const header = action?.headers?.['content-type'];
      const type = ['json', 'csv', 'html'].find(item => header.includes(item)) || 'csv';

      return {
        ...state,
        searchResult: {
          type,
          data: action.payload || null,
        }
      };
    }
    case EReduxTypes.SET_GOOGLE_SEARCH_REQUEST: {
      return {
        ...state,
        request: action?.data || {}
      };
    }
    case EReduxTypes.SET_EDITABLE_SEARCH: {
      return {
        ...state,
        editableSearch: action?.data?.search,
      }
    }
    default:
      return state;
  }
};

export default googleReducer;