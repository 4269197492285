import { ISavedSearch } from 'ts/interfaces/saved-search/saved-search.data';
import EllipsisTooltip from 'components/tooltipTableCell';
import { ActionItem, TableCell } from '../styles';
import { DateTime } from 'luxon';
import { Popconfirm } from 'antd';
import { BiEdit, BiTrash } from 'react-icons/bi';

const generateSearchColumns = (deleteSearch: (search: string) => void, editSearch: (search: ISavedSearch) => void) => (
  [
    {
      Header: 'Name',
      accessor: (originalRow: ISavedSearch) => <TableCell style={{ width: '200px' }}><EllipsisTooltip>{originalRow.name}</EllipsisTooltip></TableCell>
    },
    {
      Header: 'Question',
      accessor: (originalRow: ISavedSearch) => <TableCell><EllipsisTooltip>{originalRow.q}</EllipsisTooltip></TableCell>
    },
    {
      Header: 'Result format',
      accessor: (originalRow: ISavedSearch) => <TableCell style={{ width: '100px' }}>{originalRow?.resultFormat}</TableCell>
    },
    {
      Header: 'Created',
      accessor: (originalRow: ISavedSearch) => <TableCell style={{ width: '110px' }}>{DateTime.fromISO(originalRow?.createdAt).toRelative()}</TableCell>
    },
    {
      Header: 'Action',
      accessor: (originalRow: ISavedSearch) => {
        return (
          <div style={{ display: 'flex', flexFlow: 'row nowrap', marginLeft: -15 }}>
            <ActionItem fill="rgb(87,102,236)" onClick={() => editSearch(originalRow)}>
              <BiEdit size={20} />
            </ActionItem>
            <Popconfirm
              title="Are you sure to delete this search?"
              onConfirm={() => deleteSearch(originalRow._id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <ActionItem fill="red">
                <BiTrash size={20} />
              </ActionItem>
            </Popconfirm>
          </div>
        );
      }
    }
  ]
);

export default generateSearchColumns;