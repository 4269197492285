import { FC } from 'react';
import AsyncSelect from 'react-select/async';
import { SingleValue } from 'react-select';
import { useDispatch } from 'react-redux';
import { getGoogleLocations } from 'data/actions/google';
import { IGoogleLocation } from 'ts/interfaces/google/google.data';


const LocationSelect: FC<any> = ({ setLocation, isLoading }) => {
    const dispatch = useDispatch();

    const loadOptions = (
        inputValue: string,
        callback: (options: any) => void
      ) => {
        dispatch(getGoogleLocations(inputValue, callback))
      };

    return (
        <AsyncSelect
            className="basic-single"
            classNamePrefix="select"
            isDisabled={isLoading}
            isClearable
            loadOptions={loadOptions}
            name="google-location"
            placeholder="Choose location from the list..."
            noOptionsMessage={() => 'Start typing...'}
            onChange={(newValue: SingleValue<IGoogleLocation>) => setLocation(newValue)}
            getOptionLabel={(option: IGoogleLocation) => option.canonicalName}
            getOptionValue={(option: IGoogleLocation) => option._id}
            menuPlacement="auto"
        />
    )
}

export default LocationSelect;