import styled from 'styled-components';

export const DetailsRow = styled.div`
    display: flex;
    align-items: center;
    color: rgb(118 134 152);
    padding: 3px;
`;

export const DetailsData = styled.span`
    margin-left: 10px;
    color: #40413f;
`