import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const getUserCredits = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER_CREDITS,
    payload: {
      method: 'GET',
      endpoint: 'credits',
    },
  }
  return action;
};
