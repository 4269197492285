import { IUiPlan } from 'ts/interfaces/plan/plan';

const Plans: IUiPlan[] = [
    {
        name: 'Hobby',
        price: 14.99,
        features: [
            '1,000 searches per month',
            '1 automation',
            'Usage analytics',
        ],
        disabledFeatures: [
            'Team management',
            'Chat support'
        ],
        id: "6364197c60473bfcda48838e"
    },
    {
        name: 'Basic',
        price: 39.99,
        features: [
            '5,000 searches per month',
            '3 automation',
            'Usage analytics',
            'Team management'
        ],
        disabledFeatures: [
            'Chat support',
        ],
        id: "6364197c60473bfcda48838f"
    },
    {
        name: 'Pro',
        price: 79.99,
        features: [
            '15,000 searches per month',
            '5 automation',
            'Usage analytics',
            'Chat support',
            'Team management'
        ],
        id: "6364197c60473bfcda488390"
    },
    {
        name: 'Ultra',
        price: 149.99,
        features: [
            '50,000 searches per month',
            'Unlimited automation',
            'Usage analytics',
            'Chat support',
            'Team management'
        ],
        id: "6364197c60473bfcda488391"
    }
];

export default Plans;