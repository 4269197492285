import OptionType from 'ts/types/option';
import { IUsageAnalyticsPeriod } from 'ts/interfaces/usage/usage.analytics';

interface IPeriodOption extends OptionType {
    value: IUsageAnalyticsPeriod
}
export const periodOptions: IPeriodOption[] = [
    { value: 'month', label: 'Last month' }, 
    { value: 'day', label: 'Last day' }, 
    { value: 'hour', label: 'Last hour' }
];