import * as FileSaver  from 'file-saver';
import { useSelector } from 'react-redux';

//** Components */
import Button from 'components/button';

//** Selectors */
import { googleResultSelector } from 'data/selectors/google';

//** Types */
import { IGoogleSearchResult } from 'ts/interfaces/google/google.data';

//** Styles */
import { Container } from './styles'; 
import { BiCloudDownload } from 'react-icons/bi';
import { Tooltip } from 'antd';

const DownloadResultButton = () => {
    const googleResult: IGoogleSearchResult = useSelector(googleResultSelector);
    const downloadResult = () => {
        if (!googleResult?.data) return;

        switch (googleResult.type) {
            case 'html': {
                const blob = new Blob([googleResult.data], { type: "text/html;charset=utf-8" });
                FileSaver.saveAs(blob, "spaceserp_result.html");
                break;
            }
            case 'csv': {
                const blob = new Blob([googleResult.data], { type: "text/csv;charset=utf-8" });
                FileSaver.saveAs(blob, "spaceserp_result.csv");
                break;
            }
            default: {
                const blob = new Blob([JSON.stringify(googleResult.data)], { type: "application/json;charset=utf-8" });
                FileSaver.saveAs(blob, "spaceserp_result.json");
            }

        }
    }
    if (!googleResult?.data) return <></>
    return (
      <Tooltip title="Download results">
        <Container>
          <Button onClick={downloadResult}>
            <BiCloudDownload size={20} />
          </Button>
        </Container>
      </Tooltip>
    );
}


export default DownloadResultButton;