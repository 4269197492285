import { useSelector } from 'react-redux';
import { modalsSelector } from 'data/selectors/modals';
import { EModalTypes } from 'ts/enums/modal.types';
import ConfirmationModal from 'components/modals/confirmationModal';
import AdvancedFeatureModal from 'components/modals/advancedFeatureModal';

const ModalsController = () => {
  const { type } = useSelector(modalsSelector);

  switch (type) {
    case EModalTypes.CONFIRMATION: {
      return <ConfirmationModal />
    }
    case EModalTypes.ADVANCED_FEATURE: {
      return <AdvancedFeatureModal />
    }
    default: 
      return null;
  }
}

export default ModalsController;
