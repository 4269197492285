import { FC } from 'react';
import { Popover } from 'antd';
import Button from 'components/button';
import { FlexRow } from 'components/common/styles';
import { BsGeoAltFill, BsSearch, BsGoogle } from 'react-icons/bs';
import { MdLanguage } from 'react-icons/md';
import { IoLanguage, IoDocumentText, IoDesktopSharp } from 'react-icons/io5';
import { IGoogleSearchRequest } from 'ts/interfaces/google/google.search.request';
import {
    DetailsRow,
    DetailsData
} from './styles';

const UsageDetails: FC<({ data: IGoogleSearchRequest })> = ({ data }) => {
    const content = (
        <div>
          { data?.q && <DetailsRow><BsSearch color="rgb(139, 139, 139)"/><DetailsData>{data?.q}</DetailsData></DetailsRow> }
          { data?.location && <DetailsRow><BsGeoAltFill color="rgb(139, 139, 139)"/><DetailsData>{data?.location}</DetailsData></DetailsRow> }
          { data?.domain && <DetailsRow><BsGoogle color="rgb(139, 139, 139)"/><DetailsData>{data?.domain}</DetailsData></DetailsRow> }
          { data?.hl && <DetailsRow><MdLanguage color="rgb(139, 139, 139)"/><DetailsData>{data?.hl}</DetailsData></DetailsRow> }
          { data?.gl && <DetailsRow><IoLanguage color="rgb(139, 139, 139)"/><DetailsData>{data?.gl}</DetailsData></DetailsRow> }
          { data?.resultFormat && <DetailsRow><IoDocumentText color="rgb(139, 139, 139)"/><DetailsData>{data?.resultFormat}</DetailsData></DetailsRow> }
          { data?.device && <DetailsRow><IoDesktopSharp color="rgb(139, 139, 139)"/><DetailsData>{data?.device}</DetailsData></DetailsRow> }
        </div>
      );

      return (
        <Popover content={content} placement="left">
            <FlexRow>
                <Button type="link">Details</Button>
            </FlexRow>
        </Popover>
      )
}

export default UsageDetails;