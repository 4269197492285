import styled from "styled-components";

export const ForgotPassword = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 15px 0;
    
    span {
        cursor: pointer;
        color: rgb(87, 102, 236);
    }
`;

export const Label = styled.span`
    margin: 10px 0;
    display: block;
    > span {
      color: #5766ec;
      display: unset;
      cursor: pointer;
    }
`;

export const CreateAccountLabel = styled.span`
    color: #5766ec;
    margin-top: 20px;
`;

export const Title = styled.h2`
    margin: 10px 0 20px 0;
    font-weight: bold;
`;

export const PasswordResetDescription = styled.span`
    margin-bottom: 15px;
    display: block;
`;

export const ErrorMessage = styled.span`
    text-align: center;
    color: rgb(253, 42, 71);
    margin-bottom: 15px;
`;