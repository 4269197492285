import { ILoadingState } from 'ts/interfaces/loading/loading';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: ILoadingState = {
    sign_up: false,
    confirm_account: false,
    sign_in: false,
    password_reset: false,
    password_forgot: false,
    google_auth: false,
    get_user: true,
    update_user: false,
    apply_coupon: false,
    api_playground: false,
    save_search: false,
    automation_list: false,
    usage_analytics: false,
    saved_searches_list: false,
};
  
 const loadingReducer = (state = initialState, action: IReduxAction) => {
    switch (action.type) {
      case EReduxTypes.UPDATE_LOADING_COMPONENT: {
        const { components, loading } = action.data;
  
        components.forEach((item: keyof ILoadingState) => {
          state[item] = loading;
        });
  
        return {
          ...state,
        };
      }
  
      default:
        return state;
    }
};

export default loadingReducer;
