import styled from 'styled-components';

export const ControlsBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 0;
`;

export const UsageTypeSelectContainer = styled.div`
    width: 200px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
`;
