import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, Navigate} from 'react-router-dom';
import { shouldConfirmEmail } from 'data/selectors/user';
import SignInPage from 'pages/auth/sign-in';
import SignUpPage from 'pages/auth/sign-up';
import PasswordResetPage from 'pages/auth/forgot-password';
import ConfirmEmailPage from 'pages/auth/confirm-email';
import ResetPassword from 'pages/auth/reset-password';
import GoogleVerifyPage from 'pages/auth/google-verify';

const UnauthorizedUser: FC = () => {
    const confirmEmail = useSelector(shouldConfirmEmail);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (confirmEmail) {
        navigate("/confirm-email", { replace: true });
      }
    }, [confirmEmail, navigate]);
  
    return (
      <Routes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/password-forgot" element={<PasswordResetPage />} />
        <Route path="/confirm-email" element={<ConfirmEmailPage />} />
        <Route path="/password-reset/:token" element={<ResetPassword />} />
        <Route path="/oauth/google/verify" element={<GoogleVerifyPage />} />
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
    )
};

export default UnauthorizedUser;