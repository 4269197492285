import loadingIcon from 'assets/loading.svg';
import Logo from 'assets/logo.svg';

import { Container, LogoContainer } from './styles';

const FullScreenLoader = () => (
  <Container>
    <img src={loadingIcon} width={50} alt={"loading"} />
    <LogoContainer>
      <img src={Logo} width={150} alt={"space_serp_logo"} />
    </LogoContainer>
  </Container>
);

export default FullScreenLoader;
