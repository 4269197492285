import { EIntervalTypes } from 'ts/enums/interval.types';

const parseValues = (values: string[]) => values.reduce((prev: string[], curr) => {
  if (!isNaN(parseInt(curr, 10))) prev.push(curr);
  return prev;
}, [])

const parseInterval = (interval: string) => {
  const cron: string[] = interval.split(' ');

  const result = {
    interval: EIntervalTypes.EVERY_MINUTE,
    hours: parseValues(cron[1].split(',')),
    daysOfWeek: parseValues(cron[4].split(',')),
    daysOfMonth: parseValues(cron[2].split(','))
  }

  if (cron.find((value) => value !== '*')) {
    switch (true) {
      case (cron[0] === '1'): {
        result.interval = EIntervalTypes.EVERY_HOUR;
        break;
      }
      case (cron[2] === '*' && cron[4] === '*'): {
        result.interval = EIntervalTypes.DAILY;
        break;
      }
      case (cron[4] !== '*'): {
        result.interval = EIntervalTypes.WEEKLY;
        break;
      }
      default: {
        result.interval = EIntervalTypes.MONTHLY;
        break;
      }
    }
  }

  return result;
}

export default parseInterval;