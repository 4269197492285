import { startCase } from 'lodash';
import { DateTime } from 'luxon';
import UsageDetails from 'components/usageDetails';
import { IUsageHistory } from 'ts/interfaces/usage/usage.history';
import { TableCell } from './styles';

const HistoryTableColumns = [
  {
    Header: 'Type',
    accessor: (originalRow: IUsageHistory) => <TableCell>{startCase(originalRow.type)}</TableCell>
  },
  {
      Header: 'Query',
      accessor: (originalRow: IUsageHistory) => <TableCell>{originalRow?.data?.q}</TableCell>
  },
  {
      Header: 'Details',
      accessor: (originalRow: IUsageHistory) => <UsageDetails data={originalRow.data}/>
  },
  {
      Header: 'Cost',
      accessor: (originalRow: IUsageHistory) => <TableCell>{originalRow?.cost}</TableCell>
  },
  {
      Header: 'Date',
      accessor: (originalRow: IUsageHistory) => <TableCell>{ DateTime.fromISO(originalRow?.createdAt).toRelative()}</TableCell>
  },
];

export default HistoryTableColumns;
