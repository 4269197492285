import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  background: white;
  max-height: 100%;
  overflow-y: scroll;
  
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      th {
        text-align: left;

        &:first-child {
          border-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-child {
          border-radius: 5px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    tr {
      &:hover {
        background: #f6f6f6;
      }
    }

    th,
    td {
      margin: 0;
      padding: 10px 15px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;