import styled from 'styled-components';

export const PlaceholderTitle = styled.div`
    color: #071427f2;
    font-size: 18px;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-weight: bold;
    text-align: center;
`;

export const PlaceholderDescription = styled.div`
    color: #071427f2;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
`;

export const CustomLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(87,102,236);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  div:nth-child(1) {
    left: 8px;
    top: 0;
    animation: lds-ellipsis1 0.4s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    top: 0;
    animation: lds-ellipsis2 0.4s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    top: 0;
    animation: lds-ellipsis2 0.4s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    top: 0;
    animation: lds-ellipsis3 0.4s infinite;
  }
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
`;

export const LoadingLabel = styled.span`
    color: #071427f2;
    font-size: 14px;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const TableCell = styled.div`
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
