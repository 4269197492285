import { FC, useCallback, useMemo, useState } from 'react';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';

//** Redux layer */
import { createAutomation, updateAutomation } from 'data/actions/automation';
import { toggleDrawer } from 'data/actions/drawer';

//** Components  */
import Button from 'components/button';
import SearchForm from 'components/searchForm';
import SavedSearchSelect from 'components/savedSearchSelect';
import IntervalSelect from 'components/intervalSelect';
import OutputSelect from 'components/outputSelect';
import CreateSearchFooter from 'components/createAutomationWizard/create.search.footer';
import CreateAutomationFooter from 'components/createAutomationWizard/create.automation.footer';

//** Styles */
import { FlexRowContainer, Description, Input, FormItem } from 'components/common/styles';
import {
    Group,
    Label
} from './styles';
import { IAutomation } from 'ts/interfaces/automation/automation';

const CreateAutomationWizard: FC<{ visible: boolean, automation?: IAutomation }> = ({ visible, automation }) => {
    const dispatch = useDispatch();
    const mainMode = useMemo(() => automation ? 'edit-automation' : 'create-automation', [])

    const [name, setName] = useState<string>(automation?.name || '');
    const [interval, setInterval] = useState<string>(automation?.interval || '');
    const [output, setOutput] = useState<any>(automation?.output);
    const [associatedSearches, setAssociatedSearches] = useState<string[]>(automation?.searches || []);
    const [mode, setMode] = useState<'create-automation'|'create-search'|'edit-automation'>(mainMode);
    const [newSearch, setNewSearch] = useState<boolean>(false);
    const [isFormFieldsValid, setFormFieldsValid] = useState(true);

    const createAutomationCallback = useCallback(() => {
        if (mainMode === 'create-automation') dispatch(createAutomation(name, interval as string, associatedSearches, output));
        else if (automation) dispatch(updateAutomation(automation._id, {
            name,
            interval,
            output,
            searches: associatedSearches
        }))
        dispatch(toggleDrawer(false));
    }, [name, interval, associatedSearches, output, mainMode]);

    const isFormValid = !!name && !!interval && !!associatedSearches?.length && !!output;

    const Footer = () => mode === mainMode ? 
        <CreateAutomationFooter
            isFormValid={isFormValid && isFormFieldsValid} 
            createAutomation={createAutomationCallback} 
            cancelAction={() => dispatch(toggleDrawer(false))}
            isUpdate={!!automation}
        /> : 
        <CreateSearchFooter 
            cancelAction={() => setMode(mainMode)} 
            setNewSearch={() => setNewSearch(true)} 
        />

    return (
        <Drawer 
            title={`${mainMode === 'create-automation' ? 'Create' : 'Edit'} automation`}
            placement="right" 
            width={window.innerWidth > 1249 ? 500 : '100%'}
            onClose={() => dispatch(toggleDrawer(false))} 
            visible={visible}
            forceRender
            footer={<Footer />}
        >
            {
                (mode === mainMode) ? (
                    <>
                <Group>
                        <FormItem>
                            <Description>Automation name:</Description>
                            <Input placeholder="Name..." name="automation-name" value={name} onChange={(e) => setName(e?.target?.value)}/>
                        </FormItem>

                        <FormItem>
                        <FlexRowContainer style={{ marginBottom: 10 }}>
                            <Label> Searches </Label>
                            <Button type="link" onClick={() => setMode('create-search')}>+ Create search</Button>
                        </FlexRowContainer>
                        <Description>Searches associated with automation. Will run every time the automation starts.</Description>
                        <SavedSearchSelect value={associatedSearches} setValue={setAssociatedSearches} newSearch={newSearch}/>
                    </FormItem>

                        <FormItem>
                            <Label> Interval </Label>
                            <Description>Automation launch schedule.</Description>
                            <IntervalSelect value={interval} setValue={setInterval}/>
                        </FormItem>
                        <FormItem>
                            <Label> Output </Label>
                            <Description>Destinations where the automation results will be sent.</Description>
                            <OutputSelect value={output} setValue={setOutput}/>
                        </FormItem>
                    </Group>

                   
                    </>
                ): (
                    <SearchForm setFormFieldsValid={setFormFieldsValid} createMode={true} />
                )
            }
        </Drawer>
    )
};

export default CreateAutomationWizard;