import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const triggerLoadingAction = (components: string[], loading: boolean): AnyAction => {
    const action: IReduxAction = {
      type: EReduxTypes.UPDATE_LOADING_COMPONENT,
      data: {
        components,
        loading,
      },
    };
    return action;
};
