import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';

//** Definitions - interface, type, const */
import { IGoogleSearchRequest } from 'ts/interfaces/google/google.search.request';
import { ISavedSearch } from 'ts/interfaces/saved-search/saved-search.data';

//** Enum */
import { ButtonHeight } from 'ts/enums/styles';

//** Components  */
import ContentBox from 'components/contentBox';
import ApiResponseBox from 'components/apiResponseBox';
import ExampleUrlBox from 'components/exampleUrlBox';
import Button from 'components/button';
import SearchForm from 'components/searchForm';

//** Redux layer */
import { getLoadingSelector } from 'data/selectors/loading';
import { saveGoogleSearch } from 'data/actions/google';
import { isGoogleSearchFormValid, googleRequestSelector, editableSearchSelector } from 'data/selectors/google';
import { setEditableSearch, updateSavedSearch } from 'data/actions/savedSearch';

//** Styles */
import { SectionTitle, HeaderContainer, Description, Input, FormItem } from 'components/common/styles';
import { 
    ResultBox,
    PlaygroundParamsContainer,
    PlaygroundResultContainer
} from './styles';

const SaveSearchModal: FC<{ disabled: boolean }> = ({ disabled }) => {
    const dispatch = useDispatch();
    const isLoading: boolean = useSelector(getLoadingSelector('save_search'));
    const request: IGoogleSearchRequest = useSelector(googleRequestSelector);
    const editableSearch: ISavedSearch | null = useSelector(editableSearchSelector);
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState('');

    const handleSaveClick = useCallback(() => {
      if (editableSearch) dispatch(updateSavedSearch(editableSearch))
      else setVisible(true)
    }, [editableSearch]);

    useEffect(() => () => {
      dispatch(setEditableSearch(null));
    }, []);

    return (
        <>
            {editableSearch && <Button size={ButtonHeight.MEDIUM} style={{ width: 150, background: 'none', border: '1px solid rgb(87, 102, 236)', marginRight: 10, color: 'rgb(87, 102, 236)' }} onClick={() => dispatch(setEditableSearch(null))}>Cancel</Button>}
            <Button loading={isLoading} size={ButtonHeight.MEDIUM} style={{ width: 200 }} disabled={disabled || (editableSearch && (!editableSearch?.name?.length || !editableSearch?.q?.length))} onClick={handleSaveClick}>Save {editableSearch ? '' : 'search'}</Button>
            <Modal 
                title="Save search" 
                visible={visible} 
                onOk={() => {
                    dispatch(saveGoogleSearch(name, omit(request, 'name'), (search: ISavedSearch) => dispatch(setEditableSearch(search))));
                    setVisible(false);
                    setName('');
                }} 
                onCancel={() => setVisible(false)}
            >
                <FormItem>
                    <Input 
                        placeholder="Search name..." 
                        name="search_name"  
                        value={name} 
                        onChange={(e) => setName(e?.target?.value)}
                    />
                </FormItem>
            </Modal>
        </>
    )
}

const PlaygroundPage: FC = () => {
    const isLoading: boolean = useSelector(getLoadingSelector('api_playground'));
    const isFormValid: boolean = useSelector(isGoogleSearchFormValid);
    const [isFormFieldsValid, setFormFieldsValid] = useState(true);

    return (
        <>
            <HeaderContainer>
                <SectionTitle>API Playground</SectionTitle>
                <SaveSearchModal disabled={!isFormValid || !isFormFieldsValid}/>
            </HeaderContainer>
            <ContentBox>
                    <PlaygroundParamsContainer>
                        <SearchForm setFormFieldsValid={setFormFieldsValid}/>
                    </PlaygroundParamsContainer>

                    <PlaygroundResultContainer>
                        <FormItem style={{ justifyContent: 'flex-start', flex: 0 }}>
                            <Description>Just send the URL you would like to scrape to the API along with your <Link to={'/settings/api'}>API key</Link></Description>
                            <ExampleUrlBox disabled={isLoading || !isFormValid || !isFormFieldsValid}/>
                        </FormItem>

                            <ResultBox>
                                <ApiResponseBox isLoading={isLoading} />
                            </ResultBox>
                    </PlaygroundResultContainer>
            </ContentBox>
        </>
    )
}

export default PlaygroundPage;