import { DateTime } from 'luxon';
import { IUsageAnalyticsDocument, IUsageAnalyticsPeriod } from 'ts/interfaces/usage/usage.analytics';

const generateData = (dataset: IUsageAnalyticsDocument[], period: IUsageAnalyticsPeriod, timezone: string = 'Europe/Kiev') => {
    const labels: string[] = [];
    const creditsUsage: number[] = [];
  
    switch (period) {
      case 'month': {
        const start = DateTime.now().setZone(timezone).minus({ months: 1 });
        const diff =  DateTime.now().diff(start, ["days"]).toObject()?.days;

        if (!diff) break;
        
        for(let i = 0; i<= diff; i++) {
            const currentDate = start.plus({ day: i });
            const month = currentDate.toFormat('M');
            const day = currentDate.toFormat('dd');
            
            labels.push(currentDate.toFormat('MMMM, dd'))
            creditsUsage.push(dataset.find(({ _id }) => _id.month === parseInt(month, 10) && _id.day === parseInt(day, 10))?.credits || 0)
        }
        break;
      }
      case 'day': {
        const start = DateTime.now().setZone(timezone).minus({ days: 1 });
        const diff =  DateTime.now().diff(start, ["hours"]).toObject()?.hours;
        
        if (!diff) break;

        for(let i = 0; i<= diff; i++) {
            const currentDate = start.plus({ hours: i });
            const month = currentDate.toFormat('M');
            const day = currentDate.toFormat('dd');
            const hour = currentDate.toFormat('H');

            labels.push(currentDate.toFormat('HH:00'))
            creditsUsage.push(dataset.find(({ _id }) => _id.month === parseInt(month, 10) && _id.day === parseInt(day, 10) && _id.hour === parseInt(hour, 10))?.credits || 0)
        }
        break;
      }

      case 'hour': {
        const start = DateTime.now().setZone(timezone).minus({ hours: 1 });
        const diff =  DateTime.now().diff(start, ["minutes"]).toObject()?.minutes;
        
        if (!diff) break;

        for(let i = 0; i<= diff; i++) {
            const currentDate = start.plus({ minutes: i });
            const month = currentDate.toFormat('M');
            const day = currentDate.toFormat('dd');
            const hour = currentDate.toFormat('H');
            const minute = currentDate.toFormat('mm');

            labels.push(currentDate.toFormat('HH:mm'));
            creditsUsage.push(dataset.find(({ _id }) => _id.month === parseInt(month, 10) && _id.day === parseInt(day, 10) && _id.hour === parseInt(hour, 10) && _id.minute === parseInt(minute, 10))?.credits || 0)
        }
        break;
      }
      default:
        break;
    }
  
    return {
      labels,
      datasets: [
        {
          label: 'Credits',
          data: creditsUsage,
          backgroundColor: "#1890ff",
          borderWidth: 1
        },
      ]
    }
  };

export default generateData;