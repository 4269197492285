import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { Radio, Input } from 'antd';

//** Actions */
import { updateUser } from 'data/actions/user';

//** Selectors */
import { userEmail, userBillingEmail } from 'data/selectors/user';

/** Enums */
import EBillingEmailType from 'ts/enums/billing.email';

//** Components */
import Button from 'components/button';

//** Styles */
import { SectionItem, SectionHeader, SectionDescription } from 'components/common/styles';
import { AlternativeEmailContainer, BillingContainer, EmailTypeContainer } from './styles';

const { Group } = Radio;

const BillingEmailLayout: FC = () => {
    const dispatch = useDispatch();
    const [billingEmailType, setBillingEmailType] = useState<EBillingEmailType>(EBillingEmailType.PRIMARY_EMAIL);
    const [alternativeEmail, setAlternativeEmail] = useState('');

    const email = useSelector(userEmail);
    const billingEmail = useSelector(userBillingEmail);

    useEffect(() => {
        if (billingEmail) {
          setAlternativeEmail(billingEmail);
          setBillingEmailType(EBillingEmailType.ALTERNATIVE_EMAIL);
        }
      }, [billingEmail]);
    
    useEffect(() => {
        if (alternativeEmail && billingEmailType === EBillingEmailType.PRIMARY_EMAIL) {
          dispatch(updateUser([{ billingEmail: "" }], () => {}))
        }
    }, [billingEmailType, alternativeEmail])
      

    return (
        <SectionItem fullWidth>
            <BillingContainer>
                <div>
                    <SectionHeader>Billing email</SectionHeader>
                    <SectionDescription>Where should orders be sent?</SectionDescription>
                </div>
                <EmailTypeContainer>
                    <Group value={billingEmailType} onChange={(e) => setBillingEmailType(e.target.value)}>
                        <Radio value={EBillingEmailType.PRIMARY_EMAIL}>
                            Send to my account email
                            <SectionDescription>{email}</SectionDescription>
                        </Radio>
                        <Radio value={EBillingEmailType.ALTERNATIVE_EMAIL}>
                            Send to an alternative email
                        </Radio>
                    </Group>
                    {
                        billingEmailType === EBillingEmailType.ALTERNATIVE_EMAIL && (
                        <AlternativeEmailContainer>
                            <Input placeholder='Email' value={alternativeEmail} onChange={(e) => setAlternativeEmail(e.target.value)}/>
                            <Button
                                style={{ height: 35, borderRadius: 3, width: 150, marginLeft: 30 }}
                                disabled={!alternativeEmail || alternativeEmail === billingEmail}
                                onClick={() => { dispatch(updateUser([{ billingEmail: alternativeEmail }], () => {}))}}
                            >
                                Save
                            </Button>
                        </AlternativeEmailContainer>
                        )
                    }
                </EmailTypeContainer>
            </BillingContainer>
        </SectionItem>
    )
}

export default BillingEmailLayout;