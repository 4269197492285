import { SectionTitle } from 'components/common/styles';
import styled from 'styled-components';

export const TableEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

export const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 15px;
`;

export const Description = styled.p`
  font-size: 16px;
  margin: 0;
`;
