import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { EModalTypes } from 'ts/enums/modal.types';
import { modalsSelector, modalOptionsSelector } from 'data/selectors/modals';
import { toggleModal } from 'data/actions/modals';
import { BsX } from 'react-icons/bs';
import UpgradePlanButton from 'components/upgradePlanButton';
import {
  Title,
  Header,
  ControlButton,
} from '../baseModal/styles';
import {
    ModalContainer,
    DescriptionContainer 
  } from '../confirmationModal/styles';

const AdvancedFeatureModal: FC = () => {
  const dispatch = useDispatch();
  const { visible } = useSelector(modalsSelector);
  return (
    <Modal
      visible={visible}
      destroyOnClose
      footer={null}
      closable={false}
      width={520}
    >
      <Header>
        <ControlButton onClick={() => dispatch(toggleModal({ visible: false }))}>
            <BsX />
        </ControlButton>
      </Header>

      <ModalContainer>
        <Title>
            Automation is an advanced feature
        </Title>
        <DescriptionContainer>
            This feature available on the <b>Basic</b> plan and above.
        </DescriptionContainer>
        <UpgradePlanButton />
      </ModalContainer>
      
      
    </Modal>
  );
};

export default AdvancedFeatureModal;