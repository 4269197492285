import { FC, useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IPaginatedSavedSearch, ISavedSearch } from 'ts/interfaces/saved-search/saved-search.data';
import { getSavedSearches } from 'data/actions/savedSearch';

//** Styles */ 
import { FlexColumnContainer } from 'components/common/styles';
import { savedSearchDataSelector, savedSearchMetaSelector, savedSearchPageNumberSelector, savedSearchPageSizeSelector } from 'data/selectors/savedSearch';
import { getLoadingSelector } from 'data/selectors/loading';

const SavedSearchSelect: FC<{ value: string[], setValue: (value: any) => void, newSearch: boolean }> = ({ value, setValue, newSearch = false }) => {
  const dispatch = useDispatch();
  const savedSearches: ISavedSearch[] = useSelector(savedSearchDataSelector('select'));
  const pageNumber: number = useSelector(savedSearchPageNumberSelector('select'));
  const pageSize: number = useSelector(savedSearchPageSizeSelector('select'));
  const { totalPages = 0 } = useSelector(savedSearchMetaSelector('select'));
  const isLoading = useSelector(getLoadingSelector('saved_searches_list'));
  const [selectedOptions, setSelectedOptions] = useState<ISavedSearch[]>([]);
  const [showNewSearchBadge, setShowNewSearchBadge] = useState<boolean>(newSearch);
  const [searchInput, setSearchInput] = useState('');

  const handleLoadSelectedOptions = useCallback((data?: IPaginatedSavedSearch) => {
    if (value.length) {
      const loadedOptions = (data?.data || savedSearches).filter((search) => value.includes(search._id));
      if (loadedOptions.length === value.length) setSelectedOptions(loadedOptions);
      else dispatch(getSavedSearches('select', '', { pageNumber: 0, pageSize: value.length }, value, (data: IPaginatedSavedSearch) => setSelectedOptions(data.data)));
    }
  }, [value, savedSearches]);

  useEffect(() => {
    if (value.length && savedSearches.length) handleLoadSelectedOptions();
  }, []);

  useEffect(() => {
    setSelectedOptions([...selectedOptions, ...savedSearches.filter((search) => !selectedOptions.find((selectedSearch) => selectedSearch._id === search._id))].filter((option) => value.includes(option._id)));
  }, [value]);

  const loadNextPage = useCallback(() => {
    if (!searchInput.length && pageNumber + 1 < totalPages) dispatch(getSavedSearches('select', '', { pageNumber: pageNumber + 1, pageSize }));
  }, [pageNumber, totalPages, searchInput]);

  useEffect(() => {
    if (!savedSearches.length) dispatch(getSavedSearches('select', '', { pageNumber, pageSize }, undefined, handleLoadSelectedOptions));
  }, []);

  const loadOptions = useCallback((
    inputValue: string,
    callback: (options: any) => void
  ) => {
    if (pageNumber + 1 === totalPages) callback(savedSearches.filter((search) => search.name.includes(inputValue)));
    else dispatch(getSavedSearches('select', inputValue, undefined, undefined, (data) => callback(data.data)));
  }, [pageNumber, totalPages, savedSearches]);

    useEffect(() => {
        if (showNewSearchBadge) {
            setTimeout(() => setShowNewSearchBadge(false), 5000);
        }
    })

    return (
        <FlexColumnContainer>
            {
                showNewSearchBadge && (
                    <Badge.Ribbon text="New search available here" color="green" style={{marginTop: -35}}>
                    </Badge.Ribbon>
                )
            }
        <AsyncSelect
            className="basic-single"
            classNamePrefix="select"
            isDisabled={false}
            isClearable
            isMulti
            isLoading={isLoading}
            loadOptions={loadOptions}
            name="saved-searches"
            placeholder="Choose search from the list..."
            noOptionsMessage={() => 'Start typing...'}
            defaultOptions={savedSearches}
            value={selectedOptions}
            onMenuScrollToBottom={loadNextPage}
            onChange={(newValue: any) => {
              setSelectedOptions(newValue);
              setValue(newValue.map((item: any) => item?._id));
            }}
            onInputChange={setSearchInput}
            getOptionLabel={(option: ISavedSearch) => option.name}
            getOptionValue={(option: ISavedSearch) => option._id}
            menuPlacement="auto"
            onMenuOpen={() => setShowNewSearchBadge(false)}
        />
        </FlexColumnContainer>
    )
}

export default SavedSearchSelect;