import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/button';
import { Tooltip } from 'antd';
import { SettingPageHeader, Container, SettingPageDescription, AccountSettingsContainer } from '../account/styles';
import { FlexColumnContainer, FlexRowContainer } from 'components/common/styles';
import { ButtonHeight, } from 'ts/enums/styles';
import { IApplication } from 'ts/interfaces/application/application';
import { DateTime } from 'luxon';
import { applicationDataSelector, applicationsCountSelector } from 'data/selectors/application';
import Table from 'components/commonTable';
import { ApiKeyActionsContainer, ApiKeyContainer, ApiKeyHeaderContainer, ApiKeyTablesContainer } from './styles';
import { BsCheck } from 'react-icons/bs';
import { addNewApplication, deleteApplication } from 'data/actions/application';
import { Divider } from '../billing/styles';
import { BiCopy, BiLowVision, BiTrash } from 'react-icons/bi';

const ApiKey: FC<{ application: IApplication }> = ({ application }) => {
    const [show, setShow] = useState(false);
    const visibleKey: string = show ? application.key : Array.from({ length: application.key.length - 4 }, () => '*').join('') + application.key.substring(application.key.length - 4);
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <ApiKeyContainer>{visibleKey}</ApiKeyContainer>
            <Button style={{ marginLeft: 10, height: 20 }} type="link" onClick={() => setShow(!show)}><BiLowVision size={20}/></Button>
        </div>
    )
}

const ApiKeyControls: FC<{ application: IApplication }> = ({ application }) => {
    const dispatch = useDispatch();
    const [isCopied, setIsCopied] = useState(false);
    const applicationsCount = useSelector(applicationsCountSelector);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(application.key);
        setIsCopied(true);
    }

    return (
        <ApiKeyActionsContainer>
            <Tooltip 
                title={!isCopied ? 'Click to copy' : <FlexRowContainer><BsCheck size={22} color='#24a522' /> Copied </FlexRowContainer>}
                onVisibleChange={(value) => value && setIsCopied(false)}
            >
            <Button type="link" onClick={copyToClipboard}><BiCopy size={20}/></Button>
            </Tooltip>

            <Tooltip title={applicationsCount === 1 ? 'Your account always needs at least one API key. Please create a new one before deleting' : null}>
                <div>
                    <Button disabled={applicationsCount === 1} type="link" onClick={() => dispatch(deleteApplication(application._id))} style={{ color: 'red'}}><BiTrash size={20} /></Button>
                </div>
            </Tooltip>
        </ApiKeyActionsContainer>
    )
}

const ApplicationColumns = [
    {
        Header: 'Key',
        accessor: (originalRow: IApplication) => <ApiKey application={originalRow}/>
    },
    {
        Header: 'Created',
        accessor: (originalRow: IApplication) => {
            return <FlexRowContainer>
                <div style={{width: 100}}>{ DateTime.fromISO(originalRow?.createdAt).toRelative()}</div>
                <ApiKeyControls application={originalRow}/>
            </FlexRowContainer>
        }
    }
];

const ApiSettingsPage: FC = () => {
    const dispatch = useDispatch();
    const applications = useSelector(applicationDataSelector);
    const applicationsCount = useSelector(applicationsCountSelector);

    return (
        <Container>
            <ApiKeyHeaderContainer>
                <FlexColumnContainer>
                    <SettingPageHeader>API Keys</SettingPageHeader>
                    <SettingPageDescription>Manage your API keys and create new once, keep them secure</SettingPageDescription>
                </FlexColumnContainer>
                <Tooltip title={ (applicationsCount > 4) ? "You can't create more than 5 API keys" : null}>
                    <Button disabled={applicationsCount > 4} size={ButtonHeight.MEDIUM} onClick={() => dispatch(addNewApplication())}>+ Create New Key</Button>
                </Tooltip>
            </ApiKeyHeaderContainer>
            <AccountSettingsContainer>
                <ApiKeyTablesContainer>
                    <Table columns={ApplicationColumns} data={applications} containerStyles={{ padding: 0, marginLeft: -10, width: "calc(100% + 10px)" }} />
                </ApiKeyTablesContainer>
            </AccountSettingsContainer>
            <Divider />
            <SettingPageDescription>API keys are like your passwords: make sure to always keep them hidden! Share them only with services you trust.</SettingPageDescription>
        </Container>
    )
}

export default ApiSettingsPage;
