import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { Radio } from 'antd';
import { SettingPageHeader, Container, SettingPageDescription, BaseInput, } from '../account/styles';
import { getLoadingSelector } from 'data/selectors/loading';
import Button from 'components/button';
import {
  CouponContainer,
  Divider,
  SectionItem,
} from './styles';
import { applyCoupon } from 'data/actions/billing';
import openNotification from 'components/commonNotification';
import OrdersTable from 'components/ordersTable';
import BillingEmailLayout from 'layouts/billingEmail';

const { Group } = Radio;

const BillingSettingsPage: FC = () => {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const applyCouponLoader = useSelector(getLoadingSelector('apply_coupon'));

  return (
    <Container>
      <SettingPageHeader>Payment method</SettingPageHeader>
      <SettingPageDescription>Update your billing details and address.</SettingPageDescription>
      <Divider />
      <BillingEmailLayout />
      <Divider />
      <SectionItem>
        <div>
          <SettingPageHeader>Coupon code</SettingPageHeader>
          <SettingPageDescription>Reem your coupon code here.</SettingPageDescription>
        </div>
        <CouponContainer>
          <BaseInput value={code} placeholder="Enter coupon code" onChange={(e) => setCode(e.target.value)} />
          <Button
            disabled={applyCouponLoader || !code || code?.length < 36}
            loading={applyCouponLoader}
            onClick={() => {
              dispatch(applyCoupon(code, () => {
                openNotification({
                  type: 'success',
                  message: 'Success!',
                  description: 'Coupon successfully applied!',
                });
                }, (msg: string) => {
                  openNotification({
                    type: 'error',
                    message: 'Failed',
                    description: msg,
                  });
                })
              );
              setCode('');
            }
          }
          >
            Apply
          </Button>
        </CouponContainer>
      </SectionItem>
      <Divider />
      <SettingPageHeader>Billing history</SettingPageHeader>
      <OrdersTable />
    </Container>
  );
};

export default BillingSettingsPage;
