import { FC } from 'react';

//** Components  */
import Button from 'components/button';

const CreateAutomationFooter: FC<{ isFormValid: boolean, createAutomation: () => void, cancelAction: () => void, isUpdate: boolean }> = ({ isFormValid, createAutomation, cancelAction, isUpdate }) => {
    return (
        <div style={{ display: 'flex' }}>
            <Button disabled={!isFormValid} onClick={createAutomation} style={{ width: 150, height: 40 }}>{isUpdate ? 'Update' : 'Create'}</Button>
            <Button onClick={cancelAction} style={{ width: 120, height: 40, marginLeft: 10 }} empty>Cancel</Button>
        </div>
    )
}

export default CreateAutomationFooter;