import { FC, useState } from 'react';
import AuthLayout from 'layouts/auth/authLayout';
import EmailInput from 'components/emailInput';
import PasswordInput from 'components/passwordInput';
import { ErrorMessage, Label, CreateAccountLabel, Title } from './styles';
import Button from 'components/button';
import { signUp } from 'data/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLoadingSelector } from 'data/selectors/loading';
import useFingerPrint from 'hooks/useFingerPrint';
import ReCAPTCHA from "react-google-recaptcha";


const SignUpPage: FC = () => {
    const dispatch = useDispatch();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [recaptcha, setRecaptcha] = useState('');
    const loading = useSelector(getLoadingSelector('sign_up'));
    const googleLoading = useSelector(getLoadingSelector('google_auth'));
    const fingerPrint = useFingerPrint();

    const handleSignUp = (e: any) => {
        e.preventDefault();
        dispatch(signUp(email, password, fingerPrint, recaptcha, setError));
    };
    
    return (
        <AuthLayout>
            <Title>Create account</Title>
            { error && <ErrorMessage>{error}</ErrorMessage> }
            <EmailInput value={email} onChange={(e: any) => setEmail(e?.target?.value)} />
            <PasswordInput value={password} onChange={(e: any) => setPassword(e?.target?.value)}/>
            <br />
             <ReCAPTCHA
             sitekey="6LchjmooAAAAAJ0a0UyulaLmUiT3h4SxWD_6mtwH"
             onChange={(value) => {
                if (value) setRecaptcha(String(value))
             }}
            />
            <Button style={{marginTop: 25}} disabled={!recaptcha.length} onClick={handleSignUp} loading={loading || googleLoading}>Continue</Button>
            <Label>or</Label>
            <CreateAccountLabel>I already have an account. <Link to="/sign-in">Sign In</Link></CreateAccountLabel>
        </AuthLayout>
    )
}

export default SignUpPage;
