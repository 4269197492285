import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { drawerSelector } from 'data/selectors/drawer';
import { IDrawerState } from 'ts/interfaces/drawer/drawer';
import { EDrawerTypes } from 'ts/enums/drawer.types';

import CreateAutomationWizard from 'components/createAutomationWizard';

const DrawerController: FC = () => {
    const drawerState: IDrawerState = useSelector(drawerSelector);

    if (drawerState.type === EDrawerTypes.CREATE_AUTOMATION || drawerState.type === EDrawerTypes.UPDATE_AUTOMATION) return <CreateAutomationWizard visible={drawerState.visible} automation={drawerState.data} />
    return <></>
}

export default DrawerController;