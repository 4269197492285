import { FC, useEffect } from 'react';
import { Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCredits } from 'data/actions/credits';
import { creditsSelector } from 'data/selectors/credits';
import { totalCreditsSelector } from 'data/selectors/subscriptions';
import { ICredits } from 'ts/interfaces/credits/credits';
import numberToString from 'helpers/numberToString';
import { Container, Label } from './styles';

const CreditsBar: FC = () => {
    const dispatch = useDispatch();
    const credits: ICredits = useSelector(creditsSelector);
    const totalCredits: number = useSelector(totalCreditsSelector);

    useEffect(() => {
        const creditsInterval = setInterval(() => {
            dispatch(getUserCredits());
        }, 10000);

        dispatch(getUserCredits());
        return () => clearInterval(creditsInterval);
    }, [dispatch]);

    if (!Object.keys(credits).length) return <></>;
    return (
        <Container>
            <Label> <b>Credits:</b> {credits?.credits} out of {numberToString(totalCredits)}</Label> 
                <Progress 
                    percent={Math.ceil((credits?.credits / totalCredits) * 100)} 
                    size="small"
                    showInfo={false}
                />
        </Container>
    )
};


export default CreditsBar;
