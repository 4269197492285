import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1249px) {
    width: 100% !important;
  }
`;

export const PlaygroundParamsContainer = styled(FieldsContainer)`
  width: 30%;
  max-width: 400px;
`;

export const PlaygroundResultContainer = styled(FieldsContainer)`
  width: 60%;
  flex-grow: 100;
`;

export const ResultBox = styled.div`
  min-height: 610px;
  background: #f7f8fa;
  border-radius: 8px;
  width: 100%;
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  margin-bottom: 0px;
  align-self: stretch;
  flex-basis: 100%;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;