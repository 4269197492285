import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const signUp = (email: string, password: string, fingerPrint: string, recaptcha: string, setError: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_UP,
    payload: {
      method: 'POST',
      endpoint: `auth/sign-up`,
      body: {
        email,
        password,
        fingerPrint,
        recaptcha
      }
    },
    components: ['sign_up'],
    skipAuth: true,
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Create account error';
      setError(errorMessage);
    }
  }
  return action;
};

export const confirmAccount = (code: number, fingerPrint: string, setError: any): AnyAction => {
    const action: IReduxAction = {
      type: EReduxTypes.CONFIRM_ACCOUNT,
      payload: {
        method: 'POST',
        endpoint: `auth/confirm-account`,
        body: {
          code,
          fingerPrint
        }
      },
      components: ['confirm_account'],
      onFailed: (error: any) => {
        const errorMessage = error?.response?.data?.message || 'Confirm account error';
        setError(errorMessage);
      }
    }
    return action;
};

export const signIn = (email: string, password: string, fingerPrint: string, setError: any): AnyAction => {
    const action: IReduxAction = {
      type: EReduxTypes.SIGN_IN,
      payload: {
        method: 'POST',
        endpoint: `auth/sign-in`,
        body: {
          email,
          password,
          fingerPrint
        }
      },
      components: ['sign_in'],
      skipAuth: true,
      onFailed: (error: any) => {
        const errorMessage = error?.response?.data?.message || 'Sign-in error';
        setError(errorMessage);
      }
    }
    return action;
};

export const googleAuth = (token: string, fingerPrint: string, setError: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GOOGLE_AUTH,
    payload: {
      method: 'POST',
      endpoint: 'auth/google',
      body: {
        token,
        fingerPrint,
      }
    },
    components: ['google_auth'],
    skipAuth: true,
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-in error';
      setError(errorMessage);
    }
  };
  return action;
};

export const signOut = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_OUT,
    data: true,
    components: ['sign_out']
  }
  return action;
};

export const passwordReset = (email: string, password: string, passwordResetToken: string, resetSucceed: any = () => {}, setError: any = () => {}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.PASSWORD_RESET,
    payload: {
      method: 'POST',
      endpoint: 'auth/password-reset',
      body: {
        email,
        password,
        passwordResetToken,
      },
    },
    components: ['password_reset'],
    onSuccess: resetSucceed,
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Password reset error';
      setError(errorMessage);
    },
  };
  return action;
};

export const sendForgotPassword = (email: string, setSent: any = () => {}, setError: any = () => {}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_FORGOT_PASSWORD,
    payload: {
      method: 'POST',
      endpoint: 'auth/password-forgot',
      body: {
        email,
      },
    },
    components: ['password_forgot'],
    onSuccess: () => setSent(true),
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Password reset error';
      setError(errorMessage);
    },
  };
  return action;
};

export const getUser = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER,
    payload: {
      method: 'GET',
      endpoint: 'user',
    },
    components: ['get_user'],
  };
  return action;
};

export const updateUser = (properties: any, onSuccess: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_USER,
    payload: {
      method: 'PATCH',
      endpoint: 'user/update',
      body: {
        properties,
      },
    },
    onSuccess,
    components: ['update_user'],
  }
  return action;
}

export const deleteUser = (onSuccess: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_USER,
    payload: {
      method: 'DELETE',
      endpoint: 'user'
    },
    onSuccess,
  }
  return action;
}

export const updatePassword = (password: string | void, newPassword: string, onSuccess: any, onError: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_PASSWORD,
    payload: {
      method: 'POST',
      endpoint: 'user/update/password',
      body: {
        password,
        newPassword,
      },
    },
    onSuccess,
    onFailed: (error) => {
      const errorMessage = error?.response?.data?.message || 'Password reset error';
      onError(errorMessage);
    }
  };
  return action;
}
