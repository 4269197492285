import { FC, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoImage from 'assets/logo.svg';
import { Container, NavItemsContainer, NavItem, Logo, MobileMenuContainer, ProfileBarContainer, DesktopContainer, MobileMenuButtonContainer, RefContainer } from './styles';
import CreditsBar from 'components/creditsBar';
import ProfileBar from 'components/profileBar';
import { BiMenu } from 'react-icons/bi';
import MobileMenu from 'components/mobileMenu';
import useOnClickOutside from 'hooks/useOnClickOutside';

const Navigation: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <>
            <NavItem active={location?.pathname?.includes('playground')} onClick={() => navigate('/playground', { replace: true })}>
              Playground
            </NavItem>
            <NavItem active={location?.pathname?.includes('automation')} onClick={() => navigate('/automation', { replace: true })}>
              Automation
            </NavItem>
            <NavItem active={location?.pathname?.includes('searches')} onClick={() => navigate('/searches', { replace: true })}>
              Searches
            </NavItem>
            <NavItem active={location?.pathname?.includes('usage')} onClick={() => navigate('/usage', { replace: true })}>
              Usage
            </NavItem>
        </>
    )
}

const Header: FC = () => {
  const ref = useRef();
  const [mobileMenuVisible, toggleMobileMenuVisible] = useState(false);

  useOnClickOutside(ref, () => {
    toggleMobileMenuVisible(false);
  });
    
  return (
    <RefContainer ref={ref}>
      <Container>
        <NavItemsContainer>
          <Logo src={LogoImage} />
          <DesktopContainer>
            <Navigation />
            <ProfileBarContainer>
              <CreditsBar />
              <ProfileBar />
            </ProfileBarContainer>
          </DesktopContainer>
          <MobileMenuContainer>
            <ProfileBar mobile/>
            <MobileMenuButtonContainer>
              <BiMenu size={34} color="black" onClick={() => toggleMobileMenuVisible(!mobileMenuVisible)}/>
            </MobileMenuButtonContainer>
          </MobileMenuContainer>
        </NavItemsContainer>
      </Container>
      {window.innerWidth <= 1249 && <MobileMenu visible={mobileMenuVisible} children={<Navigation />} />}
    </RefContainer>
  )
}

export default Header;