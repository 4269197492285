import { FC } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { Description, TableEmptyStateContainer, Title } from './styles';

const TableSearchEmptyState: FC = () => (
  <TableEmptyStateContainer>
    <BiSearchAlt size={150} />
    <Title>No results found</Title>
    <Description>Please, change the search query, or clear it</Description>
  </TableEmptyStateContainer>
);

export default TableSearchEmptyState;