import { EIntervalTypes } from 'ts/enums/interval.types';

const generate = (interval: EIntervalTypes, hours: string[], daysOfWeek: string[], daysOfMonth: string[]) => {
    const cron = ['*','*','*','*','*'];
        switch(interval) {
            case EIntervalTypes.EVERY_HOUR: {
                cron[0] = '1';
                break;
            }
            case EIntervalTypes.DAILY: {
                cron[0] = '0'
                cron[1] = hours?.length ? hours.join(',') : '1';
                break;
            }
            case EIntervalTypes.WEEKLY: {
                cron[0] = '0'
                cron[1] = hours?.length ? hours.join(',') : '1';
                cron[4] = daysOfWeek?.length ? daysOfWeek.join(',') : '1';
                break;
            }
            case EIntervalTypes.MONTHLY: {
                cron[0] = '0'
                cron[1] = hours?.length ? hours.join(',') : '1';
                cron[2] = daysOfMonth?.length ? daysOfMonth.join(',') : '1';
                break;
            }
            case EIntervalTypes.EVERY_MINUTE:
            default: {
                break;
            }
        };
        return cron.join(' ');
}

export default generate;