import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IPagination } from 'ts/interfaces/common/pagination';

export const getUsageHistory = (pagination: IPagination, period: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USAGE_HISTORY,
    payload: {
      method: 'POST',
      endpoint:  `usage/history?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`,
      body: {
        period,
      }
    },
  }
  return action;
}

export const getUsageAnalytics = (period: string = 'month'): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USAGE_ANALYTICS,
    payload: {
      method: 'GET',
      endpoint: `usage/analytics?period=${period}`
    },
    components: ['usage_analytics'],
  };
  return action;
}
