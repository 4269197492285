import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

//** Interface */
import { ButtonHeight } from 'ts/enums/styles';
import { IPaginatedSavedSearch, ISavedSearch } from 'ts/interfaces/saved-search/saved-search.data';

//** Components  */
import ContentBox from 'components/contentBox';
import Table from 'components/commonTable';
import Pagination from 'components/pagination';
import Button from 'components/button';

//** Action */
import { getSavedSearches, setEditableSearch, deleteSavedSearch } from 'data/actions/savedSearch';


//** Style */
import { SectionTitle, FlexColumnContainer, ControlsBarContainer, Input } from 'components/common/styles';
import { Container, SearchHeaderContainer, EmptyStateContainer } from './styles';
import { ButtonContainer, Description, Title } from 'layouts/automationEmptyState/styles';

//** Selector */
import { savedSearchDataSelector, savedSearchMetaSelector } from 'data/selectors/savedSearch';
import { getLoadingSelector } from 'data/selectors/loading';

import generateSearchColumns from './columns/searchesTableColumns';

const SearchesPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchesList: ISavedSearch[] = useSelector(savedSearchDataSelector('table'));
  const { totalPages = 0, totalItems = 0 } = useSelector(savedSearchMetaSelector('table'));
  const isLoading = useSelector(getLoadingSelector('saved_searches_list'));  

  const [isComponentInit, setIsComponentInit] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined); // undefined used to avoid blank state blink when clearing input
  const [idFilter, setIdFilter] = useState<string[] | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(10);
  const [showEmptyState, setShowEmptyState] = useState(false);

  const editSearch = useCallback((searchData: ISavedSearch) => {
    dispatch(setEditableSearch(searchData));
    navigate('/playground', { replace: true })
  }, [navigate, setEditableSearch]);

  const columns = generateSearchColumns((searchId) => dispatch(deleteSavedSearch(searchId)), editSearch);

  useEffect(() => {
    if (window.location.search) {
      const ids = queryString?.parseUrl(window.location.href)?.query?.ids;
      if (typeof ids === 'string') setIdFilter(ids.split(','));
    }
  }, []);

  useEffect(() => {
    if (idFilter?.length && searchInput) setIdFilter(undefined);
  }, [searchInput]);

  useEffect(() => {
    const onSuccess = (result: IPaginatedSavedSearch) => {
      if (result?.data?.length === 0 && !isComponentInit) setShowEmptyState(true);
    }
    dispatch(getSavedSearches('table', searchInput || '', { pageNumber, pageSize }, idFilter, onSuccess, () => setShowEmptyState(true)));
    if (!isComponentInit) setIsComponentInit(true);
  }, [dispatch, pageNumber, pageSize, idFilter, searchInput]);

  if (searchInput === undefined && !searchesList?.length && (!isComponentInit || isLoading)) return <></>
  if (showEmptyState) return (
    <EmptyStateContainer>
      <Title>Create your first search</Title>
      <Description>Fill request fields and click <b>"Save search"</b></Description>
      <Description>on the top-right corner.</Description>
      <ButtonContainer>
        <Button size={ButtonHeight.MEDIUM} onClick={() => navigate('/playground', { replace: true })}>Create search</Button>
      </ButtonContainer>
    </EmptyStateContainer>
  )

  return (
    <Container>
      <SearchHeaderContainer>
        <SectionTitle>Search</SectionTitle>
        <Input placeholder="Search by name..." name="query" value={searchInput} onChange={(e) => setSearchInput(e?.target?.value || '')}/>
        <Button size={ButtonHeight.MEDIUM} onClick={() => navigate('/playground', { replace: true })}>Create search</Button>
      </SearchHeaderContainer>
      <ContentBox>
        <FlexColumnContainer>
          <ControlsBarContainer>
            <Pagination
              totalItems={totalItems}
              pageNumber={pageNumber}
              pageSize={pageSize}
              onChangePage={setPageNumber}
              totalPages={totalPages}
            />
          </ControlsBarContainer>
          <Table 
            columns={columns}
            data={searchesList}
            containerStyles={{ padding: 0, marginLeft: -10, width: "calc(100% + 10px)" }}
            showSearchEmptyState={true}
          />
        </FlexColumnContainer>
      </ContentBox>
    </Container>
  );
}

export default SearchesPage;
