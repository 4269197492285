import { useState, useEffect } from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const fpPromise = FingerprintJS.load({ token: String(process.env.REACT_APP_FINGER_PRINT_BROWSER_TOKEN) });

function useFingerPrint(): string {
    const [fingerPrint, setFingerPrint] = useState("UpOy5GZtchmpnIlfWOhH");

    useEffect(() => {
        fpPromise
            .then((fp: any) => fp.get())
            .then((result: any) => {
              localStorage.setItem('FINGERPRINT_TOKEN', result.visitorId);
              setFingerPrint(result.visitorId);
            }) 
    }, []);
    
    
    return fingerPrint;
  }

export default useFingerPrint;
