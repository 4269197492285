import { FC, useState } from 'react';
import { Dropdown, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateAutomation, deleteAutomation } from 'data/actions/automation';
import { applicationDataSelector } from 'data/selectors/application';
import { EAutomationStatus } from 'ts/enums/automation.status';
import MoreIcon from 'assets/more.svg';
import { BsFillPlayCircleFill, BsFillTrashFill, BsFillStopCircleFill, BsFillSaveFill, BsFillPencilFill } from 'react-icons/bs';
import { MenuContainer, MenuItem, MoreButtonContainer } from './styles';
import { IAutomation } from 'ts/interfaces/automation/automation';
import { toggleDrawer } from 'data/actions/drawer';
import { EDrawerTypes } from 'ts/enums/drawer.types';

const ActionBarMenu: FC<{ automation: IAutomation, closeDropdown: () => void }> = ({ automation, closeDropdown }) => {
  const dispatch = useDispatch();
  const applications = useSelector(applicationDataSelector);

  const getApplicationResultLink = (applicationId: string) => `${process.env.REACT_APP_API_URL}/automation/${applicationId}/results?apiKey=${applications?.[0]?.key}`;

  const confirmDeleteAutomation = () => {
    dispatch(deleteAutomation(automation._id));
    closeDropdown();
  };

  return (
    <MenuContainer>
      <MenuItem
        disabled={false}
        key="update_automation_status" 
        onClick={() => {
          dispatch(updateAutomation(automation._id, {
            status: (automation.status === EAutomationStatus.ACTIVE) ? EAutomationStatus.DISABLED : EAutomationStatus.ACTIVE
          }));
          closeDropdown();
        }}
      >
        {automation.status === EAutomationStatus.ACTIVE ? (
         <><BsFillStopCircleFill color="rgb(139, 139, 139)"/> <span> Stop automation </span></> 
        ) : <><BsFillPlayCircleFill color="rgb(139, 139, 139)"/> <span> Start automation </span></>}
      </MenuItem>

      <MenuItem key="edit_automation_properties" onClick={() => {
        dispatch(toggleDrawer(true, EDrawerTypes.UPDATE_AUTOMATION, automation));
        closeDropdown();
      }}>
          <BsFillPencilFill color="rgb(139, 139, 139)"/>
          <span>Edit</span>
      </MenuItem>

      {
        !!automation.lastRunAt && (
          <MenuItem key="save_automation_results" onClick={() => {
            window.open(getApplicationResultLink(automation._id), '_self');
            closeDropdown();
          }}>
              <BsFillSaveFill color="rgb(139, 139, 139)"/>
              <span>Save results</span>
          </MenuItem>
        )
      }
    
      <Popconfirm
        title="Are you sure to delete this automation?"
        onConfirm={confirmDeleteAutomation}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <MenuItem key="delete_automation">
          <BsFillTrashFill color="rgb(139, 139, 139)"/>
          <span>Delete</span>
        </MenuItem>
      </Popconfirm>
    </MenuContainer>
  );
}

const ActionBar: FC<{ automation: IAutomation }> = ({ automation }) => {
    const [visible, setVisible] = useState(false);
    return (
        <Dropdown
          visible={visible}
          overlay={<ActionBarMenu closeDropdown={() => setVisible(false)} automation={automation}/>} 
          trigger={['click']} 
          placement="bottomLeft"
          onVisibleChange={(state) => setVisible(state)}
        >
            <div style={{ height: 35, width: 35 }}>
                <MoreButtonContainer src={MoreIcon} />
            </div>
        </Dropdown>
    )
}

export default ActionBar;