import styled, { keyframes } from "styled-components";
import { fadeIn } from 'react-animations';
import { SectionTitle } from 'components/common/styles';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding-bottom: 35px;
    animation: 100ms ${fadeInAnimation};
`;

export const Title = styled(SectionTitle)`
    text-align: center;
    margin-bottom: 15px;
`;  

export const ButtonContainer = styled.div`
    margin-top: 20px;
    width: 250px;
`;

export const Description = styled.p`
    font-size: 16px;
    margin: 0;
`;
