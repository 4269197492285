import { FC, useState } from 'react';
import AuthLayout from 'layouts/auth/authLayout';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAccount } from 'data/actions/user';
import { Link } from 'react-router-dom';
import { CreateAccountLabel, Title, PasswordResetDescription, ErrorMessage } from './styles';
import Button from 'components/button';
import ConfirmationCode from 'components/confirmationCode';
import { getLoadingSelector } from 'data/selectors/loading';
import useFingerPrint from 'hooks/useFingerPrint';
import { signOut } from 'data/actions/user';

const ConfirmEmailPage: FC = () => {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const loading = useSelector(getLoadingSelector('confirm_account'));
    const fingerPrint = useFingerPrint();

    const handleConfirmAccount = (e: any) => {
        e.preventDefault();
        dispatch(confirmAccount(parseInt(code, 10), fingerPrint, setError));
    };

    return (
        <AuthLayout>
            <Title>Verify your email address</Title>
            { error && <ErrorMessage>{error}</ErrorMessage> }
            <PasswordResetDescription>We've just sent a 6-digit code to your email</PasswordResetDescription>
            <ConfirmationCode onChange={setCode} />
            <Button style={{ marginTop: 25 }} onClick={handleConfirmAccount} loading={loading}>Confirm</Button>
            <CreateAccountLabel onClick={() => dispatch(signOut())}><Link to="/sign-in">No, thanks</Link></CreateAccountLabel>
        </AuthLayout>
    )
} 

export default ConfirmEmailPage;