import React, { FC, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//** Actions */
import { getUsageHistory } from 'data/actions/usage';

//** Selectors */
import { usageHistorySelector, usageMetaSelector } from 'data/selectors/usage';

//** Components */
import Table from 'components/commonTable';
import Pagination from 'components/pagination';
import ContentBox from 'components/contentBox';

//** Interfaces */
import { IUsageAnalyticsPeriod } from 'ts/interfaces/usage/usage.analytics';

//** Styles */
import { FlexColumnContainer, ControlsBarContainer } from 'components/common/styles';

//** Other */
import HistoryTableColumns from './columns';

const UsageTable: FC<{ period: IUsageAnalyticsPeriod }> = ({ period }) => {
  const dispatch = useDispatch();
  const history = useSelector(usageHistorySelector);
  const { totalItems = 0, totalPages = 0 } = useSelector(usageMetaSelector);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(10);

  useEffect(() => setPageNumber(0), [period])

  useEffect(() => {
    dispatch(getUsageHistory({ pageNumber, pageSize }, period))
  }, [dispatch, pageNumber, pageSize, period ]);

  const columns = useMemo(
    () => HistoryTableColumns, []
  );

  if (!history.length) return null;

  return (
    <ContentBox>
      <FlexColumnContainer>
        <ControlsBarContainer>
          <Pagination
            totalItems={totalItems}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onChangePage={setPageNumber}
            totalPages={totalPages}
          />
        </ControlsBarContainer>
        <Table columns={columns} data={history} containerStyles={{ padding: 0, marginLeft: -10, width: "calc(100% + 10px)" }} />
      </FlexColumnContainer>
    </ContentBox>
  );
};

export default UsageTable;
