import { FC } from 'react';
import styled from "styled-components";
import { Select } from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';

export const CustomSelect: FC<any> = styled(Select)`
  font-size: 14px;
  border: 1px solid rgb(230,234,238);
  border-radius: 2px;
  background: rgb(255,255,255);
  color: rgb(0,0,0);
  font-weight: 500;
  padding: 5px 0;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  } 

  .ant-select-selection-item {
    cursor: pointer;
  }
`;

export const CustomDropdownContainer = styled.div`
  .ant-select-item {
    padding: 10px 12px;
  }
`;


export const CaretDown = styled(CaretDownOutlined) < { focused: number } >`
  color: #c5c9cf;
  svg {
    width: auto;
    height: 12px;
  }

  transform: ${({ focused }) => (focused ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const SearchIcon = styled(SearchOutlined)`
  color: #c5c9cf;
  svg {
    width: auto;
    height: 12px;
  }
`;
