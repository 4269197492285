import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import cronstrue from 'cronstrue';
import { upperFirst } from 'lodash'; 

//** Interface */
import { IAutomation } from 'ts/interfaces/automation/automation';
import { ButtonHeight } from 'ts/enums/styles';
import { EDrawerTypes } from 'ts/enums/drawer.types';
import { EAutomationStatus } from 'ts/enums/automation.status';
import { EFeatures } from 'ts/enums/features';
import { EModalTypes } from 'ts/enums/modal.types';

//** Components  */
import ContentBox from 'components/contentBox';
import Table from 'components/commonTable';
import Pagination from 'components/pagination';
import Button from 'components/button';
import ActionBar from 'components/automationActionBar';
import EllipsisTooltip from 'components/tooltipTableCell';

//** Layouts */
import AutomationEmptyState from 'layouts/automationEmptyState';

//** Action */
import { getAutomationList } from 'data/actions/automation';
import { toggleDrawer } from 'data/actions/drawer';
import { toggleModal } from 'data/actions/modals';

//** Selector */
import { automationDataSelector, automationMetaSelector } from 'data/selectors/automation';
import { isFeatureAvailableSelector } from 'data/selectors/subscriptions';
import { getLoadingSelector } from 'data/selectors/loading';

//** Style */
import { Dot, SectionTitle, FlexColumnContainer, ControlsBarContainer } from 'components/common/styles';
import { TableCell, StatusRawContainer, Container, AutomationHeaderContainer } from './styles';

const AutomationColumns = (navigate: NavigateFunction) => [
    {
      Header: 'Name',
      accessor: (originalRow: IAutomation) => <TableCell style={{ width: '100px' }}><EllipsisTooltip>{originalRow.name}</EllipsisTooltip></TableCell>
    },
    {
        Header: 'Status',
        accessor: (originalRow: IAutomation) => {
            return<TableCell> <StatusRawContainer><Dot error={originalRow.status !== EAutomationStatus.ACTIVE}/>{upperFirst(originalRow.status)}</StatusRawContainer></TableCell>
        }
    },
    {
        Header: 'Last run',
        accessor: (originalRow: IAutomation) => <TableCell style={{ width: '140px' }}>{ originalRow?.lastRunAt ? DateTime.fromISO(originalRow?.lastRunAt).toRelative() : '-'}</TableCell>
    },
    {
        Header: 'Interval',
        accessor: (originalRow: IAutomation) => <TableCell style={{ width: '230px' }}>{cronstrue.toString(originalRow?.interval)}</TableCell>
    },
    {
        Header: 'Searches',
        accessor: (originalRow: IAutomation) => originalRow?.searches?.length ? <span style={{ cursor: 'pointer', color: 'rgb(87,102,236)' }} onClick={() => navigate(`/searches?ids=${originalRow?.searches?.join(',')}`, { replace: true })} >{originalRow?.searches?.length}</span> : <TableCell>{originalRow?.searches?.length}</TableCell>
    },
    {
        Header: 'Created',
        accessor: (originalRow: IAutomation) => <TableCell style={{ width: '110px' }}>{ DateTime.fromISO(originalRow?.createdAt).toRelative()}</TableCell>
    },
    {
        Header: 'Action',
        accessor: (originalRow: IAutomation) => <ActionBar automation={originalRow} />
    }
    
];

const AutomationPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const automationList = useSelector(automationDataSelector);
    
    const isAutomationAvailable = useSelector(isFeatureAvailableSelector(EFeatures.AUTOMATION));
    const isLoading = useSelector(getLoadingSelector('automation_list'));
    const [isComponentInit, setIsComponentInit] = useState<boolean>(false);

    const showEmptyStatePlaceholder = isComponentInit && !isLoading && !automationList?.length;

    const { totalItems = 0, totalPages = 0 } = useSelector(automationMetaSelector);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize] = useState(10);

    useEffect(() => {
        dispatch(getAutomationList({ pageNumber, pageSize }));
        setTimeout(() => setIsComponentInit(true), 1);
    }, [dispatch, pageNumber, pageSize]);

    if (!automationList?.length && (!isComponentInit || isLoading)) return <></>
    if (showEmptyStatePlaceholder) return <AutomationEmptyState />
    return (
        <Container>
            <AutomationHeaderContainer>
                <SectionTitle>Automation</SectionTitle>
                <Button size={ButtonHeight.MEDIUM} onClick={() => {
                    if (isAutomationAvailable) dispatch(toggleDrawer(true, EDrawerTypes.CREATE_AUTOMATION));
                    else dispatch(toggleModal({ type: EModalTypes.ADVANCED_FEATURE, visible: true }));
                }}>Create automation</Button>
            </AutomationHeaderContainer>
            <ContentBox>
            <FlexColumnContainer>
                <ControlsBarContainer>
                    <Pagination
                        totalItems={totalItems}
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        onChangePage={setPageNumber}
                        totalPages={totalPages}
                    />
                </ControlsBarContainer>
                <Table columns={AutomationColumns(navigate)} data={automationList} containerStyles={{ padding: 0, marginLeft: -10, width: "calc(100% + 10px)" }} />
            </FlexColumnContainer>
            </ContentBox>
        </Container>
    )
}   

export default AutomationPage;