import { createSelector } from 'reselect';

const baseGoogleSelector = (state: any) => state?.google;

export const googleLocationsSelector = createSelector(
    baseGoogleSelector,
    (google) => google.location || [],
);

export const googleResultSelector = createSelector(
    baseGoogleSelector,
    (google) => google?.searchResult,
);

export const googleRequestSelector = createSelector(
    baseGoogleSelector,
    (google) => google?.request,
);

export const editableSearchSelector = createSelector(
  baseGoogleSelector,
  (google) => google?.editableSearch,
);

export const isGoogleSearchFormValid = createSelector(
    googleRequestSelector,
    (request) => !!request?.q,
);
