export enum EGoogleTab {
  DEFAULT = 'default',
  AUTOCOMPLETE = 'autocomplete',
  PICTURES = 'isch',
  PLACES = 'lcl',
  SHOP = 'shop',
  VIDEOS = 'vid',
  NEWS = 'nws',
  // AVIA_TICKETS = 'flights',
  // FINANCES = 'finance',
  BOOKS = 'bks',
}
