import styled from 'styled-components';

export const RawItem = styled.div`
    display: flex;
    padding: 5px 0;
    align-items: center;
`;

export const Label = styled.div`
    width: 150px;
    font-weight: bold;
`;

export const SubscriptionContainer = styled.div`
`;

