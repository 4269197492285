import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IToggleModal } from 'ts/interfaces/modal/modal';


export const toggleModal = (data: IToggleModal): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.TOGGLE_MODAL,
    data
  };
  return action;
};
