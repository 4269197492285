import { createSelector } from 'reselect';

const baseSavedSearchSelector = (state: any) => state?.savedSearch;

export const savedSearchMetaSelector = (place: 'table' | 'select') => createSelector(
  baseSavedSearchSelector,
  (savedSearch) => savedSearch?.[place]?.meta || {},
);

export const savedSearchDataSelector = (place: 'table' | 'select') => createSelector(
  baseSavedSearchSelector,
  (savedSearch) => savedSearch?.[place]?.data || [],
);

export const savedSearchPageNumberSelector = (place: 'table' | 'select') => createSelector(
  baseSavedSearchSelector,
  (savedSearch) => savedSearch?.[place]?.pageNumber || 0,
);

export const savedSearchPageSizeSelector = (place: 'table' | 'select') => createSelector(
  baseSavedSearchSelector,
  (savedSearch) => savedSearch?.[place]?.pageSize || 10,
);
