import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthLayout from 'layouts/auth/authLayout';
import { getLoadingSelector } from 'data/selectors/loading';
import EmailInput from 'components/emailInput';
import { Link } from 'react-router-dom';
import { sendForgotPassword } from 'data/actions/user';
import { CreateAccountLabel, Title, PasswordResetDescription, ErrorMessage, Label, } from './styles';
import Button from 'components/button';

const PasswordResetPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [sent, setSent] = useState(false);
    const loading = useSelector(getLoadingSelector('password_forgot'));

    const submit = (e: any) => {
      e.preventDefault();
      dispatch(sendForgotPassword(email, setSent, setError));
    };

    return (
        <AuthLayout>
          {!sent 
          ? (
            <>
              <Title>Password reset</Title>
              {error && <ErrorMessage>{error}</ErrorMessage> }
              <PasswordResetDescription>Input an e-mail so we can send you the link to reset your password</PasswordResetDescription>
              <EmailInput value={email} onChange={(e: any) => setEmail(e?.target?.value)} />
              <Button loading={loading} disabled={!email} onClick={submit} style={{marginTop: 25}}>Continue</Button>
              <CreateAccountLabel><Link to="sign-in">No, thanks</Link></CreateAccountLabel>
            </>
          ) 
          : (
            <>
              <Title>Please check Email</Title> 
              <div>
                <PasswordResetDescription>We've sent you the link to reset your password <strong>{email}</strong>.</PasswordResetDescription>
                <Label>Didn't receive email?</Label>
                <Label>Please check Spam or <Label onClick={() => setSent(false)}>request for a new one</Label>.</Label>
                <Button onClick={() => navigate('/sign-in', { replace: true })} style={{marginTop: 25}}>Done</Button>
              </div>
            </>
          )
          }
        </AuthLayout>
    )
} 

export default PasswordResetPage;