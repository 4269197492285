import { FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
} from 'chart.js';

//** Components */
import ContentBox from 'components/contentBox';

//** Helpers */
import generateData from 'helpers/generateChartData';

//** Interfaces */
import { IUsageAnalyticsDocument, IUsageAnalyticsPeriod } from 'ts/interfaces/usage/usage.analytics';

//** Other */
import BarOptions from './chart.options';

//** Styles */
import { FlexColumnContainer } from 'components/common/styles';
import {
    CreditsCountLabel
} from './styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
);

const UsageChart: FC<{usageAnalytics: IUsageAnalyticsDocument[], period: IUsageAnalyticsPeriod}> = ({ usageAnalytics, period}) => {

    const totalCredits: number = useMemo(() => {
        let total = 0;
        usageAnalytics.forEach((item: IUsageAnalyticsDocument) => total += item.credits);
        return total;
    }, [usageAnalytics])

    const options = useMemo(
        () => BarOptions, []
    );

    return (
        <ContentBox>
            <FlexColumnContainer>
                <CreditsCountLabel><b>Total:</b> { totalCredits } credits</CreditsCountLabel>
                <Bar data={generateData(usageAnalytics, period)} options={options} />
            </FlexColumnContainer>
        </ContentBox>
    )
}

export default UsageChart;