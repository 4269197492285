import OptionType from 'ts/types/option';
import { EIntervalTypes } from 'ts/enums/interval.types';
import { EAutomationOutputTypes } from 'ts/enums/automation.output.types';

export const intervalOptions: OptionType[] = [
    { value: EIntervalTypes.EVERY_MINUTE, label: "Every minute" },
    { value: EIntervalTypes.EVERY_HOUR, label: "Every hour" },
    { value: EIntervalTypes.DAILY, label: "Daily" },
    { value: EIntervalTypes.WEEKLY, label: "Weekly" },
    { value: EIntervalTypes.MONTHLY, label: "Monthly" },
];

export const hoursOptions: OptionType[] = [
    { value: "0", label: "00:00" },
    { value: "1", label: "01:00" },
    { value: "2", label: "02:00" },
    { value: "3", label: "03:00" },
    { value: "4", label: "04:00" },
    { value: "5", label: "05:00" },
    { value: "6", label: "06:00" },
    { value: "7", label: "07:00" },
    { value: "8", label: "08:00" },
    { value: "9", label: "09:00" },
    { value: "10", label: "10:00" },
    { value: "11", label: "11:00" },
    { value: "12", label: "12:00" },
    { value: "13", label: "13:00" },
    { value: "14", label: "14:00" },
    { value: "15", label: "15:00" },
    { value: "16", label: "16:00" },
    { value: "17", label: "17:00" },
    { value: "18", label: "18:00" },
    { value: "19", label: "19:00" },
    { value: "20", label: "20:00" },
    { value: "21", label: "21:00" },
    { value: "22", label: "22:00" },
    { value: "23", label: "23:00" },
];

export const daysOfWeekOptions: OptionType[] = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
];

export const daysOfMonthOptions: OptionType[] = [
    { value: "1", label: "1st" },
    { value: "2", label: "2nd" },
    { value: "3", label: "3rd" },
    { value: "4", label: "4th" },
    { value: "5", label: "5th" },
    { value: "6", label: "6th" },
    { value: "7", label: "7th" },
    { value: "8", label: "8th" },
    { value: "9", label: "9th" },
    { value: "10", label: "10th" },
    { value: "11", label: "11th" },
    { value: "12", label: "12th" },
    { value: "13", label: "13th" },
    { value: "14", label: "14th" },
    { value: "15", label: "15th" },
    { value: "16", label: "16th" },
    { value: "17", label: "17th" },
    { value: "18", label: "18th" },
    { value: "19", label: "19th" },
    { value: "20", label: "20th" },
    { value: "21", label: "21st" },
    { value: "22", label: "22nd" },
    { value: "23", label: "23rd" },
    { value: "24", label: "24th" },
    { value: "25", label: "25th" },
    { value: "26", label: "26th" },
    { value: "27", label: "27th" },
    { value: "28", label: "28th" },
    { value: "29", label: "29th" },
    { value: "30", label: "30th" },
    { value: "31", label: "31st" },
];

export const outputOptions: OptionType[] = [
    { value: EAutomationOutputTypes.EMAIL, label: "Email message" },
    { value: EAutomationOutputTypes.WEBHOOK, label: "Webhook integration" },
];