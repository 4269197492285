import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`${fadeIn}`;

export const TableCell = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
`;

export const ActionItem = styled.div<{fill: string}>`
  display: flex;
  flex-direction: column;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border-radius: 8px;

  &:hover {
    background: #e6e6ea;
    svg {
      fill: ${(props) => props.fill};
    }
  }
`;

export const SearchHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-left: 10px;
    width: 200px;
  }

  input {
    width: 250px;
    border-radius: 8px;
  }

  @media (max-width: 1249px) {
    flex-direction: column;
    gap: 30px;

    button {
      width: 100%;
    }
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-bottom: 35px;
  animation: 100ms ${fadeInAnimation};
`;
