import { FC, useState } from 'react';

import { Tooltip } from 'antd';
import Paragraph, { ParagraphProps } from "antd/es/typography/Paragraph";


const EllipsisTooltip: FC<ParagraphProps> = ({ children, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined}>
      <Paragraph
        {...props}
        style={{ margin: 0 }}
        ellipsis={{ onEllipsis: setTruncated }}
      >
        <>
          {children}
        </>
      </Paragraph>
    </Tooltip>
  )
}

export default EllipsisTooltip;