import { FC } from 'react';
import { BackToSiteContainer, Container, Form, LogoContainer } from './styles';
import Logo from 'assets/logo.svg';

const AuthLayout: FC<any> = ({ children, ...props }) => {
    return (
        <Container {...props}>
            <BackToSiteContainer>
                <a href="https://spaceserp.com">{'< Back to SpaceSerp'}</a>
            </BackToSiteContainer>
            <Form autoComplete={'off'}>
                <LogoContainer src={Logo} />
                {children}
            </Form>
        </Container>
    )
}

export default AuthLayout;