import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IPagination } from 'ts/interfaces/common/pagination';
import { EAutomationStatus } from 'ts/enums/automation.status';
import { IAutomationUpdate } from 'ts/interfaces/automation/automation';
import { toast } from 'react-toastify';

export const getAutomationList = (pagination: IPagination): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_AUTOMATION_LIST,
    payload: {
      method: 'GET',
      endpoint:  `automation?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`
    },
    components: ['automation_list']
  }
  return action;
}

export const createAutomation = (name: string, interval: string, searches: string[], output: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CREATE_AUTOMATION,
    payload: {
      method: 'POST',
      endpoint:  `automation`,
      body: {
        name,
        interval,
        searches,
        output,
        status: EAutomationStatus.ACTIVE
      }
    },
  }
  return action;
}

export const updateAutomation = (automationId: string, automation: IAutomationUpdate): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_AUTOMATION,
    payload: {
      method: 'PATCH',
      endpoint:  `automation/${automationId}`,
      body: automation
    },
    onSuccess: () => {
      toast.success('Automation successfully updated!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  }
  return action;
}

export const deleteAutomation = (automationId: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.DELETE_AUTOMATION,
    payload: {
      method: 'DELETE',
      endpoint:  `automation/${automationId}`,
    },
  }
  return action;
}
