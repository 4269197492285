import { createSelector } from 'reselect';

const baseAutomationSelector = (state: any) => state?.automation;

export const automationDataSelector = createSelector(
    baseAutomationSelector,
  (automation) => automation?.data || [],
);

export const automationMetaSelector = createSelector(
    baseAutomationSelector,
    (automation) => automation?.meta || {},
  );
  
