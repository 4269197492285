import { Middleware } from 'redux';
import { triggerLoadingAction } from 'data/actions/loading';
import { signOut } from 'data/actions/user';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';

const apiMiddleware = (): Middleware => (store) => (next: any) => async (action: IReduxAction) => {
  if (!action) return false;
  
  const host = action?.payload?.host;
  const forwardedData = action?.forwardedData;
  const event = action?.event;
  const endpoint = action?.payload?.endpoint;
  const method = action?.payload?.method;
  const body = action?.payload?.body;
  const params = action?.payload?.params;
  const data = action?.data;
  const components = action?.components;
  const delayLoading = action?.delayLoading;
  const skipAuth = action?.skipAuth;
  const onSuccess = action?.onSuccess;
  const onFailed = action?.onFailed;

  const apiHost = (host) ? host : process.env.REACT_APP_API_URL;

  if (data || event) return next(action);
  

  let loading = true;
  setTimeout(() => {
    if (loading && components) {
      store.dispatch(triggerLoadingAction(components, true));
    }
  }, delayLoading);

  const headers: any = {};

  if (!skipAuth) {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  try {
    const res: AxiosResponse = await axios({
      url: `${apiHost}/${endpoint}`,
      method,
      data: body,
      params,
      headers
    });

    const data = res?.data;
    const responseHeaders = res?.headers;

    if (onSuccess) onSuccess(data);

    loading = false;
    if (components) setTimeout(() => store.dispatch(triggerLoadingAction(components, false)), 0);
    
    return next({
      ...action,
      payload: data,
      headers: responseHeaders,
      forwardedData
    });
  } catch (err: any) {
    loading = false;
    const status = err?.response?.status;
    
    if (status === 401) store.dispatch(signOut());
    if (status === 403) message.error('Not sufficient funds for this action')
    
    if (components) setTimeout(() => store.dispatch(triggerLoadingAction(components, false)), 0);
    if (onFailed) return onFailed(err);
    
    return false;
  }
};

export default apiMiddleware;