import { FC } from 'react';
import Select from 'react-select';

//** Definitions - interface, type, const */
import { 
    hoursOptions, 
} from 'ts/constants/automation.form.options';

const HoursSelect: FC<{ value: string[], setValue: (value: any) => void }> = ({ value, setValue }) => {
    return (
            <Select
                placeholder="Start time..."
                className="basic-single"
                classNamePrefix="select"
                isClearable
                name="hours"
                isMulti
                value={hoursOptions.filter((option) => value.includes(option.value))}
                onChange={(newValue: any) => setValue(newValue.map((item: any) => item?.value))}
                options={hoursOptions}
                menuPlacement="auto"
            />
    )
}

export default HoursSelect;