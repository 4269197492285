import { FC, useEffect, useState } from 'react';
import Select from 'react-select';

//** Definitions - interface, type, const */
import { 
    outputOptions 
} from 'ts/constants/automation.form.options';
import { EAutomationOutputTypes } from 'ts/enums/automation.output.types';
import { searchOutputFormatOptions } from 'ts/constants/search.form.options';
import OptionType from 'ts/types/option';

//** Styles */
import { Description, Input } from 'components/common/styles';
import {
    Container
} from './styles';
import { useSelector } from 'react-redux';
import { userEmail } from 'data/selectors/user';

const OutputSelect: FC<{ value: Record<string, any>, setValue: (value: any) => void }> = ({ value, setValue }) => {
    const defaultEmail = useSelector(userEmail);

    const [type, setType] = useState<EAutomationOutputTypes>();
    const [email, setEmail] = useState<string>(value?.email?.value || defaultEmail || '');
    const [format, setFormat] = useState<OptionType>(searchOutputFormatOptions.find((option) => option.value === value?.email?.format) || searchOutputFormatOptions[1]);
    const [webhookUrl, setWebhookUrl] = useState<string>(value?.webhook || '');
    const [initialValueParsed, toggleInitialValueParsed] = useState<boolean>(!value);

    useEffect(() => {
        if (initialValueParsed) {
            if (type === EAutomationOutputTypes.EMAIL && !!email) setValue({ email: { value: email, format: format.value }});
            else if (type === EAutomationOutputTypes.WEBHOOK && !!webhookUrl) setValue({ webhook: webhookUrl });
            else setValue(null);
        }
    }, [type, email, format, webhookUrl]);

    useEffect(() => {
        if (value && !initialValueParsed) {
            const [outputType] = Object.keys(value);
            setType(outputType as EAutomationOutputTypes);
            toggleInitialValueParsed(true);
        }
    }, []);

    return (
        <>
            <Select
                placeholder="Automation output..."
                className="basic-single"
                classNamePrefix="select"
                isClearable
                value={outputOptions.find((option) => option.value === type)}
                name="output"
                onChange={(newValue: any) => setType(newValue?.value)}
                options={outputOptions}
                menuPlacement="auto"
            />
            <Container>
                { type === EAutomationOutputTypes.EMAIL &&  (
                    <>
                        <Input placeholder="Email" name="destination-email" value={email} onChange={(e) => setEmail(e?.target?.value)}/>
                        <Description style={{ marginTop: 5 }}>Result format:</Description>
                        <Select
                            placeholder="Result format..."
                            className="basic-single"
                            classNamePrefix="select"
                            name="email-result-format"
                            value={format}
                            onChange={(newValue) => setFormat(newValue as OptionType)}
                            options={searchOutputFormatOptions}
                            menuPlacement="top"
                        />
                    </>       
                )}
                { type === EAutomationOutputTypes.WEBHOOK && <Input placeholder="Webhook URL" name="destination-webhook-url" value={webhookUrl} onChange={(e) => setWebhookUrl(e?.target?.value)}/>}
            </Container>
        </>
    )
}

export default OutputSelect;