import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Tawk = () => {
    const tawkMessengerRef: any = useRef();
    return (
        <div className="App">
            <TawkMessengerReact
                propertyId="6363f842daff0e1306d5919c"
                widgetId="1ggv83hnj"
                ref={tawkMessengerRef}/>
        </div>
    );
}

export default Tawk;