import styled from "styled-components";
import { ButtonProps } from 'ts/types/button.props';
import { ButtonHeight } from 'ts/enums/styles';

export const ButtonContainer = styled.button<ButtonProps>`
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    transition: all 0.2s ease 0s;
    white-space: nowrap;
    display: block;
    width: 100%;
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    height: ${props => props.size ? props.size : ButtonHeight.LARGE};
    background: ${props => {
      if (props.disabled) {
        return 'rgb(245, 246, 255)';
      }
      if (props.empty) {
        return 'none';
      }
      return props.outline ? 'rgba(87, 102, 236, 0.1)' : 'rgb(87, 102, 236)'
    }};
    color: ${props => {
      if (props.disabled) {
        return 'rgb(186, 193, 247)';
      }
      if (props.empty) {
        return 'rgb(87, 102, 236)';
      }
      return props.outline ? 'rgb(87, 102, 236)' : 'rgb(255, 255, 255)'
    }};
    border: ${props => props.disabled ? '1px solid rgb(245, 246, 255);' : '1px solid rgb(87, 102, 236);'};
    outline: rgb(87, 102, 236);
    min-width: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: ${props => {
          if (!props.empty) return 'rgb(112, 126, 249)';
        }};
        outline: rgb(112, 126, 249);
        color: ${props => {
          if (!props.empty) return 'rgb(255, 255, 255)';
        }};
        border: 1px solid rgb(112, 126, 249);
    }

    &:active {
        background: ${props => {
          if (!props.empty) return 'rgb(72, 86, 216)';
        }};
        border: 1px solid rgb(72, 86, 216);
        color: ${props => {
          if (!props.empty) return 'rgb(255, 255, 255)';
        }};
        outline: rgb(72, 86, 216);
        transform: scale(0.9);
    }

    &:focus {
        transition: all 0.2s ease-in-out 0s;
    }

    ${(props) => props.disabled && 'pointer-events: none;'}
`;

export const LinkButtonContainer = styled.button<ButtonProps>`
    border: none;
    background: none;
    color: #5766EB;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    ${(props) => props.disabled && 'pointer-events: none;'}
`;