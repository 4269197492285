import { FC } from 'react';
import Select from 'react-select';

//** Definitions - interface, type, const */
import { 
    daysOfMonthOptions, 
} from 'ts/constants/automation.form.options';

const DaysOfMonthSelect: FC<{ value: string[], setValue: (value: any) => void }> = ({ value, setValue }) => {
    return (
            <Select
                placeholder="Days of month..."
                className="basic-single"
                classNamePrefix="select"
                isClearable
                name="days-of-months"
                isMulti
                value={daysOfMonthOptions.filter((option) => value.includes(option.value))}
                onChange={(newValue: any) => setValue(newValue.map((item: any) => item?.value))}
                options={daysOfMonthOptions}
                menuPlacement="auto"
            />
    )
}

export default DaysOfMonthSelect