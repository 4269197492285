export enum EReduxTypes {
    GET_USER_CREDITS = 'GET_USER_CREDITS',
    UPDATE_LOADING_COMPONENT = 'UPDATE_LOADING_COMPONENT',
    SIGN_UP = 'SIGN_UP',
    CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    PASSWORD_RESET = 'PASSWORD_RESET',
    SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD',
    INIT_EVENT_STREAM = "INIT_EVENT_STREAM",
    GOOGLE_AUTH = 'GOOGLE_AUTH',
    GET_USAGE_HISTORY = 'GET_USAGE_HISTORY',
    GET_USAGE_ANALYTICS = 'GET_USAGE_ANALYTICS',
    UPDATE_USER = 'UPDATE_USER',
    GET_USER = 'GET_USER',
    DELETE_USER = 'DELETE_USER',
    TOGGLE_MODAL = 'TOGGLE_MODAL',
    UPDATE_PASSWORD = 'UPDATE_PASSWORD',
    APPLY_COUPON = 'APPLY_COUPON',
    GET_GOOGLE_LOCATIONS = 'GOOGLE.GET_LOCATIONS',
    SEND_GOOGLE_SEARCH_REQUEST = 'SEND_GOOGLE_SEARCH_REQUEST',
    GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION',
    GET_AUTOMATION_LIST = 'GET_AUTOMATION_LIST',
    SET_GOOGLE_SEARCH_REQUEST = 'SET_GOOGLE_SEARCH_REQUEST',
    SAVE_GOOGLE_SEARCH = 'SAVE_GOOGLE_SEARCH',
    GET_SAVED_SEARCHES = 'GET_SAVED_SEARCHES',
    CREATE_AUTOMATION = 'CREATE_AUTOMATION',
    TOGGLE_DRAWER = 'TOGGLE_DRAWER',
    UPDATE_AUTOMATION = 'UPDATE_AUTOMATION',
    DELETE_AUTOMATION = 'DELETE_AUTOMATION',
    GET_AUTOMATION_RESULTS = 'GET_AUTOMATION_RESULTS',
    GET_APPLICATIONS_LIST = 'GET_APPLICATIONS_LIST',
    ADD_NEW_APPLICATION = 'ADD_NEW_APPLICATION',
    DELETE_APPLICATION = 'DELETE_APPLICATION',
    GENERATE_PURCHASE_URL = 'GENERATE_PURCHASE_URL',
    ORDER_COMPLETE = 'ORDER_COMPLETE',
    SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE',
    CREDITS_UPDATE = 'CREDITS_UPDATE',
    GET_ORDER_LIST = 'GET_ORDER_LIST',
    DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH',
    SET_EDITABLE_SEARCH = 'SET_EDITABLE_SEARCH',
    UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH'
}
  