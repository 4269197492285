import { FC, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/button';
import { ButtonHeight } from 'ts/enums/styles';
import { EModalTypes } from 'ts/enums/modal.types';
import { toggleModal } from 'data/actions/modals';
import { toggleDrawer } from 'data/actions/drawer';
import { isFeatureAvailableSelector } from 'data/selectors/subscriptions';
import { Container, Title, ButtonContainer, Description, VideoContainer, Preview, PlayIcon }from './styles';
import { EFeatures } from 'ts/enums/features';
import { EDrawerTypes } from 'ts/enums/drawer.types';

const AutomationEmptyState: FC = () => {
    const dispatch = useDispatch();
    const videoElement = useRef<any>(null);
    const [showPreview, setShowPreview] = useState(true);
    const isAutomationAvailable = useSelector(isFeatureAvailableSelector(EFeatures.AUTOMATION));

    const runVideo = () => {
      setShowPreview(false);
      if (videoElement?.current) {
        videoElement?.current.play();
      }
    }

    return (
        <Container>
                <Title>Create your first automation</Title>
                <Description>Creating your first automation is easy.</Description>
                <Description>Launch your first search and start SERP automation today!</Description>
                <ButtonContainer>
                    <Button size={ButtonHeight.MEDIUM} onClick={() => {
                    if (isAutomationAvailable) dispatch(toggleDrawer(true, EDrawerTypes.CREATE_AUTOMATION));
                    else dispatch(toggleModal({ type: EModalTypes.ADVANCED_FEATURE, visible: true }));
                }}>Create automation</Button>
                </ButtonContainer>
        </Container>  
    )
}

export default AutomationEmptyState;