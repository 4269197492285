import { FC, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'layouts/main';

import PlaygroundPage from 'pages/playground';
import SettingsPage from 'pages//settings';
import AccountSettingsPage from 'pages/settings/account';
import PasswordSettingsPage from 'pages/settings/password';
import BillingSettingsPage from 'pages/settings/billing';
import ApiSettingsPage from 'pages/settings/api';
import UsagePage from 'pages/usage';
import AutomationPage from 'pages/automation';
import SubscriptionPage from 'pages/settings/subscription';
import PricingPage from 'pages/pricing';

import { initEventStream } from 'data/actions/events';
import { getUser } from 'data/actions/user';
import { getUserSubscription } from 'data/actions/subscriptions';
import { getApplicationList } from 'data/actions/application';

import { getLoadingSelector } from 'data/selectors/loading';

import FullScreenLoader from 'components/fullScreenLoader';
import ModalsController from 'components/modalsController';
import DrawerController from 'components/drawerController';
import SearchesPage from 'pages/searches';

const AuthorizedUser: FC = () => {
  const dispatch = useDispatch();

    useEffect(() => {
      dispatch(initEventStream());
      dispatch(getUser());
      dispatch(getUserSubscription());
      dispatch(getApplicationList());
    }, [dispatch]);

    const loading = useSelector(getLoadingSelector('get_user'));

    if (loading) {
      return <FullScreenLoader />
    }

    return (
        <MainLayout>
          <Routes>
            <Route path="/automation" element={<AutomationPage />} />
            <Route path="/usage" element={<UsagePage />} />
            <Route path="/playground" element={<PlaygroundPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/searches" element={<SearchesPage />} />
            
            <Route path="settings/account" element={ <SettingsPage><AccountSettingsPage /></SettingsPage>} />
            <Route path="settings/password" element={ <SettingsPage><PasswordSettingsPage /></SettingsPage>} />
            <Route path="settings/billing" element={ <SettingsPage><BillingSettingsPage /></SettingsPage>} />
            <Route path="settings/api" element={ <SettingsPage><ApiSettingsPage /></SettingsPage>} />
            <Route path="settings/subscription" element={ <SettingsPage><SubscriptionPage /></SettingsPage>} />

            <Route path="*" element={<Navigate to="/playground" />} />
          </Routes>
          <ModalsController />
          <DrawerController />
        </MainLayout>
    )
};

export default AuthorizedUser;