import styled, { keyframes } from "styled-components";
import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;
export const Container = styled.section`
    width: 100%;
    border: 1px solid #ECECEC;
    padding: 25px;
    position: relative;
    background: white;
    box-shadow: rgb(124 124 149 / 8%) 0px 8px 30px;
    border-radius: 8px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    animation: 100ms ${fadeInAnimation};

    @media (max-width: 1249px) {
      flex-direction: column;
    }
`;