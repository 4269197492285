import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import { IGoogleSearchRequest } from 'ts/interfaces/google/google.search.request';

//** Redux layer */
import { saveGoogleSearch } from 'data/actions/google';
import { googleRequestSelector, isGoogleSearchFormValid } from 'data/selectors/google';

//** Components  */
import Button from 'components/button';

const CreateSearchFooter: FC<{ cancelAction: () => void, setNewSearch: () => void }> = ({ cancelAction, setNewSearch }) => {
    const dispatch = useDispatch();
    const request: IGoogleSearchRequest = useSelector(googleRequestSelector);
    const isFormValid = useSelector(isGoogleSearchFormValid) && request?.name;

    const createSavedSearch = useCallback(() => {
        if (isFormValid) {
            dispatch(saveGoogleSearch(request.name as string, omit(request, 'name')));
            setNewSearch();
            cancelAction();
        }
    }, [isFormValid, request]);

    return (
        <div style={{ display: 'flex' }}>
            <Button disabled={!isFormValid} onClick={createSavedSearch} style={{ width: 150, height: 40 }}>Save</Button>
            <Button onClick={cancelAction} style={{ width: 120, height: 40, marginLeft: 10 }} empty>Cancel</Button>
        </div>
    )
}

export default CreateSearchFooter;