import { IAutomationState, IAutomation } from 'ts/interfaces/automation/automation';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: IAutomationState = {
  data: []
};

const automationReducer = (state = initialState, action: IReduxAction): IAutomationState | {} => {
  switch (action.type) {
    case EReduxTypes.GET_AUTOMATION_LIST: {
      return {
        ...state,
        data: action?.payload?.data || [],
        meta: action?.payload?.meta,
      }
    }
    case EReduxTypes.CREATE_AUTOMATION: {
      const { data } = state;
      data.unshift(action.payload as IAutomation)
      return {
        ...state,
        data: [...data]
      }
    }
    case EReduxTypes.DELETE_AUTOMATION: {
      const deletedAutomationId: string = action?.payload?._id;
      if (deletedAutomationId) {
        const data = state?.data?.filter((item: IAutomation) => item._id !== deletedAutomationId);
        return {
          ...state,
          data
        }
      }
      return state;
    }
    case EReduxTypes.UPDATE_AUTOMATION: {
      const updatedAutomationId: string = action?.payload?._id;
      const { data } = state;
      const automationIndex = data.findIndex((item: IAutomation) => item._id === updatedAutomationId);
      
      if (automationIndex !== -1) {
        data[automationIndex] = action?.payload as IAutomation;
        return {
          ...state,
          data: [...data]
        }
      }
      return state;
    }
    default:
      return state;
  }
};

export default automationReducer;