const options: any = {
    responsive: true,
    plugins: {
      legend: false
    },
    scales: {
        x: {
          grid: {
            display: false,
            drawBorder: true
          },
        },
        y: {
            drawBorder: true,
            display: true,
        }
      }
};

export default options;